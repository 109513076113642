import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { PermittedRoutesProps } from "./interface/PermittedRoutes.interface";
import { Spin } from "antd";
import { hasPermission, permissionData } from "utils/Helpers";
import { JWTToken } from "utils/interface/Utils.interface";
import jwtDecode from "jwt-decode";

const PermittedRoutes = ({
  permission,
  Component,
}: PermittedRoutesProps): JSX.Element => {
  const token: string | null = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }
  const decodedToken: JWTToken = jwtDecode(token || "");

  const isAuthorized = (): boolean => {
    return permissionData(decodedToken).some((element) => {
      return hasPermission(element);
    });
  };

  if (!isAuthorized()) {
    return <Navigate to="/" replace />;
  }

  return (
    <Suspense fallback={<Spin size="small"></Spin>}>
      <Component />
    </Suspense>
  );
};

export default PermittedRoutes;
