const actions = {
  FETCH_REMOVE_CHILD: " FETCH_REMOVE_CHILD",
  FETCH_REMOVE_CHILD_SUCCESS: "FETCH_REMOVE_CHILD_SUCCESS",
  SAVE_REMOVE_CHILD: "SAVE_REMOVE_CHILD",
  SAVE_REMOVE_CHILD_SUCCESS: "SAVE_REMOVE_CHILD_SUCCESS",
  SAVE_REMOVE_CHILD_FAILED: "SAVE_REMOVE_CHILD_FAILED",
  UPDATE_REMOVE_CHILD: "UPDATE_REMOVE_CHILD",
  UPDATE_REMOVE_CHILD_SUCCESS: "UPDATE_REMOVE_CHILD_SUCCESS",

  fetchRemoveChild: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_REMOVE_CHILD,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  updateRemoveChildRequest: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_REMOVE_CHILD,
    data,
    resolve,
    reject,
  }),
  saveRemoveChild: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_REMOVE_CHILD,
    data,
    resolve,
    reject,
  }),
};

export default actions;
