import CustomerSupportUsersSaga from "./sagas";
import CustomerSupportUsersReducer from "./reducers";
import customerSupportUsersActions from "./actions";

export const customerSupportUsersReducer = {
    customerSupportUsersReducer: CustomerSupportUsersReducer,
};

export const customerSupportUsersSaga = [CustomerSupportUsersSaga()];

export { customerSupportUsersActions };
