import LeaveSaga from "./sagas";
import LeaveReducer from "./reducers";
import LeaveActions from "./actions";

export const leaveReducer = {
  leaveReducer: LeaveReducer,
};

export const leaveSaga = [LeaveSaga()];

export { LeaveActions };
