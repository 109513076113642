import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface NotificationReturnType {
  [x: string]: string;
}

const fetchNotificationes = takeLatest(
  actions.FETCH_NOTIFICATIONS,
  function* (
    action: AnyAction
  ): Generator<Effect, void, NotificationReturnType> {
    try {
      const response = yield call(api.get, "api/notification", false);
      yield put({
        type: actions.FETCH_NOTIFICATIONS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_NOTIFICATIONS_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchNotification = takeLatest(
  actions.FETCH_NOTIFICATION,
  function* (
    action: AnyAction
  ): Generator<Effect, void, NotificationReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/notification/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_NOTIFICATION_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_NOTIFICATION_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateNotification = takeLatest(
  actions.UPDATE_NOTIFICATION,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/notification/${action.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_NOTIFICATION_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.UPDATE_NOTIFICATION_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([fetchNotificationes, updateNotification, fetchNotification]);
}
