const actions = {
    FETCH_CHILD_DETAILS: "FETCH_CHILD_DETAILS",
    FETCH_CHILD_DETAILS_SUCCESS: "FETCH_CHILD_DETAILS_SUCCESS",
    FETCH_CHILD_DETAILS_FAILED: "FETCH_CHILD_DETAILS_FAILED",
    REMOVE_FROM_WAITING_LIST: "REMOVE_FROM_WAITING_LIST",
    REMOVE_FROM_WAITING_LIST_SUCCESS: "REMOVE_FROM_WAITING_LIST_SUCCESS",
    REMOVE_FROM_WAITING_LIST_FAILED: "REMOVE_FROM_WAITING_LIST_FAILED",

    removeFromWaitingList: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.REMOVE_FROM_WAITING_LIST,
        data,
        resolve,
        reject,
    }),

    fetchChildDetails: (
        id: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_CHILD_DETAILS,
        id,
        resolve,
        reject,
    })
}

export default actions;