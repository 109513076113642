import { Space, Spin } from "antd";
import React from "react";

function Loader({ height }: { height?: string }) {
  return (
    <Space
      style={{
        width: "100%",
        height: height ? height : "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="small" />
    </Space>
  );
}

export default Loader;
