import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { act } from "react-dom/test-utils";
import { Map } from "immutable";
const { fromJS } = require("immutable");
const initialState = fromJS(
  Map({
    loading: false,
    assignments: [],
    assignmentList: {},
    assignment: [],
  })
);
export interface Action {
  type: string;
  payload?: any;
  event?: string;
}
const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_CHILD_BYINSTRUCTOR_SUCCESS:
      return state.setIn(["childlistbyinstructor"], action.payload);

    case actions.FETCH_ASSIGNMENTS_SUCCESS:
      return state.setIn(["assignments"], action.payload);

    case actions.SAVE_ASSIGNMENT_SUCCESS:
      return state.setIn(["assignments"], action.payload);

    case actions.SUBMIT_ASSIGNMENT_SUCCESS:
      let assignment = 0;
      return state.setIn(["assignment", assignment], action.payload);
    default:
      return state;
  }
};
export default reducer;
