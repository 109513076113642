import BatchSaga from "./sagas";
import BatchReducer from "./reducers";
import batchActions from "./actions";

export const batchReducer = {
  batchReducer: BatchReducer,
};

export const batchSaga = [BatchSaga()];

export { batchActions };
