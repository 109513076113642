import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export const CircleIcon = (props: Partial<CustomIconComponentProps>) =>{
    const circleSvg = () => (<svg width="11" height="11" viewBox="0 0 11 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.69844 9.74201C7.93083 9.74201 9.74054 7.9323 9.74054 5.6999C9.74054 3.46751 7.93083 1.6578 5.69844 1.6578C3.46604 1.6578 1.65633 3.46751 1.65633 5.6999C1.65633 7.9323 3.46604 9.74201 5.69844 9.74201ZM5.69844 10.4999C8.3494 10.4999 10.4984 8.35087 10.4984 5.6999C10.4984 3.04894 8.3494 0.899902 5.69844 0.899902C3.04747 0.899902 0.898438 3.04894 0.898438 5.6999C0.898438 8.35087 3.04747 10.4999 5.69844 10.4999Z"/>
  </svg>);
  return (
    <Icon component={circleSvg} {...props}/>    
  )
}
