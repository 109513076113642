import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { getUserId } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchCreditAndDiscounts = takeLatest(
    actions.FETCH_CREDIT_AND_DISCOUNTS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const { id } = action;
        const userId = id ? parseInt(id) : getUserId(); 
        const credit_response = yield call(
          api.get,
          `api/fundManagement?user_id=${userId}`
        );
        yield put({
          type: actions.FETCH_CREDIT_AND_DISCOUNTS_SUCCESS,
          payload: credit_response,
        });
        yield call(action.resolve, credit_response);
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

  
  export default function* saga() {
    yield all([fetchCreditAndDiscounts]);
  }