import { SuperAdmin } from "../interface/SuperAdmin.interface";

const actions = {
  FETCH_SUPERADMINS: "FETCH_SUPERADMINS",
  FETCH_SUPERADMINS_SUCCESS: "FETCH_SUPERADMINS_SUCCESS",
  FETCH_SUPERADMIN: "FETCH_SUPERADMIN",
  SAVE_SUPERADMIN: "SAVE_SUPERADMIN",
  SAVE_SUPERADMIN_SUCCESS: "SAVE_SUPERADMIN_SUCCESS",
  DELETE_SUPERADMIN: "DELETE_SUPERADMIN",
  DELETE_SUPERADMIN_SUCCESS: "DELETE_SUPERADMIN_SUCCESS",
  DELETE_SUPERADMIN_FAILED: "DELETE_SUPERADMIN_FAILED",

  fetchSuperAdmins: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_SUPERADMINS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchSuperAdmin: (
    id: number | "" | undefined,
    resolve: (value: SuperAdmin) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_SUPERADMIN,
    id,
    resolve,
    reject,
  }),

  saveSuperAdmin: (
    data: SuperAdmin,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_SUPERADMIN,
    data,
    event,
    resolve,
    reject,
  }),

  deleteSuperAdmin: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_SUPERADMIN,
    id,
    resolve,
    reject,
  }),
};

export default actions;
