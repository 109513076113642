import MessageListSaga from "./sagas";
import MessageListReducer from "./reducers";
import MessageListActions from "../message/actions";
export const messageListReducer = {
  messageListReducer: MessageListReducer,
};

export const messageListSaga = [MessageListSaga()];

export { MessageListActions };
