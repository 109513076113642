import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import React from "react";
import { tokens } from "utils/Constants";

interface ATextProps extends TextProps {}

const AText = ({ children, content, ...others }: ATextProps) => {
  const { Text } = Typography;
  return (
    <Text {...others}>
      {content}
      {children}
    </Text>
  );
};

export default AText;
