import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS({
  coursedetails: [],
  loading: false,
  branchReports: [],
  years: [],
});

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case (actions.FETCH_COURSEINFO, actions.FETCH_BRANCHREPORT):
      return state.setIn(["loading"], true);

    case actions.FETCH_YEAR_SUCCESS:
      return state.setIn(["years"], action.payload);

    case actions.FETCH_COURSEINFO_SUCCESS:
      return state.merge({
        loading: false,
        coursedetails: action.payload,
      });

    case actions.FETCH_BRANCHREPORT_SUCCESS:
      return state.merge({
        loading: false,
        branchReports: action.payload,
      });

    default:
      return state;
  }
};

export default reducer;
