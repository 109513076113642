import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object;
}

interface SurveyAnswersReturnType {
    [x: string]: string;
}

const fetchSurveyAnswers = takeLatest(
    actions.FETCH_SURVEYANSWERS,
    function* (
        action: AnyAction
    ): Generator<Effect, void, SurveyAnswersReturnType>{
        const {parentId, childId} = action.params;
        try{
            const response = yield call(
                api.get,
                urlGenerator(`api/surveyAnswer/${parentId}/${childId}`, action),
                false
            );
            yield put({
                type: actions.FETCH_SURVEYANSWERS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch(error: any){
            yield put({ type: actions.FETCH_SURVEYANSWERS_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

const fetchParentDetails = takeLatest(
    actions.FETCH_PARENTDETAILS,
    function* (
        action: AnyAction
    ): Generator<Effect, void, SurveyAnswersReturnType>{
        try{
            const response = yield call(
                api.get,
                urlGenerator(`api/surveyAnswer`, action),
                false
            );
            yield put({
                type: actions.FETCH_PARENTDETAILS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch(error: any){
            yield put({ type: actions.FETCH_PARENTDETAILS_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

export default function* saga() {
    yield all([fetchSurveyAnswers, fetchParentDetails]);
}