import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    notifications: [],
    activeNotification: {},
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case (actions.FETCH_NOTIFICATIONS, actions.FETCH_NOTIFICATION):
      return state.setIn(["loading"], true);

    case actions.FETCH_NOTIFICATION_SUCCESS:
      return state.merge({
        loading: false,
        activeNotification: action.payload,
      });

    case actions.FETCH_NOTIFICATIONS_SUCCESS:
      const notifications = action.payload;
      return state.merge({
        loading: false,
        notifications: notifications,
      });

    case actions.FETCH_NOTIFICATIONS_FAILED:
      return state.setIn(["loading"], false);

    case actions.UPDATE_NOTIFICATION_SUCCESS:
      const updatedEntry = action.payload;
      const notificationIndex = state
        .toJS()
        .notifications.findIndex(
          (notification: any) => notification.id === action.payload.id
        );
      return state.setIn(["notifications", notificationIndex], updatedEntry);

    default:
      return state;
  }
};

export default reducer;
