import PickupPersonsSaga from "./sagas";
import PickupPersonsReducer from "./reducers";
import pickupPersonsActions from "./actions";

export const pickupPersonsReducer = {
  pickupPersonsReducer: PickupPersonsReducer,
};

export const pickupPersonsSaga = [PickupPersonsSaga()];

export { pickupPersonsActions };
