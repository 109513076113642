import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchbranchAssociates = takeLatest(
  actions.FETCH_BRANCH_ASSOCIATES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/branchAssociate", action)
      );
      yield put({
        type: actions.FETCH_BRANCH_ASSOCIATES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchbranchAssociate = takeLatest(
  actions.FETCH_BRANCH_ASSOCIATE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `/api/branchAssociate/${action.id}`);
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const savebranchAssociate = takeLatest(
  actions.SAVE_BRANCH_ASSOCIATE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(
          api.post,
          "/api/branchAssociate",
          action.data,
          null
        );
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_BRANCH_ASSOCIATE_SUCCESS,
          payload: response.data,
          event: response.event,
        });
      }

      if (action.event === "EDIT") {
        yield call(api.put, `/api/branchAssociate/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_BRANCH_ASSOCIATE_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const deletebranchAssociate = takeLatest(
  actions.DELETE_BRANCH_ASSOCIATE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(
        api.delete,
        `/api/branchAssociate/${action.id}`
      );
      yield put({
        type: actions.DELETE_BRANCH_ASSOCIATE_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield put({ type: actions.DELETE_BRANCH_ASSOCIATE_FAILED });
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchbranchAssociates,
    fetchbranchAssociate,
    savebranchAssociate,
    deletebranchAssociate,
  ]);
}
