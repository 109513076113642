import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        referrals: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_REFERRALS_SUCCESS:
            return state.setIn(["referrals"], action.payload);

        case actions.UPDATE_REFERRAL_SUCCESS:
            const updatedEntry = action.payload;
            const referralIndex = state
                .toJS()
                .referrals?.data.findIndex(
                    (referral: any) => referral.id === action.payload.id
                );
            return state.setIn(["referrals", "data", referralIndex], updatedEntry);

        default:
            return state;
    }
};

export default reducer;