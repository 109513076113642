import React, { Suspense, useEffect } from "react";
import "./App.css";
import ThemeConfigProvider from "./theme/ThemeConfigProvider";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { publicRoutes, restrictedMainRoutes, PermittedRoutes } from "./routes";
import { PublicRoutes, RestrictedRoutes } from "./routes/index.interface";
import { hasTokenExpired } from "./utils/Helpers";
import { IRootState } from "store/interface/Store.interface";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import ConditionalLayout from "layout";

function AppRoutes() {
  const token: string | null = localStorage.getItem("token");
  const navigate = useNavigate();
  const isTokenExpired = hasTokenExpired();
  const loading: boolean = useSelector<IRootState, boolean>(
    ({ userReducer }) => userReducer.loading
  );
  const location = useLocation();

  useEffect(() => {
    if (token && !isTokenExpired) {
      // new Promise((resolve, reject) => {
      // 	// fetch user from token

      // });
      navigate(location.pathname, { replace: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token || isTokenExpired) {
    return (
      <Routes>
        {publicRoutes.map(({ path, Component }: PublicRoutes) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ConditionalLayout />
    </>
  );
}

function App() {
  return (
    <ThemeConfigProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeConfigProvider>
  );
}

export default App;
