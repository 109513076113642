import LogsSaga from "./sagas";
import LogsReducer from "./reducers";
import logsActions from "./actions";

export const logsReducer = {
  logsReducer: LogsReducer,
};

export const logsSaga = [LogsSaga()];

export { logsActions };
