import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    courses: [],
    learningMaterials: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_COURSES_SUCCESS:
      return state.setIn(["courses"], action.payload);

    case actions.FETCH_LEARNING_MATERIALS_SUCCESS:
      return state.setIn(["learningMaterials"], action.payload);

    case actions.SAVE_LEARNING_MATERIALS_SUCCESS:
      if (state.learningMaterials && state.learningMaterials.length > 0) {
        const saveLearningMaterialsIndex = state.learningMaterials.length;
        return state.setIn(
          ["learningMaterials", saveLearningMaterialsIndex],
          action.payload
        );
      }
      return state;

    case actions.SAVE_COURSE_SUCCESS:
      let coursesIndex = 0;

      if (action.event === "ADD") {
        coursesIndex = state.toJS().courses.length;
      }

      if (action.event === "EDIT") {
        coursesIndex = state
          .toJS()
          .courses.findIndex((course: any) => course.id === action.payload.id);
      }

      return state.setIn(["courses", coursesIndex], action.payload);

    case actions.DELETE_COURSE_SUCCESS:
      const deleteIndex = state
        .toJS()
        .courses.findIndex((course: any) => course.id === action.id);
      return state.deleteIn(["courses", deleteIndex]);

    default:
      return state;
  }
};

export default reducer;
