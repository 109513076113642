const actions = {
    FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
    FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
    FETCH_USER_DETAILS_FAILED: "FETCH_USER_DETAILS_FAILED",
    UPDATE_ENROLLMENT: "UPDATE_ENROLLMENT",
    UPDATE_ENROLLMENT_SUCCESS: "UPDATE_ENROLLMENT_SUCCESS",
    UPDATE_ENROLLMENT_FAILED: "UPDATE_ENROLLMENT_FAILED",
  
    fetchUserDetails: (
      id: any,
      child_id: any,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.FETCH_USER_DETAILS,
      id,
      child_id,
      resolve,
      reject,
    }),

    updateEnrollment: (
      data: any,
      resolve: (data: string) => void,
      reject: (value: string) => void
    ) => ({
      type: actions.UPDATE_ENROLLMENT,
      data,
      resolve,
      reject,
    }),
  };
  
  export default actions;
  