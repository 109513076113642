import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const createRefund = takeLatest(
    actions.CREATE_REFUND,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(api.post, "api/fundManagement/refund", action.data, null);
            action.data.id = response.id;
            yield put({
                type: actions.CREATE_REFUND_SUCCESS,
                payload: action.data,
            })
            yield call(action.resolve, response)
        } catch (error) {
            yield call(action.reject, error)
        }
    }
);

const createCreditAndDiscount = takeLatest(
    actions.CREATE_CREDIT_AND_DISCOUNT,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(api.post, "api/fundManagement/addCreditAndDiscount", action.data, null);
            action.data.id = response.id;
            yield put({
                type: actions.CREATE_CREDIT_AND_DISCOUNT_SUCCESS,
                payload: action.data,
            })
            yield call(action.resolve, response)
        } catch (error) {
            yield call(action.reject, error)
        }
    }
);

const fetchAllRefunds = takeLatest(
    actions.FETCH_ALL_REFUNDS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.get,
          'api/fundManagement/allRefunds'
        );
        yield put({
          type: actions.FETCH_ALL_REFUNDS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

export default function* saga() {
    yield all([
        createRefund,
        createCreditAndDiscount,
        fetchAllRefunds
    ]);
  }