import MailingAddressSaga from "./sagas";
import MailingAddressReducer from "./reducers";
import mailingAddressActions from './actions';

export const mailingAddressReducer = {
    mailingAddressReducer: MailingAddressReducer,
}

export const mailingAddressSaga = [MailingAddressSaga()];

export { mailingAddressActions };