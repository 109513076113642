import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { getUserId, urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface StudentsReturnType {
  [x: string]: string;
}

const fetchStudent = takeLatest(
  actions.FETCH_STUDENT,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(api.get, "api/students/" + action.id, false);
      yield put({ type: actions.FETCH_STUDENT_SUCCESS, payload: response });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_STUDENT_FAILED });
      yield call(action.reject, error);
    }
  }
);

const fetchStudents = takeLatest(
  actions.FETCH_STUDENTS,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/students", action),
        false
      );
      yield put({ type: actions.FETCH_STUDENTS_SUCCESS, payload: response });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_STUDENTS_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchProgressReports = takeLatest(
  actions.FETCH_PROGRESS_REPORTS,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(
        api.get,
        "api/progressReport/student/" + action.id,
        false
      );
      yield put({
        type: actions.FETCH_PROGRESS_REPORTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_PROGRESS_REPORTS_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchCurrentCourses = takeLatest(
  actions.FETCH_CURRENT_COURSES,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(
        api.get,
        "api/currentCourse/student/" + action.id,
        false
      );
      yield put({
        type: actions.FETCH_CURRENT_COURSES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_CURRENT_COURSES_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const addProgressReport = takeLatest(
  actions.ADD_PROGRESS_REPORT,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(
        api.post,
        "api/progressReport",
        action.data,
        null
      );
      yield put({
        type: actions.ADD_PROGRESS_REPORT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.ADD_PROGRESS_REPORT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const addCurrentCourse = takeLatest(
  actions.ADD_CURRENT_COURSE,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(
        api.post,
        "api/currentCourse",
        action.data,
        null
      );
      yield put({
        type: actions.ADD_CURRENT_COURSE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.ADD_CURRENT_COURSE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchAllProgressReports = takeLatest(
  actions.FETCH_ALL_PROGRESS_REPORTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const selectedId = action.batch_id;
      let response;
      selectedId
        ? (response = yield call(
            api.get,
            `api/progressReport?batch_id=${selectedId}`
          ))
        : (response = yield call(api.get, `api/progressReport`));
      yield put({
        type: actions.FETCH_ALL_PROGRESS_REPORTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchAllCurrentCourses = takeLatest(
  actions.FETCH_ALL_CURRENT_COURSES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const selectedId = action.batch_id;
      let response;
      selectedId
        ? (response = yield call(
            api.get,
            `api/currentcourse?batch_id=${selectedId}`
          ))
        : (response = yield call(api.get, `api/currentcourse`));
      yield put({
        type: actions.FETCH_ALL_CURRENT_COURSES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);
const fetchMilestone = takeLatest(
  actions.FETCH_MILESTONE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        "api/milestone/student/" + action.id,
        false
      );
      yield put({
        type: actions.FETCH_MILESTONE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const updateMilestone = takeLatest(
  actions.UPDATE_MILESTONE,
  function* (action: AnyAction): Generator<Effect, void, StudentsReturnType> {
    try {
      const response = yield call(api.post, "api/milestone", action.data, null);
      yield put({
        type: actions.UPDATE_MILESTONE_SUCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([
    fetchStudent,
    fetchStudents,
    fetchProgressReports,
    addProgressReport,
    fetchAllProgressReports,
    fetchMilestone,
    updateMilestone,
    addCurrentCourse,
    fetchCurrentCourses,
    fetchAllCurrentCourses,
  ]);
}
