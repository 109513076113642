import FetchQuestionsSaga from "./sagas";
import FetchQuestionsReducer from "./reducers";
import fetchQuestionsActions from "./actions";

export const fetchQuestionsReducer = {
    fetchQuestionsReducer:
    FetchQuestionsReducer
};

export const fetchQuestionsSaga = [FetchQuestionsSaga()];

export { fetchQuestionsActions };