import BranchAdminsSaga from "./sagas";
import BranchAdminsReducer from "./reducers";
import branchAdminsActions from "./actions";

export const branchAdminsReducer = {
  branchAdminsReducer: BranchAdminsReducer,
};

export const branchAdminsSaga = [BranchAdminsSaga()];

export { branchAdminsActions };
