import EmployeeAttendanceSaga from "./sagas";
import EmployeeAttendanceReducer from "./reducers";
import employeeAttendanceActions from "./actions";

export const employeeAttendanceReducer = {
  employeeAttendanceReducer: EmployeeAttendanceReducer,
};

export const employeeAttendanceSaga = [EmployeeAttendanceSaga()];

export { employeeAttendanceActions };