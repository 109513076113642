import SettingsSaga from "./sagas";
import SettingsReducer from "./reducers";
import settingsActions from "./actions";

export const settingsReducer = {
  settingsReducer: SettingsReducer,
};

export const settingsSaga = [SettingsSaga()];

export { settingsActions };
