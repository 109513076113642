const actions = {
    FETCH_ALLCHILD: "FETCH_ALLCHILD",
    FETCH_ALLCHILD_SUCCESS: "FETCH_ALLCHILD_SUCCESS",
    FETCH_ONECHILD: "FETCH_ONECHILD",
    FETCH_ONECHILD_SUCCESS: "FETCH_ONECHILD_SUCCESS",
  
    fetchAllChild: (
      resolve: (token: string) => void,
      reject: (value: string) => void,
      id?: any
    ) => ({
      id,
      type: actions.FETCH_ALLCHILD,
      resolve,
      reject,
    }),
  
    fetchOneChild: (
      resolve: (token: string) => void,
      reject: (value: string) => void,
      id: any
    ) => ({
      type: actions.FETCH_ONECHILD,
      resolve,
      reject,
      payload: { id },
    }),
};
  
export default actions;
  