import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface AttendanceReturnType {
  [x: string]: string;
}

const createAttendance = takeLatest(
  actions.CREATE_ATTENDANCE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/attendance`,
        action.data,
        null
      );
      yield put({
        type: actions.CREATE_ATTENDANCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.CREATE_ATTENDANCE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const checkIn = takeLatest(
  actions.CHECK_IN,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/attendance/checkIn`,
        action.data,
        null
      );
      yield put({
        type: actions.CHECK_IN_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.CHECK_IN_FAILED });
      yield call(action.reject, error);
    }
  }
);

const checkOut = takeLatest(
  actions.CHECK_OUT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/attendance/checkOut`,
        action.data,
        null
      );
      yield put({
        type: actions.CHECK_OUT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.CHECK_OUT_FAILED });
      yield call(action.reject, error);
    }
  }
);

const fetchAttendancees = takeLatest(
  actions.FETCH_ATTENDANCEES,
  function* (action: AnyAction): Generator<Effect, void, AttendanceReturnType> {
    const { resolve, reject, branchId } = action;
    try {
      // const response = yield call(api.get, `api/attendance?branch_id=${branchId}`, false);
      const response = yield call(
        api.get,
        urlGenerator(`api/attendance${branchId ? `?branch_id=${branchId} `: ""}`, action)
      );
      yield put({
        type: actions.FETCH_ATTENDANCEES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_ATTENDANCEES_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchAttendance = takeLatest(
  actions.FETCH_ATTENDANCE,
  function* (action: AnyAction): Generator<Effect, void, AttendanceReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/attendance/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_ATTENDANCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_ATTENDANCE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchAttendanceReport = takeLatest(
  actions.FETCH_ATTENDANCE_REPORT,
  function* (action: AnyAction): Generator<Effect, void, AttendanceReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/attendance/report/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_ATTENDANCE_REPORT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_ATTENDANCE_REPORT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateAttendance = takeLatest(
  actions.UPDATE_ATTENDANCE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/attendance/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_ATTENDANCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.UPDATE_ATTENDANCE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const deleteAttendance = takeLatest(
  actions.DELETE_ATTENDANCE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/attendance/${action.id}`);
      yield put({
        type: actions.DELETE_ATTENDANCE_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.DELETE_ATTENDANCE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const checkEmployeeAccess = takeLatest(
  actions.CHECK_EMPLOYEE_ACCESS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/attendance/verifyEmployee`,
        action.data,
        null
      );
      yield put({
        type: actions.CHECK_EMPLOYEE_ACCESS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.CHECK_EMPLOYEE_ACCESS_FAILED });
      yield call(action.reject, error);
    }
  }
);

export default function* saga() {
  yield all([
    createAttendance,
    fetchAttendancees,
    fetchAttendanceReport,
    updateAttendance,
    deleteAttendance,
    fetchAttendance,
    checkIn,
    checkOut,
    checkEmployeeAccess,
  ]);
}
