import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const {fromJS} = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        trialClasses: [],
        activeTrialClass: {},
        timeSlots: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_TRIAL_CLASS:
            return state.setIn(["loading"], true);

        case actions.FETCH_TRIAL_CLASS_SUCCESS:
            const activeTrialClass = action.payload;
            return state.merge({
                loading: false,
                activeTrialClass: activeTrialClass,
            });
            
        case actions.FETCH_TRIAL_CLASS_FAILED:
            return state.setIn(["loading"], false);

        case actions.FETCH_TRIAL_CLASSES:
            return state.setIn(["loading"], true);

        case actions.FETCH_TRIAL_CLASSES_SUCCESS:
            const trialClasses = action.payload;
            return state.merge({
                loading: false,
                trialClasses: trialClasses, 
            });
            
        case actions.FETCH_TRIAL_CLASSES_FAILED:
            return state.setIn(["loading"], false);

        case actions.UPDATE_TRIAL_CLASS_SUCCESS:
            const updatedEntry = action.payload;
            let evalIndex = state.toJS().trialClasses?.data.findIndex(
                (trialClasses: any) => 
                    trialClasses.id === action.payload.id
             );
            return state.setIn(
                ["trialClasses", "data", evalIndex],
                action.payload
            )    
        
        case actions.FETCH_TRIAL_CLASS_TIME_SLOTS:
            return state.setIn(["loading"], true);
            
        case actions.FETCH_TRIAL_CLASS_TIME_SLOTS_SUCCESS:
            const timeSlots = action.payload;
            return state.merge({
                loading: false,
                timeSlots: timeSlots,
            })
            
        case actions.FETCH_TRIAL_CLASS_TIME_SLOTS_FAILED:
            return state.setIn(["loading"], false);    

        default:
            return state;    
    }
};

export default reducer;