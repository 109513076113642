import CoursesSaga from "./sagas";
import CoursesReducer from "./reducers";
import coursesActions from "./actions";

export const coursesReducer = {
  coursesReducer: CoursesReducer,
};

export const coursesSaga = [CoursesSaga()];

export { coursesActions };
