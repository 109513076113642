const actions = {
    FETCH_QUESTIONS: "FETCH_QUESTIONS",
    FETCH_QUESTIONS_SUCCESS: "FETCH_QUESTIONS_SUCCESS",
    FETCH_QUESTIONS_FAILED: "FETCH_QUESTIONS_FAILED",

    fetchQuestions: (
        resolve: (value: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_QUESTIONS,
        resolve,
        reject,
    }),
};

export default actions;