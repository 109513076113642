import { Log } from "../interface/Log.interface";

const actions = {
  FETCH_LOGS: "FETCH_LOGS",
  FETCH_LOGS_SUCCESS: "FETCH_LOGS_SUCCESS",
  FETCH_LOG: "FETCH_LOG",
  SAVE_LOG: "SAVE_LOG",
  SAVE_LOG_SUCCESS: "SAVE_LOG_SUCCESS",
  DELETE_LOG: "DELETE_LOG",
  DELETE_LOG_SUCCESS: "DELETE_LOG_SUCCESS",
  DELETE_LOG_FAILED: "DELETE_LOG_FAILED",
  DELETE_PERMANENTLY: "DELETE_PERMANENTLY",
  DELETE_PERMANENTLY_SUCCESS: "DELETE_PERMANENTLY_SUCCESS",
  DELETE_PERMANENTLY_FAILED: "DELETE_PERMANENTLY_FAILED",
  UNDO_LOG: "UNDO_LOG",
  UNDO_LOG_SUCCESS: "UNDO_LOG_SUCCESS",
  UNDO_LOG_FAILED: "UNDO_LOG_FAILED",

  fetchLogs: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_LOGS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchLog: (
    id: number | "" | undefined,
    resolve: (value: Log) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_LOG,
    id,
    resolve,
    reject,
  }),

  saveLog: (
    data: Log,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_LOG,
    data,
    event,
    resolve,
    reject,
  }),

  deleteLog: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_LOG,
    id,
    resolve,
    reject,
  }),

  deletePermanently: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_PERMANENTLY,
    id,
    resolve,
    reject,
  }),

  undoLog: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UNDO_LOG,
    id,
    resolve,
    reject,
  }),
};

export default actions;
