import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    branchSelfServices: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_BRANCH_SELF_SERVICES_SUCCESS:
      return state.setIn(["branchSelfServices"], action.payload);

    case actions.SAVE_BRANCH_SELF_SERVICE_SUCCESS:
      let branchSelfServicesIndex = 0;

      if (action.event === "ADD") {
        branchSelfServicesIndex = state.toJS().courses.length;
      }

      if (action.event === "EDIT") {
        branchSelfServicesIndex = state
          .toJS()
          .branchSelfServices.data?.findIndex(
            (branchAssociate: any) => branchAssociate.id === action.payload.id
          );
      }

      return state.setIn(
        ["branchSelfServices", "data", branchSelfServicesIndex],
        action.payload
      );

    case actions.DELETE_BRANCH_SELF_SERVICE_SUCCESS:
      const deleteIndex = state
        .toJS()
        .branchSelfServices.data?.findIndex(
          (branchAssociate: any) => branchAssociate.id === action.id
        );
      return state.deleteIn(["branchSelfServices", "data", deleteIndex]);

    default:
      return state;
  }
};

export default reducer;
