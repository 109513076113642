import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
import { useSelector } from "react-redux";
import { IRootState } from "store/interface/Store.interface";
import { message } from "antd";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    chatBadge: 0,
    notificationBadge: 0,
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.INCREASE_CHAT_BADGE:
      return state.setIn(["chatBadge"], state.toJS().chatBadge + 1);

    case actions.DECREASE_CHAT_BADGE:
      return state.setIn(
        ["chatBadge"],
        state.toJS().chatBadge > 0 ? state.toJS().chatBadge - 1 : 0
      );
    case actions.INCREASE_NOTIFICATION_BADGE:
      return state.setIn(
        ["notificationBadge"],
        state.toJS().notificationBadge + 1
      );

    case actions.DECREASE_NOTIFICATION_BADGE:
      return state.setIn(
        ["notificationBadge"],
        state.toJS().notificationBadge > 0
          ? state.toJS().notificationBadge - 1
          : 0
      );
    case actions.MAKE_CHAT_BADGE_NULL:
      return state.setIn(["chatBadge"], 0);
      
     case actions.MAKE_NOTIFICATION_BADGE_NULL:
      return state.setIn(["notificationBadge"], 0)
    default:
      return state;
  }
};

export default reducer;
