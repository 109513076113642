import PendingStudensSaga from "./sagas";
import PendingStudentsReducer from "./reducers";
import pendingStudentsActions from "./actions";

export const pendingStudentsReducer = {
    pendingStudentsReducer: PendingStudentsReducer,
}

export const pendingStudentsSaga = [PendingStudensSaga()];

export { pendingStudentsActions };