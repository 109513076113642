import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const userSvg = () =>{
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.787 5.48254C20.6277 8.01456 18.9874 9.96509 17.1986 9.96509C15.4099 9.96509 13.7668 8.01518 13.6102 5.48254C13.4471 2.84843 15.0439 1 17.1986 1C19.3533 1 20.9502 2.89636 20.787 5.48254Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.2002 14.1035C13.6702 14.1035 10.2756 16.0966 9.42521 19.9782C9.31255 20.4918 9.59584 20.9997 10.06 20.9997H24.341C24.8052 20.9997 25.0868 20.4918 24.9758 19.9782C24.1254 16.0345 20.7308 14.1035 17.2002 14.1035Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M9.99045 6.85736C9.87162 8.98139 8.63354 10.6542 7.29897 10.6542C5.9644 10.6542 4.72428 8.98205 4.60748 6.85736C4.48611 4.64765 5.69118 3.06836 7.29897 3.06836C8.90675 3.06836 10.1118 4.6882 9.99045 6.85736Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5997 14.1183C9.6039 13.6082 8.50717 13.4121 7.34479 13.4121C4.47609 13.4121 1.7122 15.0065 1.01985 18.1119C0.928822 18.5226 1.15942 18.9291 1.53677 18.9291H7.73096" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
  )
}
export const UserIcon =  (props: Partial<CustomIconComponentProps>)  =>(<Icon component={userSvg} {...props}/>)
