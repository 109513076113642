const actions = {
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_FAILED: "FETCH_NOTIFICATIONS_FAILED",
  FETCH_NOTIFICATION: "FETCH_NOTIFICATION",
  FETCH_NOTIFICATION_SUCCESS: "FETCH_NOTIFICATION_SUCCESS",
  FETCH_NOTIFICATION_FAILED: "FETCH_NOTIFICATION_FAILED",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILED: "UPDATE_NOTIFICATION_FAILED",

  updateNotification: (
    id: number,
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_NOTIFICATION,
    id,
    data,
    resolve,
    reject,
  }),

  fetchNotifications: (
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_NOTIFICATIONS,
    resolve,
    reject,
  }),

  fetchNotification: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_NOTIFICATION,
    resolve,
    reject,
  }),
};

export default actions;
