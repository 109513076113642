const actions = {
  FETCH_CONVERSATIONLIST: "FETCH_CONVERSATIONLIST",
  FETCH_CONVERSATIONLIST_SUCCESS: "FETCH_CONVERSATIONLIST_SUCCESS",

  fetchConversationList: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_CONVERSATIONLIST,
    resolve,
    reject,
  }),
};

export default actions;
