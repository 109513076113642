import AttendanceSaga from "./sagas";
import AttendanceReducer from "./reducers";
import attendanceActions from "./actions";

export const attendanceReducer = {
  attendanceReducer: AttendanceReducer,
};

export const attendanceSaga = [AttendanceSaga()];

export { attendanceActions };
