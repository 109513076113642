import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchbranchSelfServices = takeLatest(
  actions.FETCH_BRANCH_SELF_SERVICES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/branchSelfService", action)
      );
      yield put({
        type: actions.FETCH_BRANCH_SELF_SERVICES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchbranchSelfService = takeLatest(
  actions.FETCH_BRANCH_SELF_SERVICE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `/api/branchSelfService/${action.id}`
      );
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const savebranchSelfService = takeLatest(
  actions.SAVE_BRANCH_SELF_SERVICE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(
          api.post,
          "/api/branchSelfService",
          action.data,
          null
        );
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_BRANCH_SELF_SERVICE_SUCCESS,
          payload: response.data,
          event: response.event,
        });
      }

      if (action.event === "EDIT") {
        yield call(
          api.put,
          `/api/branchSelfService/${action.data.id}`,
          action.data
        );
        yield put({
          type: actions.SAVE_BRANCH_SELF_SERVICE_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const deletebranchSelfService = takeLatest(
  actions.DELETE_BRANCH_SELF_SERVICE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(
        api.delete,
        `/api/branchSelfService/${action.id}`
      );
      yield put({
        type: actions.DELETE_BRANCH_SELF_SERVICE_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield put({ type: actions.DELETE_BRANCH_SELF_SERVICE_FAILED });
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchbranchSelfServices,
    fetchbranchSelfService,
    savebranchSelfService,
    deletebranchSelfService,
  ]);
}
