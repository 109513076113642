import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchRemoveChild = takeLatest(
  actions.FETCH_REMOVE_CHILD,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/removeChild", action)
      );
      yield put({
        type: actions.FETCH_REMOVE_CHILD_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveRemoveChild = takeLatest(
  actions.SAVE_REMOVE_CHILD,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `/api/removeChild`,
        action.data,
        null
      );
      yield put({
        type: actions.SAVE_REMOVE_CHILD_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield call(action.reject, error.response.data.errors);
    }
  }
);

const updateRemoveChildRequest = takeLatest(
  actions.UPDATE_REMOVE_CHILD,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/removeChild/${action.data.id}`,
        action.data
      );

      yield put({
        type: actions.UPDATE_REMOVE_CHILD_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([fetchRemoveChild, saveRemoveChild, updateRemoveChildRequest]);
}
