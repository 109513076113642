import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const CuriculumIcon = (props: Partial<CustomIconComponentProps>) => {
  const curiculumSvg = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M3.54919 8.45572C3.32216 10.1924 3.18425 11.9571 3.1402 13.7451C5.55351 14.7527 7.84886 15.9864 9.99976 17.4198C12.1506 15.9864 14.446 14.7527 16.8593 13.7451C16.8153 11.9571 16.6774 10.1924 16.4503 8.45575M3.54919 8.45572C2.81944 8.21043 2.08083 7.98441 1.33398 7.77826C4.03847 5.88257 6.93926 4.24785 9.99979 2.91064C13.0603 4.24785 15.9611 5.88258 18.6656 7.77827C17.9187 7.98443 17.1801 8.21046 16.4503 8.45575M3.54919 8.45572C5.78856 9.20843 7.94459 10.1426 9.9998 11.2407C12.055 10.1426 14.211 9.20846 16.4503 8.45575M5.62476 12.5002C5.96993 12.5002 6.24976 12.2204 6.24976 11.8752C6.24976 11.53 5.96993 11.2502 5.62476 11.2502C5.27958 11.2502 4.99976 11.53 4.99976 11.8752C4.99976 12.2204 5.27958 12.5002 5.62476 12.5002ZM5.62476 12.5002V9.43724C7.03432 8.56142 8.49445 7.7593 9.99976 7.03626M4.16029 16.6607C5.1366 15.6844 5.62475 14.4048 5.62476 13.1252V11.8752"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
  return <Icon component={curiculumSvg} {...props} />;
};
