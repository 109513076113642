import { Modal, ModalProps } from "antd";

interface AModalProps extends ModalProps {}

function AModal({ children, open, onOk, onCancel, ...others }: AModalProps) {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} {...others}>
      {children}
    </Modal>
  );
}

export default AModal;
