import RefundsSaga from "./sagas";
import RefundsReducer from "./reducers";
import refundsActions from "./actions";

export const refundsReducer = {
  refundsReducer: RefundsReducer,
};

export const refundsSaga = [RefundsSaga()];

export { refundsActions };
