import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export const HomeIcon =  (props: Partial<CustomIconComponentProps>)  =>{
  const homeSvg = () => (<svg width="18" height="18" viewBox="0 0 18 18" fill='none' xmlns="http://www.w3.org/2000/svg">
  <path d="M0.875 8.99998L8.33709 1.53789C8.7032 1.17178 9.2968 1.17178 9.66291 1.53789L17.125 8.99998M2.75 7.12498V15.5625C2.75 16.0802 3.16973 16.5 3.6875 16.5H7.125V12.4375C7.125 11.9197 7.54473 11.5 8.0625 11.5H9.9375C10.4553 11.5 10.875 11.9197 10.875 12.4375V16.5H14.3125C14.8303 16.5 15.25 16.0802 15.25 15.5625V7.12498M5.875 16.5H12.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
  return (
    <Icon component={homeSvg} {...props}/>    
  )
}
