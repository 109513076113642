import { Card, Avatar } from "antd";
import Meta from "antd/es/card/Meta";
import AText from "components/Text";
import React from "react";
import { tokens } from "utils/Constants";
import { capitalizeFirstLetter, generateLetterImageURL } from "utils/Helpers";
import { JWTToken } from "utils/interface/Utils.interface";

function ProfileCard(user: any) {
  return (
    <Card
      style={{
        margin: "1rem 1rem",
        textAlign: "center",
      }}
      cover={
        <div
          style={{
            height: "10vh",
            background: `${tokens.COLOR_SECONDARY_S300}`,
          }}
        ></div>
      }
    >
      <Avatar
        size={88}
        icon={
          <img
            src={generateLetterImageURL(
              user?.user?.UserDetail?.first_name,
              user?.user?.UserDetail?.last_name
            )}
            alt="image"
          />
        }
        style={{
          margin: "-30% 0 15% 0",
        }}
      />
      <Meta
        title={capitalizeFirstLetter(
          `${user?.user?.UserDetail?.first_name} ${user?.user?.UserDetail?.last_name}`
        )}
        description={
          <>
          <AText
            style={{ fontSize: "0.7rem" }}
            content={`${
              user.user?.email ? user.user?.email : user.user?.username
            }`}
            />
            <br />
          <p>
            ID: {user.user?.UserDetail ? user.user?.UserDetail.unique_user_id : ""}
            </p>  
            </>
        }
        
      />
    </Card>
  );
  
}

export default ProfileCard;
