import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchCamps = takeLatest(
    actions.FETCH_CAMPS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(api.get, urlGenerator("api/camp", action));
        yield put({
          type: actions.FETCH_CAMPS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

const saveCamp = takeLatest(
    actions.SAVE_CAMP,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        if (action.event === "ADD") {
          const response = yield call(api.post, "api/camp", action.data, null);
          action.data.id = response.id;
          yield put({
            type: actions.SAVE_CAMP_SUCCESS,
            payload: action.data,
            event: action.event,
          });
        }
  
        if (action.event === "EDIT") {
          yield call(api.put, `api/camp/${action.data.id}`, action.data);
          yield put({
            type: actions.SAVE_CAMP_SUCCESS,
            payload: action.data,
            event: action.event,
          });
        }
        yield call(action.resolve, "success");
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

  export default function* saga() {
    yield all([
     saveCamp,
     fetchCamps
    ]);
  }
  
  