import AssignmentSaga from "./sagas";
import AssignmentReducer from "./reducers";
import assignmentActions from "./actions";

export const assignmentReducer = {
  assignmentReducer: AssignmentReducer,
};

export const assignmentSaga = [AssignmentSaga()];

export { assignmentActions };
