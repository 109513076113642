const actions = {
  FETCH_COURSEINFO: "FETCH_COURSEINFO",
  FETCH_COURSEINFO_SUCCESS: "FETCH_COURSEINFO_SUCCESS",
  FETCH_BRANCHREPORT: "FETCH_BRANCHREPORT",
  FETCH_BRANCHREPORT_SUCCESS: "FETCH_BRANCHREPORT_SUCCESS",
  FETCH_YEAR: "FETCH_YEAR",
  FETCH_YEAR_SUCCESS: "FETCH_YEAR_SUCCESS",

  fetchCourseInfo: (
    resolve: (token: string) => void,
    reject: (value: string) => void,
    id?: any
  ) => ({
    id,
    type: actions.FETCH_COURSEINFO,
    resolve,
    reject,
  }),

  fetchBrachReportInfo: (
    resolve: (token: string) => void,
    reject: (value: string) => void,
    id?: any,
    year?: any
  ) => ({
    id,
    year,
    type: actions.FETCH_BRANCHREPORT,
    resolve,
    reject,
  }),

  fetchYears: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_YEAR,
    resolve,
    reject,
  }),
};

export default actions;
