import ParentAndChildAssociationSaga from "./sagas";
import ParentAndChildAssociationReducer from "./reducers";
import parentAndChildAssociationActions from "./actions";

export const parentAndChildAssociationReducer = {
    parentAndChildAssociationReducer: ParentAndChildAssociationReducer,
}

export const parentAndChildAssociationSaga = [ParentAndChildAssociationSaga()];

export { parentAndChildAssociationActions };