import CouponSaga from "./sagas";
import CouponReducer from "./reducers";
import couponActions from "./actions";

export const couponReducer = {
  couponReducer: CouponReducer,
};

export const couponSaga = [CouponSaga()];

export { couponActions };
