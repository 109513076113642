import BranchAdminSaga from "./sagas";
import BranchAdminReducer from "./reducers";
import BranchAdminAction from "./actions";

export const branchAdminReducer = {
  branchAdminReducer: BranchAdminReducer,
};

export const branchAdminSaga = [BranchAdminSaga()];

export { BranchAdminAction };
