import { Radio, RadioGroupProps } from 'antd'
import React from 'react'

interface ARadioGroupProps extends RadioGroupProps{

}

function ARadioGroup({children,...others}:ARadioGroupProps) {
  return (
    <Radio.Group
        {...others}
    >
        {children}
    </Radio.Group>
  )
}

export default ARadioGroup