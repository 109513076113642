import { Map } from "immutable";
import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
      childDetails: {},
    })
  );

  export interface Action {
    type: string;
    payload?: any;
    event?: string;
  }

  const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case actions.FETCH_CHILD_DETAILS_SUCCESS:
        return state.setIn(["childDetails"], action.payload);
        
      default:
        return state;
    }
  };

  export default reducer;