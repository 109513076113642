import { Card } from "antd";
import { tokens } from "utils/Constants";
import { JWTToken } from "utils/interface/Utils.interface";

import FormFields from "./formfields";
import Loader from "components/Loader";
interface PlatformFormProps {
  userInfo?: JWTToken | null;
  onCancel?: () => void;
  information? : any
  branch?: any;
  userDetails?: any;
  id?: any
  newEnrollment?: boolean;
}

const PlacementForm: React.FC<PlatformFormProps> = ({ userInfo, onCancel, information, branch, userDetails, id, newEnrollment }) => {
  return (
    <>
      {newEnrollment ? (
        <Card
        className="placement-form"
        style={{
          width: "100%",
          margin: "2rem 3rem",
          zIndex: 3,
          background: tokens.BG_LIGHT,
          padding: "10px",
          boxShadow: tokens.CARD_BOX_SHADOW,
          border: "1px solid #D9D9D9",
          borderRadius: "10px",
          maxWidth: "80vw",
        }}
      >
        <FormFields userInfo={userInfo} onCancel={onCancel} information={information} branch={branch} userDetails={userDetails} id={id} newEnrollment={newEnrollment} />
      </Card>
      ) : !!!userInfo ? (
        <Card
          className="placement-form"
          style={{
            width: "100%",
            // margin: "-4% 20%",
            zIndex: 3,
            background: tokens.BG_LIGHT,
            padding: "10px",
            boxShadow: tokens.CARD_BOX_SHADOW,
            border: "1px solid #D9D9D9",
            borderRadius: "10px",
            // maxWidth: "60vw",
          }}
        >
          <FormFields userInfo={userInfo} onCancel={onCancel} information={information} branch={branch} userDetails={userDetails} id={id}/>
        </Card>
      ) : (
        <>
          <FormFields userInfo={userInfo} onCancel={onCancel} information={information} branch={branch} userDetails={userDetails} id={id}/>
        </>
      )}
    </>
  );
};

export default PlacementForm;
