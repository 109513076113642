import ChildSaga from "./sagas";
import ChildReducer from "./reducers";
import ChildAction from "./actions";

export const childReducer = {
  childReducer: ChildReducer,
};

export const childSaga = [ChildSaga()];

export { ChildAction };
