import { Space, theme } from "antd";

const NotFound = () => {
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <Space
      align="center"
      style={{
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        fontFamily: token.fontFamily,
      }}
    >
      Sorry ! , The Page you are looking for does not exist
    </Space>
  );
};

export default NotFound;
