import BranchSaga from "./sagas";
import BranchReducer from "./reducers";
import branchActions from "./actions";

export const branchReducer = {
  branchReducer: BranchReducer,
};

export const branchSaga = [BranchSaga()];

export { branchActions };
