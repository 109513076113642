const actions = {
  FETCH_COUPONS: "FETCH_COUPONS",
  FETCH_COUPONS_SUCCESS: "FETCH_COUPONS_SUCCESS",
  FETCH_COUPONS_FAILED: "FETCH_COUPONS_FAILED",
  FETCH_COUPON: "FETCH_COUPON",
  FETCH_COUPON_SUCCESS: "FETCH_COUPON_SUCCESS",
  FETCH_COUPON_FAILED: "FETCH_COUPON_FAILED",
  CREATE_COUPON: "CREATE_COUPON",
  CREATE_COUPON_SUCCESS: "CREATE_COUPON_SUCCESS",
  CREATE_COUPON_FAILED: "CREATE_COUPON_FAILED",
  UPDATE_COUPON: "UPDATE_COUPON",
  UPDATE_COUPON_SUCCESS: "UPDATE_COUPON_SUCCESS",
  UPDATE_COUPON_FAILED: "UPDATE_COUPON_FAILED",
  DELETE_COUPON: "DELETE_COUPON",
  DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
  DELETE_COUPON_FAILED: "DELETE_COUPON_FAILED",

  createCoupon: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CREATE_COUPON,
    data,
    resolve,
    reject,
  }),

  updateForm: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_COUPON,
    data,
    resolve,
    reject,
  }),

  fetchCoupons: (
    page: number,
    pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_COUPONS,
    page,
    pageSize,
    resolve,
    reject,
  }),

  fetchCoupon: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_COUPON,
    resolve,
    reject,
  }),

  deleteCoupon: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_COUPON,
    id,
    resolve,
    reject,
  }),
};

export default actions;
