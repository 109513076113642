import { Event, News } from "../interface/News&Events.interface";

const actions = {
  FETCH_EVENTS: "FETCH_EVENTS",
  FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
  FETCH_EVENT: "FETCH_EVENT",
  SAVE_EVENT: "SAVE_EVENT",
  SAVE_EVENT_SUCCESS: "SAVE_EVENT_SUCCESS",
  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  FETCH_ALL_NEWS: "FETCH_ALL_NEWS",
  FETCH_ALL_NEWS_SUCCESS: "FETCH_ALL_NEWS_SUCCESS",
  FETCH_NEWS: "FETCH_NEWS",
  SAVE_NEWS: "SAVE_NEWS",
  SAVE_NEWS_SUCCESS: "SAVE_NEWS_SUCCESS",
  DELETE_NEWS: "DELETE_NEWS",
  DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",

  fetchEvents: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_EVENTS,
    resolve,
    reject,
  }),

  fetchEvent: (
    id: number | "" | undefined,
    resolve: (value: Event) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_EVENT,
    id,
    resolve,
    reject,
  }),

  saveEvent: (
    data: Event,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_EVENT,
    data,
    event,
    resolve,
    reject,
  }),

  deleteEvent: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_EVENT,
    id,
    resolve,
    reject,
  }),

  fetchAllNews: (
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_ALL_NEWS,
    resolve,
    reject,
  }),

  fetchNews: (
    id: number | "" | undefined,
    resolve: (value: News) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_NEWS,
    id,
    resolve,
    reject,
  }),

  saveNews: (
    data: News,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_NEWS,
    data,
    event,
    resolve,
    reject,
  }),

  deleteNews: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_NEWS,
    id,
    resolve,
    reject,
  }),
};

export default actions;
