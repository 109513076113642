import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface ParentAndChildAssociationReturnType {
    [x: string]: string;
}

const fetchParentDetails = takeLatest(
    actions.FETCH_PARENT_DETAILS,
    function* (
      action: AnyAction
    ): Generator<Effect, void, ParentAndChildAssociationReturnType> {
      try {
        const response = yield call(
          api.get,
          urlGenerator("api/parentAndChildAssociation/getAllParents", action),
          false
        );
        yield put({
          type: actions.FETCH_PARENT_DETAILS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.FETCH_PARENT_DETAILS_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );  

  const fetchSingleParentDetail = takeLatest(
    actions.FETCH_SINGLE_PARENT_DETAIL,
    function* (
      action: AnyAction
    ): Generator<Effect, void, ParentAndChildAssociationReturnType> {
      try {
        const { id } = action;
        const parent_id = parseInt(id);
        const response = yield call(
          api.get, `api/parentAndChildAssociation/getParentDetail?parentId=${parent_id}`,
          false
        );
        yield put({
          type: actions.FETCH_SINGLE_PARENT_DETAIL_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.FETCH_SINGLE_PARENT_DETAIL_FAILED });
        yield call(action.reject, "reject");
      }
    }
  ); 

  const removeChild = takeLatest(
    actions.REMOVE_CHILD,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/parentAndChildAssociation/removeChild/${action.childId}`,
          action
        );
        yield put({
          type: actions.REMOVE_CHILD_SUCCESS,
          payload: response,
        });
  
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, "reject");
      }
    }
  );

  const sendSecurityCode = takeLatest(
    actions.SEND_SECURITY_CODE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.post,
          `api/parentAndChildAssociation/sendSecurityCode/${action.childId}`,
          action,
          null
        );
        yield put({
          type: actions.SEND_SECURITY_CODE_SUCCESS,
          payload: response,
        });
  
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, "reject");
      }
    }
  );

  const updateParentDetails = takeLatest(
    actions.UPDATE_PARENT_DETAILS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
          const response = yield call(api.put, `/api/parentAndChildAssociation/updateParent/${action.data.id}`, action.data);
          yield put({
            type: actions.UPDATE_PARENT_DETAILS_SUCCESS,
            payload: action.data,
            event: action.event,
          });
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, error);
      }
    }
  );

  export default function* saga() {
    yield all([fetchParentDetails, fetchSingleParentDetail, removeChild, sendSecurityCode, updateParentDetails]);
  }  
