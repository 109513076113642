import MyKidsSaga from "./sagas";
import MyKidsReducer from "./reducers";
import MyKidsActions from "./actions";

export const myKidsReducer = {
  myKidsReducer: MyKidsReducer,
};

export const myKidsSaga = [MyKidsSaga()];

export { MyKidsActions };
