import NotificationSaga from "./sagas";
import NotificationReducer from "./reducers";
import notificationActions from "./actions";

export const notificationReducer = {
  notificationReducer: NotificationReducer,
};

export const notificationSaga = [NotificationSaga()];

export { notificationActions };
