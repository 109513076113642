import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    removeChildRequests: Map({
      data: [],
    }),
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case (actions.FETCH_REMOVE_CHILD, actions.UPDATE_REMOVE_CHILD):
      return state.setIn(["loading"], true);

    case actions.FETCH_REMOVE_CHILD_SUCCESS:
      const removeChildRequests = action.payload;
      return state.merge({
        loading: false,
        removeChildRequests: removeChildRequests,
      });

    case actions.FETCH_REMOVE_CHILD_SUCCESS:
      return state.setIn(["removeChildRequests"], action.payload);

    case actions.SAVE_REMOVE_CHILD_SUCCESS:
      let removeChildIndex = 0;
      removeChildIndex = state.toJS().removeChildRequests.data.length;
      return state.setIn(
        ["removeChildRequests", "data", removeChildIndex],
        action.payload
      );

    case actions.SAVE_REMOVE_CHILD_FAILED:
      return state.setIn(["loading"], false);

    case actions.UPDATE_REMOVE_CHILD_SUCCESS:
      let updateRemoveChildIndex = 0;
      updateRemoveChildIndex = state
        .toJS()
        .removeChildRequests?.data?.findIndex(
          (removeChildRequest: any) =>
            removeChildRequest.id === action.payload.id
        );
      return state.setIn(
        ["removeChildRequests", "data", updateRemoveChildIndex],
        action.payload
      );

    default:
      return state;
  }
};

export default reducer;
