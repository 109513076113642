const actions = {
    FETCH_REFERRALS: "FETCH_REFERRALS",
    FETCH_REFERRALS_SUCCESS: "FETCH_REFERRALS_SUCCESS",
    FETCH_REFERRALS_FAILED: "FETCH_REFERRALS_FAILED",
    UPDATE_REFERRAL: "UPDATE_REFERRAL",
    UPDATE_REFERRAL_SUCCESS: "UPDATE_REFERRAL_SUCCESS",
    UPDATE_REFERRAL_FAILED: "UPDATE_REFERRAL_FAILED",

    fetchReferrals: (
        resolve: (token: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_REFERRALS,
        resolve,
        reject,
    }),

    updateReferral: (
        data: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.UPDATE_REFERRAL,
        data,
        resolve,
        reject,
    })
}

export default actions;