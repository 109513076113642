import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object;
}

const fetchCustomerSupportAdmins = takeLatest(
    actions.FETCH_CUSTOMER_SUPPORT_ADMINS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.get,
          urlGenerator("api/customerSupportAdmin", action)
        );
        yield put({
          type: actions.FETCH_CUSTOMER_SUPPORT_ADMINS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, error);
      }
    }
  );
  
  const fetchCustomerSupportAdmin = takeLatest(
    actions.FETCH_CUSTOMER_SUPPORT_ADMIN,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(api.get, `/api/customerSupportAdmin/${action.id}`);
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

  const saveCustomerSupportAdmin = takeLatest(
    actions.SAVE_CUSTOMER_SUPPORT_ADMIN,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        if (action.event === "ADD") {
          const response = yield call(
            api.post,
            "/api/customerSupportAdmin",
            action.data,
            null
          );
          action.data.id = response.id;
          yield put({
            type: actions.SAVE_CUSTOMER_SUPPORT_ADMIN_SUCCESS,
            payload: response.data,
            event: response.event,
          });
        }
  
        if (action.event === "EDIT") {
          yield call(api.put, `/api/customerSupportAdmin/${action.data.user_id}`, action.data);
          yield put({
            type: actions.SAVE_CUSTOMER_SUPPORT_ADMIN_SUCCESS,
            payload: action.data,
            event: action.event,
          });
        }
        yield call(action.resolve, "success");
      } catch (error) {   
        yield call(action.reject, error);
      }
    }
  );

  const deleteCustomerSupportAdmin = takeLatest(
    actions.DELETE_CUSTOMER_SUPPORT_ADMIN,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const responseId = yield call(
          api.delete,
          `/api/customerSupportAdmin/${action.id}`
        );
        if(!!responseId.status){
          yield put({
            type: actions.DELETE_CUSTOMER_SUPPORT_ADMIN_SUCCESS,
            id: action.id,
          });
          yield call(action.resolve, responseId);
        }
      } catch (error) {
        yield put({ type: actions.DELETE_CUSTOMER_SUPPORT_ADMIN_FAILED });
        yield call(action.reject, "failed");
      }
    }
  );

  export default function* saga() {
    yield all([
        fetchCustomerSupportAdmins,
        fetchCustomerSupportAdmin,
        saveCustomerSupportAdmin,
        deleteCustomerSupportAdmin,
    ])
  }