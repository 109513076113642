const actions = {
  FETCH_CHILDINFO: "FETCH_CHILDINFO",
  FETCH_CHILDINFO_SUCCESS: "FETCH_CHILDINFO_SUCCESS",

  fetchChildInfo: (
    resolve: (token: string) => void,
    reject: (value: string) => void,
    id?: any
  ) => ({
    id,
    type: actions.FETCH_CHILDINFO,
    resolve,
    reject,
  }),
};

export default actions;
