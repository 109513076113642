import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        maillingAddress: [],
    })
)

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.CREATE_MAILING_ADDRESS_SUCCESS:
            const saveMailingAddressIndex = state.toJS().maillingAddress.length;
            return state.setIn(["maillingAddress", saveMailingAddressIndex], action.payload);
        
            default:
                return state;
    }
}

export default reducer;