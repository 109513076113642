import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS({
  mychildren: [],
  activeChild: [],
});

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_ALLCHILD_SUCCESS:
      return state.setIn(["mychildren"], action.payload);

    case actions.FETCH_ONECHILD_SUCCESS:
      return state.setIn(["activeChild"], action.payload);  

    default:
      return state;
  }
};

export default reducer;