import { Map } from "immutable";
import changeRequestactions from "./actions";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    classTime: [],
    currentClassTime: {},
    loading: false,
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case changeRequestactions.SAVE_CHANGEREQUEST_SUCCESS:
      const saveChangeRequestsIndex = state.toJS().classTime.length;
      return state.setIn(
        ["classTime", saveChangeRequestsIndex],
        action.payload
      );

    case changeRequestactions.FETCH_CHANGEREQUESTS:
    case changeRequestactions.FETCH_CHANGEREQUEST:
      return state.setIn(["loading"], true);

    case changeRequestactions.FETCH_CHANGEREQUEST_SUCCESS:
      return state.merge({
        loading: false,
        currentClassTime: action.payload,
      });

    case changeRequestactions.FETCH_CHANGEREQUESTS_SUCCESS:
      const classTime = action.payload;
      return state.merge({
        loading: false,
        classTime: classTime,
      });

    case actions.UPDATE_CHANGEREQUEST_SUCCESS:
      let changeIndex = 0;
      changeIndex = state
        .toJS()
        .classTime.findIndex(
          (timeChange: any) => timeChange.id === action.payload.id
        );
      return state.setIn(["classTime", changeIndex], action.payload);

    case actions.FETCH_ENROLLMENTS_SUCCESS:
      return state.setIn(["enrollments"], action.payload);

    default:
      return state;
  }
};

export default reducer;
