import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    events: [],
    news: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_EVENTS_SUCCESS:
      return state.setIn(["events"], action.payload);

    case actions.FETCH_ALL_NEWS_SUCCESS:
      return state.setIn(["news"], action.payload);

    case actions.SAVE_EVENT_SUCCESS:
      let eventsIndex = 0;

      if (action.event === "ADD") {
        eventsIndex = state.toJS().events.length;
      }

      if (action.event === "EDIT") {
        eventsIndex = state
          .toJS()
          .events.findIndex((event: any) => event.id === action.payload.id);
      }

      return state.setIn(["events", eventsIndex], action.payload);

    case actions.SAVE_NEWS_SUCCESS:
      let newsIndex = 0;

      if (action.event === "ADD") {
        newsIndex = state.toJS().news.length;
      }

      if (action.event === "EDIT") {
        newsIndex = state
          .toJS()
          .news.findIndex((news: any) => news.id === action.payload.id);
      }

      return state.setIn(["news", newsIndex], action.payload);

    case actions.DELETE_NEWS_SUCCESS:
      const deleteNewsIndex = state
        .toJS()
        .events.findIndex((news: any) => news.id === action.id);
      return state.deleteIn(["news", deleteNewsIndex]);

    case actions.DELETE_EVENT_SUCCESS:
      const deleteIndex = state
        .toJS()
        .events.findIndex((event: any) => event.id === action.id);
      return state.deleteIn(["events", deleteIndex]);

    default:
      return state;
  }
};

export default reducer;
