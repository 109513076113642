import TimeSlotSaga from "./sagas";
import TimeSlotReducer from "./reducers";
import timeSlotActions from "./actions";

export const timeSlotReducer = {
  timeSlotReducer: TimeSlotReducer,
};

export const timeSlotSaga = [TimeSlotSaga()];

export { timeSlotActions };
