import { createContext } from "react";
import { notification } from "antd";
import { NotificationProps } from "pages/notifications";
import { MessageOutlined, BellOutlined } from "@ant-design/icons";
import { tokens } from "utils/Constants";

const Context = createContext({ name: "Default" });
export const openNotification = (
  placement: any,
  data: NotificationProps,
  handleClick: (data: NotificationProps) => void
) => {
  const key = `open${Date.now()}`; // Generate a unique key

  notification.info({
    message: `${data?.title}`,
    description: (
      <Context.Consumer>{({ name }) => data.message}</Context.Consumer>
    ),
    placement,
    icon:
      data.model_type === "Chat" || data.model_type === "GroupChat" ? (
        <MessageOutlined style={{ color: tokens.COLOR_PRIMARY }} />
      ) : (
        <BellOutlined style={{ color: tokens.COLOR_PRIMARY }} />
      ),
    duration: 5,
    // onClick: handleClick,
    onClick: () => handleClick(data), // Pass data and source_id
  });
};
