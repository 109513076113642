import UserDetailsSaga from "./sagas";
import UserDetailsReducer from "./reducers";
import userDetailsActions from "./actions";

export const userDetailsReducer = {
    userDetailsReducer: UserDetailsReducer,
};

export const userDetailsSaga = [UserDetailsSaga()];

export { userDetailsActions };
