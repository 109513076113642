const actions = {
    FETCH_TRIAL_CLASSES: "FETCH_TRIAL_CLASSES",
    FETCH_TRIAL_CLASSES_SUCCESS: "FETCH_TRIAL_CLASSES_SUCCESS",
    FETCH_TRIAL_CLASSES_FAILED: "FETCH_TRIAL_CLASSES_FAILED",
    UPDATE_TRIAL_CLASS: "UPDATE_TRIAL_CLASS",
    UPDATE_TRIAL_CLASS_SUCCESS: "UPDATE_TRIAL_CLASS_SUCCESS",
    UPDATE_TRIAL_CLASS_FAILED: "UPDATE_TRIAL_CLASS_FAILED",
    FETCH_TRIAL_CLASS_TIME_SLOTS: "FETCH_TIME_SLOT",
    FETCH_TRIAL_CLASS_TIME_SLOTS_SUCCESS: "FETCH_TIME_SLOT_SUCCESS",
    FETCH_TRIAL_CLASS_TIME_SLOTS_FAILED: "FETCH_TIME_SLOT_FAILED",
    FETCH_TRIAL_CLASS: "FETCH_TRIAL_CLASS",
    FETCH_TRIAL_CLASS_SUCCESS: "FETCH_TRIAL_CLASS_SUCCESS",
    FETCH_TRIAL_CLASS_FAILED: "FETCH_TRIAL_CLASS_FAILED",

    fetchTrialClasses: (
        // page: number,
        // pageSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_TRIAL_CLASSES,
        // page,
        // pageSize,
        resolve,
        reject
    }), 

    fetchTrialClass: (
        id: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        id,
        type: actions.FETCH_TRIAL_CLASS,
        resolve,
        reject
    }),

    updateTrialClass: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.UPDATE_TRIAL_CLASS,
        data,
        resolve,
        reject,
    }),

    fetchTrialClassTimeSlots: (
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_TRIAL_CLASS_TIME_SLOTS,
        resolve,
        reject
    })
}

export default actions;

