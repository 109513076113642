import { PickupPersonSchema } from "../type";

const actions = {
  FETCH_PICKUP_PERSONS: "FETCH_PICKUP_PERSONS",
  FETCH_PICKUP_PERSONS_SUCCESS: "FETCH_PICKUP_PERSONS_SUCCESS",
  SAVE_PICKUP_PERSON: "SAVE_PICKUP_PERSON",
  SAVE_PICKUP_PERSON_SUCCESS: "SAVE_PICKUP_PERSON_SUCCESS",
  DELETE_PICKUP_PERSON: "DELETE_PICKUP_PERSON",
  DELETE_PICKUP_PERSON_SUCCESS: "DELETE_PICKUP_PERSON_SUCCESS",
  DELETE_PICKUP_PERSON_FAILED: "DELETE_PICKUP_PERSON_FAILED",
  VERIFY_PICKUP_PERSON: "VERIFY_PICKUP_PERSON",
  VERIFY_PICKUP_PERSON_SUCCESS: "VERIFY_PICKUP_PERSON_SUCCESS",

  fetchPickupPersons: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_PICKUP_PERSONS,
    resolve,
    reject,
  }),

  savePickupPerson: (
    data: PickupPersonSchema,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_PICKUP_PERSON,
    data,
    event,
    resolve,
    reject,
  }),

  verifyPickupPerson:(
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.VERIFY_PICKUP_PERSON,
    id,
    resolve,
    reject,
  }),


  deletePickupPerson: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_PICKUP_PERSON,
    id,
    resolve,
    reject,
  }),
};

export default actions;
