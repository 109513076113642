import ConversatinListSaga from "./sagas";
import ConversationListReducer from "./reducers";
import ConversatinListActions from "../message/actions";

export const conversationListReducer = {
  conversationListReducer: ConversationListReducer,
};

export const conversationListSaga = [ConversatinListSaga()];

export { ConversatinListActions };
