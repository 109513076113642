import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    coupons: [],
    activeCoupon: {},
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.CREATE_COUPON_SUCCESS:
      const saveCouponIndex = state.toJS().coupons.length;
      return state.setIn(["coupons", saveCouponIndex], action.payload);

    case (actions.FETCH_COUPONS, actions.FETCH_COUPON):
      return state.setIn(["loading"], true);

    case actions.FETCH_COUPON_SUCCESS:
      return state.merge({
        loading: false,
        activeCoupon: action.payload,
      });

    case actions.FETCH_COUPONS_SUCCESS:
      const coupons = action.payload;
      return state.merge({
        loading: false,
        coupons: coupons,
      });

    case (actions.FETCH_COUPONS_FAILED, actions.CREATE_COUPON_FAILED):
      return state.setIn(["loading"], false);

    case actions.UPDATE_COUPON_SUCCESS:
      const updatedEntry = action.payload;
      const couponIndex = state
        .toJS()
        .coupons.findIndex((coupon: any) => coupon.id === action.payload.id);
      return state.setIn(["coupons", couponIndex], updatedEntry);

    case actions.DELETE_COUPON_SUCCESS:
      const delCouponIndex = state
        .toJS()
        .coupons?.data?.findIndex(
          (coupon: any) => coupon.id === action.payload
        );

      return state.deleteIn(["coupons", "data", delCouponIndex]);

    default:
      return state;
  }
};

export default reducer;
