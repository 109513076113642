import CustomerSupportAdminsSaga from "./sagas";
import CustomerSupportAdminsReducer from "./reducers";
import customerSupportAdminsActions from "./actions";

export const customerSupportAdminsReducer = {
    customerSupportAdminsReducer: CustomerSupportAdminsReducer,
};

export const customerSupportAdminsSaga = [CustomerSupportAdminsSaga()];

export { customerSupportAdminsActions };
