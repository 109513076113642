import InstructorsSaga from "./sagas";
import InstructorsReducer from "./reducers";
import instructorsActions from "./actions";

export const instructorsReducer = {
	instructorsReducer: InstructorsReducer,
};

export const instructorsSaga = [InstructorsSaga()];

export { instructorsActions };
