import { CustomerSupportAdmin } from "../interface/CustomerSupportAdmin.interface"

const actions = {
    FETCH_CUSTOMER_SUPPORT_ADMINS: "FETCH_CUSTOMER_SUPPORT_ADMINS",
    FETCH_CUSTOMER_SUPPORT_ADMINS_SUCCESS: "FETCH_CUSTOMER_SUPPORT_ADMINS_SUCCESS",
    FETCH_CUSTOMER_SUPPORT_ADMINS_FAILED: "FETCH_CUSTOMER_SUPPORT_ADMINS_FAILED",
    FETCH_CUSTOMER_SUPPORT_ADMIN: "FETCH_CUSTOMER_SUPPORT_ADMIN",
    FETCH_CUSTOMER_SUPPORT_ADMIN_SUCCESS: "FETCH_CUSTOMER_SUPPORT_ADMIN_SUCCESS",
    FETCH_CUSTOMER_SUPPORT_ADMIN_FAILED: "FETCH_CUSTOMER_SUPPORT_ADMIN_FAILED",
    SAVE_CUSTOMER_SUPPORT_ADMIN: "SAVE_CUSTOMER_SUPPORT_ADMIN",
    SAVE_CUSTOMER_SUPPORT_ADMIN_SUCCESS: "SAVE_CUSTOMER_SUPPORT_ADMIN_SUCCESS",
    SAVE_CUSTOMER_SUPPORT_ADMIN_FAILED: "SAVE_CUSTOMER_SUPPORT_ADMIN_FAILED",
    DELETE_CUSTOMER_SUPPORT_ADMIN: "DELETE_CUSTOMER_SUPPORT_ADMIN",
    DELETE_CUSTOMER_SUPPORT_ADMIN_SUCCESS: "DELETE_CUSTOMER_SUPPORT_ADMIN_SUCCESS",
    DELETE_CUSTOMER_SUPPORT_ADMIN_FAILED: "DELETE_CUSTOMER_SUPPORT_ADMIN_FAILED",

    fetchCustomerSupportAdmins: (
        // page: number,
        // pageSize: number,
        resolve: (token: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_CUSTOMER_SUPPORT_ADMINS,
        // page,
        // pageSize,
        resolve,
        reject,
    }),

    fetchCustomerSupportAdmin : (
        id: number | " " | undefined,
        resolve: (value: CustomerSupportAdmin) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_CUSTOMER_SUPPORT_ADMIN,
        id,
        resolve,
        reject,
    }),

    saveCustomerSupportAdmin: (
        data: CustomerSupportAdmin,
        event: string,
        resolve: (value: string) => void,
        reject: (value: string) => void, 
    ) => ({
        type: actions.SAVE_CUSTOMER_SUPPORT_ADMIN,
        data,
        event,
        resolve,
        reject,
    }),

    deleteCustomerSupportAdmin: (
        id: number | " " | undefined,
        resolve: (value: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.DELETE_CUSTOMER_SUPPORT_ADMIN,
        id,
        resolve,
        reject,
    }),
}

export default actions;