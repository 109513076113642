import { LeaveRequestListState } from "../interface";

const Leaveactions = {
  FETCH_LEAVE_REQUESTS: " FETCH_LEAVE_REQUESTS",
  FETCH_LEAVE_REQUESTS_SUCCESS: "FETCH_LEAVE_REQUESTS_SUCCESS",
  SAVE_LEAVE_REQUEST: "SAVE_LEAVE_REQUEST",
  SAVE_LEAVE_REQUEST_SUCCESS: "SAVE_LEAVE_REQUEST_SUCCESS",
  UPDATE_LEAVE_REQUEST: "UPDATE_LEAVE_REQUEST",
  UPDATE_LEAVE_REQUEST_SUCCESS: "UPDATE_LEAVE_REQUEST_SUCCESS",

  fetchLeaveRequests: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void,
  ) => ({
    type: Leaveactions.FETCH_LEAVE_REQUESTS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  saveLeaveRequest: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: Leaveactions.SAVE_LEAVE_REQUEST,
    data,
    resolve,
    reject,
  }),

  updateLeaveRequest: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: Leaveactions.UPDATE_LEAVE_REQUEST,
    data,
    resolve,
    reject,
  }),
};

export default Leaveactions;
