import FetchSurveyAnswersSaga from "./sagas";
import FetchSurveyAnswersReducer from "./reducers";
import fetchSurveyAnswersActions from "./actions";

export const fetchSurveyAnswersReducer = {
    fetchSurveyAnswersReducer:
    FetchSurveyAnswersReducer
};

export const fetchSurveyAnswersSaga =[FetchSurveyAnswersSaga()];

export { fetchSurveyAnswersActions };