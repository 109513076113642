import { BranchAssociate } from "../interface/BranchAssociate.interface";

const actions = {
  FETCH_BRANCH_ASSOCIATES: "FETCH_BRANCH_ASSOCIATES",
  FETCH_BRANCH_ASSOCIATES_SUCCESS: "FETCH_BRANCH_ASSOCIATES_SUCCESS",
  FETCH_BRANCH_ASSOCIATE: "FETCH_BRANCH_ASSOCIATE",
  SAVE_BRANCH_ASSOCIATE: "SAVE_BRANCH_ASSOCIATE",
  SAVE_BRANCH_ASSOCIATE_SUCCESS: "SAVE_BRANCH_ASSOCIATE_SUCCESS",
  DELETE_BRANCH_ASSOCIATE: "DELETE_BRANCH_ASSOCIATE",
  DELETE_BRANCH_ASSOCIATE_SUCCESS: "DELETE_BRANCH_ASSOCIATE_SUCCESS",
  DELETE_BRANCH_ASSOCIATE_FAILED: "DELETE_BRANCH_ASSOCIATE_FAILED",

  fetchBranchAssociates: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCH_ASSOCIATES,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchBranchAssociate: (
    id: number | "" | undefined,
    resolve: (value: BranchAssociate) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCH_ASSOCIATE,
    id,
    resolve,
    reject,
  }),

  saveBranchAssociate: (
    data: BranchAssociate,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_BRANCH_ASSOCIATE,
    data,
    event,
    resolve,
    reject,
  }),

  deleteBranchAssociate: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_BRANCH_ASSOCIATE,
    id,
    resolve,
    reject,
  }),
};

export default actions;
