import ChangeRequestSaga from "./sagas";
import ChangeRequestReducer from "./reducers";
import ChangeRequestAction from "./actions";

export const changeRequestReducer = {
  changeRequestReducer: ChangeRequestReducer,
};

export const changeRequestSaga = [ChangeRequestSaga()];

export { ChangeRequestAction };
