import StudentsSaga from "./sagas";
import StudentsReducer from "./reducers";
import studentsActions from "./actions";

export const studentsReducer = {
  studentsReducer: StudentsReducer,
};

export const studentsSaga = [StudentsSaga()];

export { studentsActions };
