import { Table, TableProps } from "antd";
import { tokens } from "utils/Constants";

interface ATableProps extends TableProps<any> {
  fetchDataAction?: string;
  searchValue?: any;
  filters?: string[] | null;
}

function ATable({ fetchDataAction, searchValue, filters, ...others }: ATableProps) {

  return (
    <div
      style={{
        backgroundColor: tokens.BG_LIGHT,
        borderRadius: "6px",
        margin: "10px 0",
        padding: "16px",
      }}
    >
      <Table
        style={{ cursor: "pointer" }}
        scroll={{ x: "max-content" }}
        {...others}
      />
    </div>
  );
}

export default ATable;
