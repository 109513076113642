import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
import CustomerSupportAdminLayout from "layout/customerSupportAdmin";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        customerSupportAdmins: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_CUSTOMER_SUPPORT_ADMINS_SUCCESS:
            return state.setIn(["customerSupportAdmins"], action.payload);

        case actions.DELETE_CUSTOMER_SUPPORT_ADMIN_SUCCESS:
            const deleteIndex = state.toJS().customerSupportAdmins.data?.findIndex(
                (CustomerSupportAdmin: any) => CustomerSupportAdmin.id === action.id
            )
            return state.deleteIn(["customerSupportAdmins", "data", deleteIndex]);

        default:
            return state;    
    }
}

export default reducer;