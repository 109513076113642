import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface CouponReturnType {
  [x: string]: string;
}

const createCoupon = takeLatest(
  actions.CREATE_COUPON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/payment/coupon`,
        action.data,
        null
      );
      yield put({
        type: actions.CREATE_COUPON_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      console.log(error);
      yield put({ type: actions.CREATE_COUPON_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchCoupons = takeLatest(
  actions.FETCH_COUPONS,
  function* (action: AnyAction): Generator<Effect, void, CouponReturnType> {
    try {
      const url = action.page
        ? `api/payment/coupon?page=${action.page}&pageSize=${action.pageSize}`
        : `api/payment/coupon`;
      const response = yield call(api.get, url, false);
      yield put({
        type: actions.FETCH_COUPONS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_COUPONS_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchCoupon = takeLatest(
  actions.FETCH_COUPON,
  function* (action: AnyAction): Generator<Effect, void, CouponReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/payment/coupon/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_COUPON_SUCCESS,
        payload: response.data,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_COUPON_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateCoupon = takeLatest(
  actions.UPDATE_COUPON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/payment/coupon/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_COUPON_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      console.log(error);
      yield put({ type: actions.UPDATE_COUPON_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const deleteCoupon = takeLatest(
  actions.DELETE_COUPON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.delete,
        `api/payment/coupon/${action.id}`
      );
      yield put({
        type: actions.DELETE_COUPON_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.DELETE_COUPON_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([
    createCoupon,
    fetchCoupons,
    updateCoupon,
    deleteCoupon,
    fetchCoupon,
  ]);
}
