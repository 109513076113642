const actions = {
  INCREASE_CHAT_BADGE: "INCREASE_BADGE",
  DECREASE_CHAT_BADGE: "DECREASE_BADGE",
  INCREASE_NOTIFICATION_BADGE: "INCREASE_NOTIFICATION_BADGE",
  DECREASE_NOTIFICATION_BADGE: "DECREASE_NOTIFICATION_BADGE",
  MAKE_CHAT_BADGE_NULL: "MAKE_CHAT_BADGE_NULL",
  MAKE_NOTIFICATION_BADGE_NULL: "MAKE_NOTIFICATION_BADGE_NULL",
  increaseChatBadge: () => ({
    type: actions.INCREASE_CHAT_BADGE,
  }),
  decreasChateBadge: () => ({
    type: actions.DECREASE_CHAT_BADGE,
  }),
  increaseNotificationBadge: () => ({
    type: actions.INCREASE_NOTIFICATION_BADGE,
  }),
  decreaseNotificatioBadge: () => ({
    type: actions.DECREASE_NOTIFICATION_BADGE,
  }),
  makeChatBadgeNull: () => ({
    type: actions.MAKE_CHAT_BADGE_NULL,
  }),
  makeNotificationBadgeNull: () => ({
    type: actions.MAKE_NOTIFICATION_BADGE_NULL
  })
};

export default actions;
