import { Row, Col, Space } from "antd";
import React, { useEffect, useState } from "react";
import { tokens } from "utils/Constants";
import { getUserDeviceResolution, isOnMobile } from "utils/Helpers";
import { HeartOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
function Footer() {
  const [onMobile, setOnMobile] = useState<boolean>(isOnMobile());

  const checkOnMobile = () => {
    setOnMobile(getUserDeviceResolution().width < 650);
  };

  useEffect(() => {
    window.addEventListener("resize", checkOnMobile);
  }, []);

  return (
    // <Row
    //   style={{
    //     position: "fixed",
    //     bottom: 0,
    //     zIndex: 10,
    //     width: "100%",
    //     padding: "0.3rem",
    //     fontSize: "0.3rem",
    //     color: tokens.COLOR_USERNAME,
    //     background: tokens.BG_LIGHT,
    //     textAlign: onMobile ? "center" : "left",
    //   }}
    // >
    //   <Col xl={8} lg={7} md={8} sm={24} xs={24}>
    //     {/* <p> */}
    //     COPYRIGHT © {new Date().getFullYear() + " "}
    //     <NavLink to={"/"}>Kidncode</NavLink>
    //     {/* </p> */}
    //   </Col>
    //   <Col xl={6} lg={6} md={8} sm={0} xs={0}></Col>
    //   <Col xl={10} lg={7} md={8} sm={24} xs={24}>
    //     {/* <p> */}
    //     Developed By Wolfmatrix with{" "}
    //     <HeartOutlined style={{ color: tokens.COLOR_DANGER }} />
    //     {/* </p> */}
    //   </Col>
    // </Row>
    <Space
      align="center"
      wrap={true}
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 10,
        display: "flex",
        justifyContent: onMobile ? "center" : "space-between",
        width: "100%",
        padding: "0.4rem 0.7rem",
        fontSize: "1rem",
        color: tokens.COLOR_USERNAME,
        background: tokens.BG_LIGHT,
        textAlign: onMobile ? "center" : "left",
      }}
    >
      <div>
        COPYRIGHT © {new Date().getFullYear() + " "}
        <NavLink to={"/"}>Kidncode</NavLink>
      </div>
      <div>
        Developed By Wolfmatrix with{" "}
        <HeartOutlined style={{ color: tokens.COLOR_DANGER }} />
      </div>
    </Space>
  );
}

export default Footer;
