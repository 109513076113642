import { PasswordInterface } from "../interface/Password.interface";

const actions = {
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",

  updatePassword: (
    data: PasswordInterface,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_PASSWORD,
    data,
    resolve,
    reject,
  }),
};

export default actions;
