import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const communicationIcon = () =>( <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.875 6.09253C16.6117 6.32957 17.125 7.03267 17.125 7.84018V11.4115C17.125 12.3587 16.4188 13.1619 15.4748 13.2389C15.1923 13.262 14.9091 13.2821 14.625 13.2994V15.875L12.125 13.375C10.9969 13.375 9.87966 13.329 8.77519 13.2388C8.5266 13.2186 8.2945 13.1479 8.08745 13.0375M15.875 6.09253C15.7488 6.05193 15.6161 6.025 15.4783 6.01356C14.3727 5.9218 13.2543 5.875 12.125 5.875C10.9957 5.875 9.87733 5.9218 8.77174 6.01356C7.82916 6.09179 7.125 6.89436 7.125 7.84018V11.4114C7.125 12.1092 7.50822 12.7288 8.08745 13.0375M15.875 6.09253V4.53109C15.875 3.17991 14.9152 2.00887 13.5753 1.83492C11.8732 1.61396 10.1375 1.5 8.37524 1.5C6.61278 1.5 4.87694 1.61399 3.1747 1.83499C1.83477 2.00895 0.875 3.17998 0.875 4.53115V9.71886C0.875 11.07 1.83478 12.2411 3.1747 12.415C3.65551 12.4774 4.13899 12.5313 4.625 12.5765V16.5L8.08745 13.0375" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>);

export const CommunicationIcon = (props: Partial<CustomIconComponentProps>) =>{
  return (
   <Icon component={communicationIcon} {...props}/>
  )
}

