import { EnrollmentData } from "../interface";

const changeRequestactions = {
  FETCH_CHANGEREQUEST: "FETCH_CHANGEREQUEST",
  FETCH_CHANGEREQUEST_SUCCESS: "FETCH_CHANGEREQUEST_SUCCESS",
  FETCH_CHANGEREQUESTS: "FETCH_CHANGEREQUESTS",
  FETCH_CHANGEREQUESTS_SUCCESS: "FETCH_CHANGEREQUESTS_SUCCESS",
  SAVE_CHANGEREQUEST: "SAVE_CHANGEREQUEST",
  SAVE_CHANGEREQUEST_SUCCESS: "SAVE_CHANGEREQUEST_SUCCESS",
  UPDATE_CHANGEREQUEST: "UPDATE_CHANGEREQUEST",
  UPDATE_CHANGEREQUEST_SUCCESS: "UPDATE_CHANGEREQUEST_SUCCESS",
  FETCH_ENROLLMENTS: "FETCH_ENROLLMENTS",
  FETCH_ENROLLMENTS_SUCCESS: "FETCH_ENROLLMENTS_SUCCESS",
  fetchChangeRequests: (
    resolve: (value: EnrollmentData) => void,
    reject: (value: string) => void
  ) => ({
    type: changeRequestactions.FETCH_CHANGEREQUESTS,
    resolve,
    reject,
  }),

  fetchChangeRequest: (
    id: any,
    resolve: (value: EnrollmentData) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: changeRequestactions.FETCH_CHANGEREQUEST,
    resolve,
    reject,
  }),

  saveChangeRequest: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: changeRequestactions.SAVE_CHANGEREQUEST,
    data,
    resolve,
    reject,
  }),

  updateChangeRequest: (
    id: number,
    data: EnrollmentData,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: changeRequestactions.UPDATE_CHANGEREQUEST,
    id,
    data,
    resolve,
    reject,
  }),
  fetchEnrollments: (
    // page: number,
    // pageSize: number,
    resolve: (value: EnrollmentData) => void,
    reject: (value: string) => void
  ) => ({
    type: changeRequestactions.FETCH_ENROLLMENTS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),
};

export default changeRequestactions;