import {
  Select,
  Form,
  Space,
  Col,
  Row,
  RadioChangeEvent,
  theme,
  message,
  Checkbox,
  Modal,
  Alert,
  DatePicker,
} from "antd";
import { DefaultOptionType, SelectProps } from "antd/es/select";
import { ActiveIcon, PendingIcon } from "assets";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AButton, AInput, AModal, AText, ATitle } from "components";
import Loader from "components/Loader";
import ASelect from "components/Select";
import TermsAndCondition from "components/TermsAndCondition";
import { Course } from "pages/course/interface/Course.interface";
import { coursesActions } from "pages/course/store";
import { mailingAddressActions } from "pages/enrollment/store";
import CheckoutForm from "pages/enrollment/components/CheckoutForm";
import { TimeSlotFormatType } from "pages/timeSlot/timeSlot.interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "services/api";
import { IRootState } from "store/interface/Store.interface";
import { days, newPairDays, pairDays, partTimeDays, states, tokens } from "utils/Constants";
import { formatTimeSlot, formatTimeSlots, getUserInfo, isCustomerSupportUser } from "utils/Helpers";
import { JWTToken } from "utils/interface/Utils.interface";
import axios, { AxiosError } from "axios";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import actions from "pages/batch/store/actions";
import enrollmentAction from 'pages/website/store/actions'
import { Pending } from "assets/icons/Pending";
import moment from "moment";
interface FormFieldsProps {
  userInfo?: JWTToken | null;
  onCancel?: () => void;
  information?: any;
  branch?: any;
  userDetails?: any;
  id?: any;
  newEnrollment?: boolean;
}

export const validateContact = (_: any, value: string) => {
  const usaContactNumberRegex =
    /^(\+?1\s?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[2-9][0-9]{2}[-.\s]?[0-9]{4}$/;
  const nepalContactNumberRegex = /^(\+?977)?\s?\d{3}[-.\s]?\d{3}[-.\s]?\d{4}$/;
  if (
    !usaContactNumberRegex.test(value) &&
    !nepalContactNumberRegex.test(value)
  ) {
    return Promise.reject(new Error("Please input a valid contact number!"));
  }
  return Promise.resolve();
};

const FormFields: React.FC<FormFieldsProps> = ({
  userInfo,
  onCancel,
  information,
  branch,
  userDetails,
  id,
  newEnrollment,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const api = new Api();
  const { Option } = Select;

  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [evaluationTestType, setEvaluationTestType] = useState("online");
  const [formError, setFormError] = useState<any>({});
  const [value, setValue] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState<any>("");
  const [formValues, setFormValues] = useState<any>({});
  const [isChecked, setIsChecked] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [daysPerWeek, setDaysPerWeek] = useState<number | null>();
  const [formattedTimeSlots, setFormattedTimeSlots] = useState<
    TimeSlotFormatType[]
  >(formatTimeSlots(information?.timeslot));
  const [options, setOptions] = useState<SelectProps["options"]>(days);
  const [partTimeOptions, setPartTimeOptions] = useState<SelectProps["options"]>(partTimeDays); 
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stepForm, setStepForm] = useState<number>(1);
  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [userId, setUserId] = useState<any>();
  const [evaluationTestId, setEvaluationTestId] = useState<number>();
  const [countStudent, setCountStudent] = useState<number>(0);
  const [childId, setChildId] = useState<any>();
  const [branchId, setBranchId] = useState<any>();
  const [batch, setBatch] = useState<number>();
  const [isCheckedAuthorizedPerson, setIsCheckedAuthorizedPerson] =
    useState(false);
  const [isCheckedSecondAuthorizedPerson, setIsCheckedSecondAuthorizedPerson] =
    useState(false);
  const [sendVerificationCodeSuccess, setSendVerificationCodeSuccess] =
    useState<boolean>(false);
  const [admissionFee, setAdmissionFee] = useState<any>(0);
  const [plan, setPlan] = useState<any>();
  const [monthlyFee, setMonthlyFee] = useState<any>();
  const [timeSlotChanged, setTimeSlotChanged] = useState<boolean>(false);
  const [filteredCourses, setFilteredCourses] = useState<any>();

  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let parentId: any = searchParams.get("parent_id");
  let flag: any = searchParams.get("f");
  let name: any = searchParams.get("name");
  let count: any = searchParams.get("count");
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);
  const [isWaitListModelVisible, setIsWaitListModelVisible] = useState<boolean>(false);  
  const [existingUser, setExistingUser] = useState<any>();
  const [reactivateUser, setReactivationUser] = useState<boolean>(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [isUserWithoutSubscription, setIsUserWithoutSubscription] = useState<boolean>(false);
  const [existingUserWithoutSubscription, setExistingUserWithoutSubscription] = useState<boolean>(false);
  const [batchNotFound, setBatchNotFound] = useState<boolean>(false);
  const [reactivationModel, setReactivationModel] = useState<boolean>(false);
  const [reactivationPendingModel, setReactivationPendingModel] = useState<boolean>(false);
  const [enrollmentId, setEnrollmentId] = useState<any>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parent_id: any = userInfo ? userInfo.id : null;

  useEffect(() => {
    if(flag == 1) {
      if(count < 25){
        setStepForm(2);
      }else {
        setReactivationPendingModel(true)
      }
    }
  }, [flag, count])

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleAuthorizedPersonChange = (e: any) => {
    setIsCheckedAuthorizedPerson(e.target.checked);
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(coursesActions.fetchCourses(resolve, reject));
    });
  }, [dispatch]);

  const courses = useSelector((state: IRootState) =>
    state.coursesReducer.get("courses")
  );

  const preprocessPhoneNumber = (value: string) => {
    //Check if ht number starts with '+1', if not, add it
    // if (!value.startsWith('+1')) {
    //   return `+1${value}`
    // }
    return value;
  };

  const userToken: any = localStorage.getItem("token")
  
  let customerSupportUser: boolean;
  if(userToken){
    customerSupportUser = isCustomerSupportUser()
  } 

  const FormSubmit = async (values: any) => {
    values.batch = batch;
    if (countStudent < 25) {
      values.seat_available_status = "enrolled";
    } else {
      values.seat_available_status = "waiting";
    }
    if (userInfo) {
      values.userInfo_id = userInfo.id;
    }
    new Promise((resolve, reject) => {
      setIsLoading(true);
      api
        .post(
          process.env.REACT_APP_API_BASE_URL + "/api/enrollmentWithoutId",
          values,
          null,
          !!!userInfo && true
        )
        .then((data: any) => {
          setIsLoading(false);
          setUserId(userInfo ? data?.parent_id : data?.dataValues?.user_id ? data?.dataValues?.user_id : data?.UserDetail.user_id );
          setEvaluationTestId(data.id);
          // form.resetFields();
          // !!onCancel && onCancel();

          if (userInfo) {
            setStepForm(stepForm + 1);
            setFormSubmittedSuccessfully(true);
          }
          // create enrollement
          if (userInfo) {
            formValues.flag = 1;
          } else {
            formValues.flag = 2;
          }

          formValues.user_id = userInfo
            ? data?.parent_id
            : data?.dataValues?.user_id ? data?.dataValues?.user_id : data?.UserDetail.user_id;
          formValues.available_days =
            formValues.available_days &&
            formValues.available_days
              .map((item: any) => item.split(","))
              .flat();
            values.available_days =
            values.available_days &&
            values.available_days
                .map((item: any) => item.split(","))
                .flat();  
          const formValue = {
            ...formValues,
            evaluation_form_id: data.id,
            ...values
          };
          api
            .post(
              process.env.REACT_APP_API_BASE_URL +
                "/api/enrollmentWithoutId/create-enrollment",
              formValue,
              null,
              false
            )
            .then((data: any) => {
              if(reactivateUser){
                sendMailForFurtherProcessing(reactivateUser, data?.child?.id, countStudent)
              }
              setEnrollmentId(data?.result?.id)
              setChildId(data?.child?.id);
              setBranchId(data?.result?.branch_location);
              const mailingAddressValues = {
                user_id: formValues.user_id,
                address1: formValues.address1 || values?.address1,
                address2: formValues.address2 || values?.address2,
                city: formValues.city || values?.city,
                state: formValues.state || values?.state,
                zip_code: formValues.zip_code || values?.zip_code,
              };
              if(formValues.flag != 1) {
                new Promise((resolve, reject) => {
                  dispatch(
                    mailingAddressActions.creatingMailingAddress(
                      mailingAddressValues,
                      resolve,
                      reject
                    )
                  );
                }).then(() => {
                  if(countStudent >= 25){
                    setIsWaitListModelVisible(true)
                  }
                })
              }
              });
            })
            .catch((err: any) => {
              setIsLoading(false);
              const validationErrors = JSON.parse(err.request.response);
              setFormError(validationErrors.errors);
              message.error("Form submission failed");
            });
          });
        };

  useEffect(() => {
    if(userDetails && userDetails.length != 0) {
      setPlans();
    }
  }, [courses, userDetails])

  const setPlans = () => {
    let courseId: any;

    if (!parentId) {
      courseId = form.getFieldValue("course_id");
    }else if(userDetails.length != 0) {
      courseId = userDetails?.enrollment?.course_id
    }
    const course = courseId && courses.filter((course: Course) => 
      course.id === courseId
    );
    if (course && course.length > 0) {
      setSelectedPlans([
        process.env.REACT_APP_ADMISSION_FEE as string,
        process.env.REACT_APP_LOAN_LAPTOP_PLAN_ID as string,
        course[0].plan_id,
      ]);
    }
  };
    
  const fetchMonthlyFee = async () => {
    await api
      .get(process.env.REACT_APP_API_BASE_URL + "/api/payment/plans")
      .then((data: any[]) => {
        const setPlans = data.filter((plan: any) =>
          selectedPlans.includes(plan.id)
        );
        setMonthlyFee(setPlans.length > 0 ? setPlans[0].amount / 100 : 0);
        setPlan(setPlans);
      })
      .catch((error: any) => {
        message.error("Error fetching pricing plan from stripe");
      });
  };

  useEffect(() => {
    if (selectedPlans.length > 0) {
      fetchMonthlyFee();
    }
  }, [selectedPlans]);

  const subscribe = async (
    paymentMethod: any,
    coupons: any,
    billingAddress: any,
    branch_id?: any,
    course_id?: any,
    defaultPaymentCard?: any,
    referredBy?: any
  ) => {
    new Promise((resolve, reject) => {
      setIsLoading(true);
      if (countStudent < 25 || parentId) {
        setStepForm(1);
        api
          .post(
            process.env.REACT_APP_API_BASE_URL +
              "/api/payment/subscribeWithoutUserId",
            {
              user_id: userId || userDetails?.userData?.id,
              course_id: formValues.course_id || course_id,
              laptop_option: selectedPlans,
              payment_method: paymentMethod,
              coupons: coupons,
              child_id: childId || userDetails?.child.id,
              branch_id: branchId || userDetails?.enrollment?.branch_location || branch_id,
              flag: flag ? flag : "",
              default_payment_card: defaultPaymentCard,
              enrollment_id: enrollmentId,
              batch: batch,
              class_start_date: formValues.class_start_date || userDetails?.evaluationTest?.class_start_date,
              referred_by: referredBy
            },
            null,
            false
          )
          .then((data: any) => {
            setIsLoading(false);
            resolve(data);
            setUpdateSuccess(true);
            setStepForm(1);
          })
          .catch((error: any) => {
            setStepForm(1);
            message.error(error?.response?.data?.errors ? error?.response?.data?.errors : "Enrollment Failed! Please try again later.");
            setIsLoading(false);
          });
      } else {
        Promise.resolve()
          .then((data: any) => {
            setIsLoading(false);
            resolve(data);
            setUpdateSuccess(true);
            setStepForm(1);
          })
          .catch((error: any) => {
            setStepForm(1);
            message.error(error?.response?.data?.errors ? error?.response?.data?.errors : "Enrollment Failed! Please try again later.");
            setIsLoading(false);
          });
      }
    }).catch((error: any) => {
      setIsLoading(false);
      setStepForm(1);
      message.error(
        error?.response?.data?.errors ? error?.response?.data?.errors :
        "Enrollment Failed, Please contact support team for further assistance."
      );
    });
  };
  
  const proceedToPayment = () => {
    const authorizedPerson1 = [];
    const authorizedPerson2 = [];

    const firstauthorisedPersonFirstName = form.getFieldValue("firstauthorisedPersonFirstName");
    const firstauthorisedPersonContact = form.getFieldValue('firstauthorisedPersonContact')

    const secondauthorisedPersonName = form.getFieldValue("secondauthorisedPersonFirstName");
    const secondauthorisedPersonContact = form.getFieldValue('secondauthorisedPersonContact')

    if (
      firstauthorisedPersonFirstName &&
      firstauthorisedPersonContact
    ) {
      authorizedPerson1.push({
        first_name: form.getFieldValue("firstauthorisedPersonFirstName"),
        middle_name: form.getFieldValue("firstauthorisedPersonMiddleName")
          ? form.getFieldValue("firstauthorisedPersonMiddleName")
          : "",
        last_name: form.getFieldValue("firstauthorisedPersonLastName"),
        contact:
          form.getFieldValue("firstauthorisedPersonContact"),
      });
    }
    // Check if second authorized person data exists
    if (secondauthorisedPersonName && secondauthorisedPersonContact) {
      authorizedPerson2.push({
        first_name: form.getFieldValue("secondauthorisedPersonFirstName"),
        middle_name: form.getFieldValue("secondauthorisedPersonMiddleName")
          ? form.getFieldValue("secondauthorisedPersonMiddleName")
          : "",
        last_name: form.getFieldValue("secondauthorisedPersonLastName"),
        contact:
          form.getFieldValue("secondauthorisedPersonContact"),
      });
    }
    const enrollmentDetails = {
      parent_id: userDetails?.userData.id,
      child_id: userDetails?.child.id,
      first_name: form.getFieldValue("first_name"),
      middle_name: form.getFieldValue("middle_name"), 
      last_name: form.getFieldValue("last_name"), 
      email: form.getFieldValue("email"),
      contact: form.getFieldValue("contact"), 
      address_id: userDetails?.address?.id,
      address1: form.getFieldValue('address1'),
      address2: form.getFieldValue('address2'),
      city: form.getFieldValue('city'),
      state: form.getFieldValue('state'),
      zip_code: form.getFieldValue('zip_code'),
      pickupPerson1_id: userDetails?.pickupPerson[0] ? userDetails?.pickupPerson[0]?.id : 0,
      pickupPerson2_id: userDetails?.pickupPerson[1] ? userDetails?.pickupPerson[1]?.id : 0, 
      authorizedPerson1: authorizedPerson1,
      authorizedPerson2: authorizedPerson2,
  };
    new Promise((resolve, reject) => {
      dispatch(enrollmentAction.updateEnrollment(enrollmentDetails, resolve, reject))
    })
  }

  const handleStudentCount = () => {
    const course_id = form.getFieldValue("course_id");
    const branch_location = form.getFieldValue("branch_location");
    const timeslot_id = form.getFieldValue("time_slot");
    const available_days = form.getFieldValue("available_days");
    const days_per_week = form.getFieldValue("days_per_week");
    const daysParam =  available_days && available_days.join(",");

    setPlans();

    new Promise((resolve, reject) => {
      dispatch(
        actions.fetchTotalStudentOfBatch(
          course_id,
          branch_location,
          timeslot_id,
          days_per_week,
          daysParam,
          resolve,
          reject
        )
      );
    })
      .then((data: any) => {
        setCountStudent(data.batch.total_students);
        setBatch(data?.batch.id);
        setBatchNotFound(false)
        // setCountStudent(25);
      })
      .catch((error) => {
        setBatchNotFound(true)
        console.error("Failed to fetch student count:", error);
      });
  };

  function handleChange(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    handleStudentCount();
    filterTimeSlot();
    if (optionsSelected.length < 1) {
      const values = value[0]?.split(",");
      let filteredTimeSlots = information?.timeslot;
      if ((values[0] == "sun" || values[0] == "sat") && values.length === 1) {
        const startHourCondition = values[0] == "sun" ? 4 : 7;
        const endHourCondition = values[0] == "sun" ? 6 : 9;

        filteredTimeSlots = information?.timeslot.filter((slot: any) => {
          const startHour = parseInt(slot.start_time.split(":")[0]);
          const endHour = parseInt(slot.end_time.split(":")[0]);
          return (
            !(
              startHour >= startHourCondition && startHour < endHourCondition
            ) && !(endHour > startHourCondition && endHour <= endHourCondition)
          );
        });
      }
      setFormattedTimeSlots(formatTimeSlots(filteredTimeSlots));
      setOptionsSelected(values);
    }
  }

  const verifyCode = () => {
    const contact = formValues?.contact;
    new Promise((resolve, reject) => {
      setIsLoading(true);
      api
        .post(
          process.env.REACT_APP_API_BASE_URL + "/api/otp/code",
          { verificationCode, contact },
          null
        )
        .then(() => {
          setIsLoading(false);
          setIsModalOpen(false);
          setSendVerificationCodeSuccess(false);
          setIsCodeVerified(true);
          setVerificationCode(null);
          if(reactivateUser){
            setReactivationModel(true);
            FormSubmit(formValues);
            // sendMailForFurtherProcessing(reactivateUser, childId, countStudent)
          }
          if(!reactivateUser){
            FormSubmit(formValues);
            setStepForm(stepForm + 1);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          setVerificationCode(null);
          message.error(err.response.data.message);
        });
    });
  };

  const sendMailForFurtherProcessing = (reactivateUser: any, child_id: any, student_count: any) => {
    new Promise((resolve, reject) => {
      dispatch(mailingAddressActions.sendMailForFurtherProcessing({...reactivateUser, child_id, student_count}, resolve, reject))
    })
  }

  const onFinish = (data: any) => {
    const authorizedPersons = [];
    if (data.firstauthorisedPersonFirstName && data.firstauthorisedPersonContact) {
      authorizedPersons.push({
        first_name: data.firstauthorisedPersonFirstName,
        middle_name: data.firstauthorisedPersonMiddleName || '',
        last_name: data.firstauthorisedPersonLastName,
        contact: data.firstauthorisedPersonContact,
      });
    }
    if (data.secondauthorisedPersonName && data.secondauthorisedPersonContact) {
      authorizedPersons.push({
        first_name: data.secondauthorisedPersonFirstName,
        middle_name: data.secondauthorisedPersonMiddleName || '',
        last_name: data.secondauthorisedPersonLastName,
        contact: data.secondauthorisedPersonContact,
      });
    }
    const values = authorizedPersons.length > 0 ? { authorizedPersons, ...data } : data;

    values.evaluation_test_type = evaluationTestType;
    values.terms_and_condition = isChecked;
    values.contact = form.getFieldValue('contact');
    values.contact = preprocessPhoneNumber(values.contact);
    if (!values.free_trial) {
      values.free_trial = 'false';
    }
    setFormValues(values);

    const { contact, email } = values;

    if (userInfo) {
        FormSubmit(values);
    } else if (parentId) {
      setStepForm(stepForm + 1);
    } else {
      if (!isReactivating) {
        email && new Promise((resolve, reject) => {
          dispatch(
            mailingAddressActions.fetchExisitngUser(
              email,
              resolve,
              reject
            )
          );
        }).then((data: any) => {
          setExistingUser(data);
          setReactivationUser(data);
          if (!data) {
            sendOTP(contact, email);
          }
        });
      } else {
        sendOTP(contact, email);
      }
    }
  };

  const sendOTP = (contact: any, email: any) => {
    setIsLoading(true);
    api
      .post(process.env.REACT_APP_API_BASE_URL + '/api/otp/send', { contact, email }, null)
      .then(() => {
        setIsLoading(false);
        setSendVerificationCodeSuccess(true);
      })
      .catch((error: any) => {
        setIsLoading(false);
        if (error.response.status === 404) {
          setFormError({ email: error.response.data });
        } else {
          message.error(error.response.data);
        }
      });
  };

  const handleReactivate = () => {
    setExistingUser(false)
    const data = form.getFieldsValue();
    sendOTP(data.contact, data.email);
  };

  const fetchAdmissionFee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/payment/admission-fee`
      );
      const fee = response.data.unit_amount / 100;
      setAdmissionFee(fee);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching admission fee!!!", error);
      message.error("Error fetching admission fee!!!");
    }
  };

  useEffect(() => {
    fetchAdmissionFee();
  }, []);

  //filter timeslot on the basis of selected days_per_week and available_days
  //class_type = 1 --> Full time class (MON-THU)
  //class_type = 2 --> Weekend classes (SAT-SUN)
  //class_type = 3 --> Part Time classes
  const [newFilteredTimeSlots, setNewFilteredTimeSlots] = useState<any>();
  const filterTimeSlot = () => {
    const days_per_week = form.getFieldValue('days_per_week');
    const available_days = form.getFieldValue('available_days');
  
    if (days_per_week == 2 && ((available_days && available_days[0])  === "mon,wed" || (available_days && available_days[0])  === "tue,thu" )) {
      const filteredTimeSlots = information.timeslot.filter((timeslot: any) => {
        return timeslot?.class_type == 1 && (timeslot?.class_number == 1 || timeslot?.class_number == 2 || timeslot?.class_number == 3);
      });
      setNewFilteredTimeSlots(formatTimeSlots(filteredTimeSlots));
    } else if(days_per_week == 2 && (available_days && available_days[0]) === "sat,sun") {
      const filteredTimeSlots = information.timeslot.filter((timeslot: TimeSlotFormatType) => {
        return timeslot?.class_type == 2;
      });
      setNewFilteredTimeSlots(formatTimeSlots(filteredTimeSlots));
    } else if(days_per_week == 1 && (available_days && available_days[0]) === "fri") {
      const filteredTimeSlots = information.timeslot.filter((timeslot: any) => {
        return timeslot?.class_type == 3 && (timeslot?.class_number == 1 || timeslot?.class_number == 2);
      });
      setNewFilteredTimeSlots(formatTimeSlots(filteredTimeSlots));
    }else if(days_per_week == 1 && (available_days && available_days[0]) === "sat") {
      const filteredTimeSlots = information.timeslot.filter((timeslot: any) => {
        return timeslot?.class_type == 3 && (timeslot?.class_number == 3);
      });
      setNewFilteredTimeSlots(formatTimeSlots(filteredTimeSlots));
    } else if (days_per_week == 1 && ((available_days && available_days[0]) === "mon" || (available_days && available_days[0]) === "tue" || (available_days && available_days[0]) === "wed") || (available_days && available_days[0]) === "thu") {
      const filteredTimeSlots = information.timeslot.filter((timeslot: any) => {
        return timeslot?.class_type == 1 && (timeslot?.class_number == 1 || timeslot?.class_number == 2 || timeslot?.class_number == 3);
      });
      setNewFilteredTimeSlots(formatTimeSlots(filteredTimeSlots));
    } else if(days_per_week == 1 && (available_days && available_days[0]) === "sun") {
      const filteredTimeSlots = information.timeslot.filter((timeslot: TimeSlotFormatType) => {
        return timeslot?.class_type == 2;
      });
      setNewFilteredTimeSlots(formatTimeSlots(filteredTimeSlots));
    }
  }

  const handleTimeSlotChange = () => {
    handleStudentCount();
    setTimeSlotChanged(true);
  };

  const handleExistingUser = (data: any) => {
    setExistingUser(true);
    const authorizedPersons = [];

    if (
      data.firstauthorisedPersonFirstName &&
      data.firstauthorisedPersonContact
    ) {
      authorizedPersons.push({
        first_name: data.firstauthorisedPersonFirstName,
        middle_name: data.firstauthorisedPersonMiddleName
          ? data.firstauthorisedPersonMiddleName
          : "",
        last_name: data.firstauthorisedPersonLastName,
        contact:
          data.firstauthorisedPersonContact,
      });
    }
    // Check if second authorized person data exists
    if (data.secondauthorisedPersonName && data.secondauthorisedPersonContact) {
      authorizedPersons.push({
        first_name: data.secondauthorisedPersonFirstName,
        middle_name: data.secondauthorisedPersonMiddleName
          ? data.secondauthorisedPersonMiddleName
          : "",
        last_name: data.secondauthorisedPersonLastName,
        contact:
          data.secondauthorisedPersonContact,
      });
    }
    const values =
      authorizedPersons.length > 0 ? { authorizedPersons, ...data } : data;

    values.evaluation_test_type = evaluationTestType;
    values.terms_and_condition = isChecked;
    values.contact = form.getFieldValue("contact");

    values.contact = preprocessPhoneNumber(values.contact);
    if (!values.free_trial) {
      values.free_trial = "false";
    }
    setFormValues(values);
    const { contact, email } = values;
    if (!parentId) {
      new Promise((resolve, reject) => {
        setIsLoading(true);
        api
          .post(
            process.env.REACT_APP_API_BASE_URL + "/api/otp/send",
            { contact, email },
            null
          )
          .then(() => {
            setIsLoading(false);
            setSendVerificationCodeSuccess(true);
          })
          .catch((error: any) => {
            setIsLoading(false);
            if (error.response.status === 404) {
              setFormError({ email: error.response.data });
            } else {
              message.error(error.response.data);
            }
          });
      });
    } 
  }

  // const disabledDate = (current: any) => {
  //   const disableBeforeDate = new Date('2024-10-21');
  //   return current && current < disableBeforeDate;
  // };
  const dayMap: any = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 0,
  };
  const disabledDate = (current: any) => {
    const today = new Date();
    const disableBeforeDate = new Date(Date.UTC(2024, 9, 22));

    if (current && current.isBefore(today, 'day')) {
      return true;
    }
  
    if (current && current.isBefore(disableBeforeDate, 'day')) {
      return true;
    }
  
    if (!optionsSelected.length) {
      return false; 
    }

    const selectedWeekdays = optionsSelected
      .flatMap((day) => day.split(","))
      .map((day) => dayMap[day]);

    return !selectedWeekdays.includes(current.day());
  };

  const initialValues = {
    grade: (userDetails && userDetails?.child?.grade) || "1st",
    evaluation_test_type: "online",
    first_name: userInfo?.UserDetail?.first_name || userDetails?.userData?.UserDetail?.first_name,
    middle_name: userInfo?.UserDetail?.middle_name || userDetails?.userData?.UserDetail?.middle_name,
    last_name: userInfo?.UserDetail?.last_name || userDetails?.userData?.UserDetail?.last_name,
    child_first_name: userDetails?.enrollment?.child_first_name,
    child_middle_name: userDetails?.enrollment?.child_middle_name,
    child_last_name: userDetails?.enrollment?.child_last_name,
    email: userInfo?.email || userDetails?.userData?.email,
    contact: userInfo?.UserDetail?.contact
      ? userInfo?.UserDetail?.contact
       || userDetails?.userData?.UserDetail?.contact : " ",
    free_trial: false,
    age: (userDetails && userDetails?.child?.age) || "6",
    branch_location: userInfo
      ? userInfo?.branchFilter[0]
      : branch?.id || userDetails?.enrollment?.branch_location,
    days_per_week: userDetails?.enrollment?.days_per_week,
    available_days: userDetails?.enrollment?.available_days[0],
    time_slot: userDetails && userDetails.timeslot ? formatTimeSlot(userDetails.timeslot) : "Select Time Slot",
    // course_id: userDetails?.enrollment?.course_id || information?.course[0]?.id,
    course_id: userDetails?.enrollment?.course_id || filteredCourses?.[0]?.id,
    address1: userInfo?.address?.address1 || userDetails?.address?.address1,
    address2: userInfo?.address?.address2 || userDetails?.address?.address2,
    city: userInfo?.address?.city || userDetails?.address?.city,
    state: userInfo?.address?.state || userDetails?.address?.state || "TX",
    zip_code: userInfo?.address?.zip_code || userDetails?.address?.zip_code,
    firstauthorisedPersonFirstName: userDetails?.pickupPerson?.[0]?.first_name ?? '',
    firstauthorisedPersonMiddleName: userDetails?.pickupPerson?.[0]?.middle_name ?? '',
    firstauthorisedPersonLastName: userDetails?.pickupPerson?.[0]?.last_name ?? '',
    firstauthorisedPersonContact: userDetails?.pickupPerson?.[0]?.contact ?? '',
    secondauthorisedPersonFirstName: userDetails?.pickupPerson?.[1]?.first_name ?? '',
    secondauthorisedPersonMiddleName: userDetails?.pickupPerson?.[1]?.middle_name ?? '',
    secondauthorisedPersonLastName: userDetails?.pickupPerson?.[1]?.last_name ?? '',
    secondauthorisedPersonContact: userDetails?.pickupPerson?.[1]?.contact ?? '',
    class_start_date: userDetails?.evaluationTest?.class_start_date
    ? moment(userDetails.evaluationTest.class_start_date)
    : null, 
  };

const filterCourse = (value: any) => {
  const filteredCourses = information && information.course.filter((course: any) => course.course_name.toLowerCase() === "coding plus level 1".toLowerCase() && course.course_type == value)
  setFilteredCourses(filteredCourses)
}

const formatPhoneNumber = (value: any) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 13)}`;
};

const handlePhoneChange = (e: any) => {
  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
  form.setFieldsValue({ contact: formattedPhoneNumber });
  form.setFieldsValue({ firstauthorisedPersonContact: formattedPhoneNumber });
  form.setFieldsValue({ secondauthorisedPersonContact: formattedPhoneNumber });
};

const handleFirstPersonPhoneChange = (e: any) => {
  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
  form.setFieldsValue({ firstauthorisedPersonContact: formattedPhoneNumber });
};

const handleSecondPersonPhoneChange = (e: any) => {
  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
  form.setFieldsValue({ secondauthorisedPersonContact: formattedPhoneNumber });
};

useEffect(() => {
  if (!!filteredCourses) {
    // Update initialValues with filtered course ID after filterCourse completes
    form.setFieldsValue({
      course_id: filteredCourses[0]?.id,
    });
  }
}, [filteredCourses, form]);
  return (
    <>
     {isLoading && (
      <div style={{ 
        position: "fixed", 
        top: 0, 
        left: 0, 
        width: "100%", 
        height: "100%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        backgroundColor: "rgba(255, 255, 255, 0.7)", 
        zIndex: 9999 
      }}>
        <Loader />
      </div>
    )}
      <ATitle
        content={newEnrollment ? "New Enrollment" : "Fill Your Details"}
        level={4}
        style={{ margin: "-0.5% 0% 1% 0%" }}
      />
      <Form
        size="large"
        form={form}
        name="child evaluation test form"
        onFinish={onFinish}
        layout="vertical"
        scrollToFirstError
        title="Child Details"
        initialValues={initialValues}
        // initialValues={{
        //   grade: (userDetails && userDetails?.child?.grade) || "1st",
        //   evaluation_test_type: "online",
        //   first_name:
        //     userInfo?.UserDetail?.first_name ||
        //     userDetails?.userData?.UserDetail?.first_name,
        //   middle_name:
        //     userInfo?.UserDetail?.middle_name ||
        //     userDetails?.userData?.UserDetail?.middle_name,
        //   last_name:
        //     userInfo?.UserDetail?.last_name ||
        //     userDetails?.userData?.UserDetail?.last_name,
        //   child_first_name: userDetails?.enrollment?.child_first_name,
        //   child_middle_name: userDetails?.enrollment?.child_middle_name,
        //   child_last_name: userDetails?.enrollment?.child_last_name,
        //   email: userInfo?.email || userDetails?.userData?.email,
        //   contact: userInfo?.UserDetail?.contact
        //     ? userInfo?.UserDetail?.contact.replace(/^(\+977|\+1)\s?/, "")
        //     : "" || userDetails?.userData?.UserDetail?.contact,
        //   free_trial: false,
        //   age: (userDetails && userDetails?.child?.age) || "6",
        //   branch_location: userInfo
        //     ? userInfo?.branchFilter[0]
        //     : branch?.id || userDetails?.enrollment?.branch_location,
        //   days_per_week: userDetails?.enrollment?.days_per_week, 
        //   available_days: userDetails?.enrollment?.available_days[0],
        //   time_slot: userDetails?.enrollment?.time_slot,
        //   course_id: userDetails?.enrollment?.course_id,
        //   address1:
        //     userInfo?.address?.address1 || userDetails?.address?.address1,
        //   address2:
        //     userInfo?.address?.address2 || userDetails?.address?.address2,
        //   city: userInfo?.address?.city || userDetails?.address?.city,
        //   state: userInfo?.address?.state || userDetails?.address?.state,
        //   zip_code:
        //     userInfo?.address?.zip_code || userDetails?.address?.zip_code,
        //     firstauthorisedPersonFirstName: userDetails?.pickupPerson?.[0]?.first_name ?? '',
        //     firstauthorisedPersonMiddleName: userDetails?.pickupPerson?.[0]?.middle_name ?? '',
        //     firstauthorisedPersonLastName: userDetails?.pickupPerson?.[0]?.last_name ?? '',
        //     firstauthorisedPersonContact: userDetails?.pickupPerson?.[0]?.contact ?? '',
        //     secondauthorisedPersonFirstName: userDetails?.pickupPerson?.[1]?.first_name ?? '',
        //     secondauthorisedPersonMiddleName: userDetails?.pickupPerson?.[1]?.middle_name ?? '',
        //     secondauthorisedPersonLastName: userDetails?.pickupPerson?.[1]?.last_name ?? '',
        //     secondauthorisedPersonContact: userDetails?.pickupPerson?.[1]?.contact ?? '',   
        // }}
      >
        <ATitle level={5} content="Parent Details" />
        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input First Name!",
                },
              ]}
            >
              <AInput
                placeholder={"Enter First Name"}
                size="large"
                disabled={!!userInfo}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="middle_name"
              label="Middle Name"
              help="Only the initial letter!"
            >
              <AInput
                placeholder={"Enter Middle Name"}
                size="large"
                maxLength={1}
                disabled={!!userInfo}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input Last Name!",
                },
              ]}
            >
              <AInput
                placeholder={"Enter Last Name"}
                size="large"
                disabled={!!userInfo}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="contact"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input the Phone Number!",
                },
                // {
                //   validator: validateContact,
                // },
              ]}
            >
              <AInput
                placeholder={"Enter Phone Number"}
                size="large"
                onChange={handlePhoneChange}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="email"
              label="Email"
              help={formError.email}
              validateStatus={formError.email ? "error" : ""}
              rules={[
                { required: true, message: "Please input your email!" },
                {
                  pattern: emailRegex,
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <AInput
                placeholder={"Enter Email"}
                size="large"
                disabled={!!userInfo}
              />
            </Form.Item>
          </Col>
        </Row>

        <ATitle level={5} content="Child Details" />
        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="child_first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input First Name!",
                },
              ]}
            >
              <AInput placeholder={"Enter First Name"} 
              disabled={userDetails?.enrollment?.child_first_name} 
              size="large" />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="child_middle_name"
              label="Middle Name"
              help="Only the initial letter!"
            >
              <AInput
                placeholder={"Enter Middle Name"}
                disabled={userDetails?.enrollment} 
                size="large"
                maxLength={1}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="child_last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input Last Name!",
                },
              ]}
            >
              <AInput placeholder={"Enter Last Name"}
              disabled={userDetails?.enrollment?.child_last_name} 
              size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="age"
              label="Child Age"
              rules={[
                {
                  required: true,
                  message: "Please input your child's age!",
                },
              ]}
            >
              <ASelect customized={false} 
              disabled={userDetails && userDetails?.child?.age} 
              defaultValue={6}>
                <Select.Option value="6">6</Select.Option>
                <Select.Option value="7">7</Select.Option>
                <Select.Option value="8">8</Select.Option>
                <Select.Option value="9">9</Select.Option>
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="11">11</Select.Option>
                <Select.Option value="12">12</Select.Option>
                <Select.Option value="13">13</Select.Option>
                <Select.Option value="14">14</Select.Option>
                <Select.Option value="15">15</Select.Option>
                <Select.Option value="16">16</Select.Option>
                {/* <Select.Option value="17">17</Select.Option>
                <Select.Option value="18">18</Select.Option> */}
              </ASelect>
            </Form.Item>
          </Col>

          <Col md={12} sm={24} lg={8} xs={24}>
            {" "}
            <Form.Item
              name="grade"
              label="Child Grade"
              rules={[
                {
                  required: true,
                  message: "Please input your child's grade!",
                },
              ]}
            >
              <ASelect customized={false} 
              disabled={userDetails && userDetails?.child?.grade} 
              defaultValue="1st">
                <Select.Option value="1st">1st</Select.Option>
                <Select.Option value="2nd">2nd</Select.Option>
                <Select.Option value="3rd">3rd</Select.Option>
                <Select.Option value="4th">4th</Select.Option>
                <Select.Option value="5th">5th</Select.Option>
                <Select.Option value="6th">6th</Select.Option>
                <Select.Option value="7th">7th</Select.Option>
                <Select.Option value="8th">8th</Select.Option>
                <Select.Option value="9th">9th</Select.Option>
                <Select.Option value="10th">10th</Select.Option>
                <Select.Option value="11th">11th</Select.Option>
                <Select.Option value="12th">12th</Select.Option>
              </ASelect>
            </Form.Item>
          </Col>
        </Row>

        <ATitle level={5} content="Course Enroll" />
        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="branch_location"
              label="Branch Location"
              rules={[
                {
                  required: true,
                  message: "Please select branch location! ",
                },
              ]}
            >
              <ASelect
                className="custom-course-select"
                customized={false}
                placeholder="Branch Location"
                // disabled={branch || information || userDetails?.enrollment?.branch_location}
                disabled={parentId || name}
                onChange={handleStudentCount}
                defaultValue={
                  // information?.branch.length === 1
                  //   ? information?.branch[0].id
                  //   : undefined
                  branch ? branch.id : undefined
                }
                value={
                  // information?.branch.length === 1
                  //   ? information?.branch[0].id
                  //   : undefined
                  branch ? branch.id : undefined
                }
              >
                {information &&
                  information?.branch?.map((branch: any) => (
                    <Select.Option value={branch.id}>
                      {branch.branch_name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>

          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              label="Days per Week"
              name="days_per_week"
              rules={[
                {
                  required: true,
                  message: "Please select Days Per Week!",
                },
              ]}
            >
              <ASelect
                customized={false}
                disabled={userDetails?.enrollment?.days_per_week}
                placeholder="Select Days Per Week"
                onChange={(value) => {
                  setOptionsSelected([]);
                  setDaysPerWeek(parseInt(value));
                  form.resetFields(["available_days"]);
                  filterTimeSlot()
                  filterCourse(parseInt(value))
                }}
              >
                <Select.Option value="2">
                  Full-time (2 Sessions Per Week)
                </Select.Option>
                <Select.Option value="1">
                  Part-time (1 Session Per Week)
                </Select.Option>
              </ASelect>
            </Form.Item>
          </Col>

          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              label="Available Days"
              name="available_days"
              rules={[
                {
                  required: true,
                  message: "Please select days for class",
                },
              ]}
            >
              <ASelect
                mode="multiple"
                allowClear
                maxLength={1}
                disabled={userDetails?.enrollment?.available_days}
                placeholder="Select days"
                onChange={handleChange}
                onClear={() => {
                  setOptionsSelected([]);
                  form.resetFields(["time_slot"]);
                  
                }}
                customized={false}
                maxTagCount={1}
              >
                {daysPerWeek ? (
                  daysPerWeek === 1 ? (
                    // this will map whole days of week
                    days?.map((option: DefaultOptionType) => {
                      return (
                    <Option
                      disabled={
                        optionsSelected.length !== 0 &&
                        optionsSelected.length >= 1
                      }
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </Option>
                      )}
                    )
                  ) :
                  daysPerWeek === 2 ? (
                    //maps all pairs days
                    pairDays.map(
                      (pairDay: { label: string; value: string[] }) => {
                        return (
                          <Option
                            disabled={
                              optionsSelected.length !== 0 &&
                              optionsSelected.length >= 1
                            }
                            value={pairDay.value.join(",")}
                            key={pairDay.value.join(",")}
                          >
                            {pairDay.label}
                          </Option>
                        );
                      }
                    )
                  ) 
                  : null
                ) : (
                  <Option disabled={true} key="disabled">
                    Please select Days Per Week
                  </Option>
                )}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>

        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              label="Select Time Slot"
              name="time_slot"
              rules={[
                {
                  required: true,
                  message: "Please select Time slot!",
                },
              ]}
            >
              <ASelect
                placeholder="Select Time Slot"
                customized={false}
                disabled={optionsSelected.length === 0 || userDetails?.enrollment?.time_slot}
                onChange={handleTimeSlotChange}
              >
                {newFilteredTimeSlots?.map(
                  (formattedTimeSlot: TimeSlotFormatType) => (
                    <Select.Option value={formattedTimeSlot.value}>
                      {formattedTimeSlot.show}
                    </Select.Option>
                  )
                )}
              </ASelect>
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="course_id"
              label="Select Course"
              rules={[{ required: true, message: "Please select a Course!" }]}
            >
              <ASelect
                customized={false}
                placeholder="Select Course"
                onChange={handleStudentCount}
                disabled={!timeSlotChanged || userDetails?.enrollment?.course_id || information?.course}
                className="custom-course-select"
              >
                {information?.course?.map((course: any) => (
                  <Select.Option value={course.id}>
                    {course.course_name}
                  </Select.Option>
                ))}
              </ASelect>
            </Form.Item>
          </Col>

          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="class_start_date"
              label="Class Start Date"
              rules={[
                {
                  required: true,
                  message: "Please select a date!!!",
                },
              ]}
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                disabledDate={disabledDate}
                // onChange={handleDateChange}
              />
            </Form.Item>
          </Col>

          {/* {countStudent < 25 && (
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item label="Admission Fee">
                <AInputNumber
                  prefix={"$"}
                  value={admissionFee}
                  disabled
                ></AInputNumber>
              </Form.Item>
            </Col>
          )} */}
        </Row>

        {/* {countStudent < 25 && (
          <Row wrap gutter={20}>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label="Monthly Fee"
                // name="monthly_fee"
                help="Payment for the first month in advance."
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input Monthly Fee!",
                //   },
                // ]}
              >
                <AInputNumber
                  prefix={monthlyFee ? "$" : ""}
                  value={monthlyFee ? monthlyFee : "Please Choose Course"}
                  disabled
                ></AInputNumber>
              </Form.Item>
            </Col>
          </Row>
        )} */}

        {countStudent >= 25 && (
          <div>
            <Col span={24}>
              <Alert
                message="This class is currently full. Please submit your application to the waitlist. We will notify you when a spot becomes available, or you may choose another available time slot."
                style={{
                  border: "none",
                  color: "#d30000",
                  backgroundColor: "#fcff81",
                }}
              />
            </Col>
          </div>
        )}

{/* {!!batchNotFound && (
          <div>
            <Col span={24}>
              <Alert
                message="Class not found. Please select all fields in Course Enroll section or contact our customer support for any help!"
                style={{
                  border: "none",
                  color: "#d30000",
                  backgroundColor: "#fcff81",
                }}
              />
            </Col>
          </div>
        )} */}

        <ATitle level={5} content="Address" />
        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="address1"
              label="Address Line 1"
              rules={[
                {
                  required: true,
                  message: "Please input address line 1!",
                },
              ]}
            >
              <AInput
                disabled={!!userInfo}
                placeholder="Enter Address Line 1"
              />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item name="address2" label="Address Line 2">
              <AInput
                disabled={!!userInfo}
                placeholder="Enter Address Line 2"
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please input your city name!",
                },
              ]}
            >
              <AInput disabled={!!userInfo} placeholder="Enter City Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap gutter={20}>
          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Please select your state!",
                },
              ]}
            >
              <ASelect
                allowClear
                maxLength={1}
                placeholder="Select Your State"
                customized={false}
                maxTagCount={1}
                disabled={!!userInfo}
              >
                {states &&
                  states.map((state: { label: string; value: string }) => {
                    return (
                      <Option value={state.value} key={state.value}>
                        {state.label}
                      </Option>
                    );
                  })}
              </ASelect>
            </Form.Item>
          </Col>

          <Col md={12} sm={24} lg={8} xs={24}>
            <Form.Item
              name="zip_code"
              label="Zip Code"
              rules={[
                {
                  required: true,
                  message: "Please input zip code!",
                },
                {
                  pattern: /^\d{5}$/,
                  message: "Zip code must be exactly 5 digits!",
                },
              ]}
            >
              <AInput
                maxLength={5}
                disabled={!!userInfo}
                placeholder="Enter Zip Code"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={24} sm={24} lg={24} xs={24}>
            <Form.Item name="pickup_person">
              <Checkbox
                checked={isCheckedAuthorizedPerson}
                onChange={handleAuthorizedPersonChange}
                style={{ fontSize: "1rem" }}
              >
                Designate Authorized Person(s) for Pick-Up
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {isCheckedAuthorizedPerson && (
        <>
        <ATitle level={5} content="Authorized Person 1 Details" />
        <Row wrap gutter={20}>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`First Name`}
                name={"firstauthorisedPersonFirstName"}
                rules={[
                  {
                    required: true,
                    message: `Please input Authorized Person First Name!`,
                  },
                ]}
              >
                <AInput
                  placeholder={`Enter First Name`}
                  size="large" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`Middle Name`}
                name={"firstauthorisedPersonMiddleName"}
                help="Only the initial letter!"
              >
                <AInput
                  placeholder={`Enter Middle Name`}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large"
                  maxLength={1} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`Last Name`}
                name={"firstauthorisedPersonLastName"}
                rules={[
                  {
                    required: true,
                    message: `Please input Authorized Person Last Name!`,
                  },
                ]}
              >
                <AInput
                  placeholder={`Enter Last Name`}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`Contact`}
                name={`firstauthorisedPersonContact`}
                rules={[
                  {
                    required: true,
                    message: `Please input Authorized Person Contact!`,
                  },
                  // {
                  //   validator: validateContact,
                  // },
                ]}
              >
                <AInput
                  placeholder={"Enter Phone Number"}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large"
                  onChange={handleFirstPersonPhoneChange}
                  />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}></Col>
            {!isCheckedSecondAuthorizedPerson && (
              <Col
                md={12}
                lg={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: 40,
                }}
              >
                <Form.Item>
                  <AButton
                    type="default"
                    size="middle"
                    // disabled={userDetails.pickupPerson != 0}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setIsCheckedSecondAuthorizedPerson(true);
                    } }
                    style={{ padding: "0px 16px" }}
                  >
                    Add
                  </AButton>
                </Form.Item>
              </Col>
            )}
          </Row>
          </>
        )}
        {isCheckedAuthorizedPerson && isCheckedSecondAuthorizedPerson && (
        <>
        <ATitle level={5} content="Authorized Person 2 Details" />
        <Row wrap gutter={20}>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`First Name`}
                name={"secondauthorisedPersonFirstName"}
                rules={[
                  {
                    required: true,
                    message: `Please input Authorized Person First Name!`,
                  },
                ]}
              >
                <AInput
                  placeholder={`Enter First Name`}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`Middle Name`}
                name={"secondauthorisedPersonMiddleName"}
                help="Only the initial letter!"
              >
                <AInput
                  placeholder={`Middle Name`}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large"
                  maxLength={1} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`Last Name`}
                name={"secondauthorisedPersonLastName"}
                rules={[
                  {
                    required: true,
                    message: `Please Last Name!`,
                  },
                ]}
              >
                <AInput
                  placeholder={`Last Name`}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} lg={8} xs={24}>
              <Form.Item
                label={`Contact`}
                name={`secondauthorisedPersonContact`}
                rules={[
                  {
                    required: true,
                    message: `Please input Authorized Person Contact!`,
                  },
                  // {
                  //   validator: validateContact,
                  // },
                ]}
              >
                <AInput
                  placeholder={"Enter Phone Number"}
                  // disabled={userDetails && userDetails.length != 0}
                  size="large"
                  onChange={handleSecondPersonPhoneChange}
                  />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}></Col>
            {isCheckedSecondAuthorizedPerson && (
              <Col
                md={12}
                lg={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingTop: 40,
                }}
              >
                <Form.Item>
                  <AButton
                    type="default"
                    size="middle"
                    icon={<MinusOutlined />}
                    onClick={() => {
                      setIsCheckedSecondAuthorizedPerson(false);
                    } }
                    style={{ padding: "0px 16px" }}
                  >
                    Remove
                  </AButton>
                </Form.Item>
              </Col>
            )}
          </Row>
          </>
        )}
        <Row>
          <Col>
            <AText
              onClick={() =>
                window.open(
                  `https://kidncode.com/terms-and-conditions`,
                  "_blank"
                )
              }
              style={{ color: "#6c559e", cursor: "pointer" }}
            >
              View Terms and Conditions
            </AText>
          </Col>
        </Row>

        <Row wrap gutter={20}>
          <Col md={24} sm={24} lg={24} xs={24}>
            <Form.Item
              name="terms_and_condition"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "Please accept the terms and conditions to continue!"
                        ),
                },
              ]}
            >
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ fontSize: "1rem" }}
              >
                I accept terms and conditions.
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Modal
          title="Terms and Conditions"
          visible={modalVisible}
          onCancel={handleModalClose}
          width={1100}
          footer={[
            <AButton key="cancel" onClick={handleModalClose}>
              Close
            </AButton>,
          ]}
        >
          <TermsAndCondition />
        </Modal>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Item>
            <Col md={12} sm={24} lg={8} xs={24}>
              {countStudent < 25 ? (
                <AButton
                  type="primary"
                  htmlType="submit"
                  // size="large"
                  // style={{ padding: "0.3rem 3rem" }}
                  disabled={batchNotFound}
                  onClick={() => {
                    if(parentId){
                      if(isChecked){
                        proceedToPayment();
                      }else {
                        message.error("Please accept our terms and conditions!")
                      }
                    }
                  }}
                >
                  Proceed to Payment Details
                </AButton>
              ) : (
                <AButton type="primary" htmlType="submit">
                  Submit To Waitlist 
                </AButton>
              )}
            </Col>
          </Form.Item>
        </Row>
      </Form>

      {countStudent < 25 && (
        <>
          {(isCodeVerified || formSubmittedSuccessfully || !userInfo) && (
            <AModal
              open={stepForm === 2}
              onCancel={() => setStepForm(1)}
              footer={null}
              maskClosable={false}
              width={1000}
            >
              <CheckoutForm
                onFinish={subscribe}
                selectedPlans={selectedPlans}
                newChild={userInfo ? false : true}
                admissionFee={admissionFee}
                monthlyFee={monthlyFee}
                formValues={formValues}
                studentCount={countStudent}
                userDetails={userDetails}
                id={id}
                parent_id={parent_id}
              />
            </AModal>
          )}
        </>
      )}
      {parentId &&  (
        <AModal
          open={stepForm === 2}
          onCancel={() => setStepForm(1)}
          footer={null}
          maskClosable={false}
          width={1000}
        >
          <CheckoutForm
            onFinish={subscribe}
            selectedPlans={selectedPlans}
            newChild={userInfo ? false : true}
            admissionFee={admissionFee}
            monthlyFee={monthlyFee}
            formValues={formValues}
            studentCount={countStudent}
            userDetails={userDetails}
            id={id}
            parent_id={parent_id}
          />
        </AModal>
      )}
      <AModal
        style={{
          padding: "1rem",
        }}
        open={sendVerificationCodeSuccess}
        closable={false}
        footer={null}
      >
        <Space align="center" direction="vertical">
          <Space direction="vertical" size="small" align="center">
            <ActiveIcon
              style={{
                fontSize: "55px",
                color: token.colorSuccess,
              }}
            />
            <ATitle content="Verification Code Sent !" level={4} />
            <p
              style={{
                textAlign: "center",
              }}
            >
              One-time verification code has been send to your provided contact
              number. Please Check !!!
            </p>
          </Space>
          <AButton
            type="primary"
            size="small"
            onClick={() => {
              setSendVerificationCodeSuccess(false);
              setIsModalOpen(true);
              // navigate("/");
            }}
          >
            Ok
          </AButton>
        </Space>
      </AModal>

      <AModal
        style={{
          padding: "1rem",
        }}
        open={updateSuccess}
        closable={false}
        footer={null}
      >
        <Space align="center" direction="vertical">
          <Space direction="vertical" size="small" align="center">
            <ActiveIcon
              style={{
                fontSize: "55px",
                color: token.colorSuccess,
              }}
            />
            <ATitle content="Successfully Done !" level={4} />
            <p
              style={{
                textAlign: "center",
              }}
            >
              Thank you for your submission. We will review it and send you an
              email.
            </p>
          </Space>
          <AButton
            type="primary"
            size="small"
            onClick={() => {
              setUpdateSuccess(false);
              if(userInfo && id){
                navigate("/parentAndChildAssociation")
              }else if(customerSupportUser) {
                navigate("/parentAndChildAssociation")
              }
              else if(userInfo){
                navigate("/")
              }else {
                navigate("/login");
              }
            }}
          >
            Ok
          </AButton>
        </Space>
      </AModal>

        <AModal
        style={{
          padding: "1rem",
        }}
        open={isWaitListModelVisible}
        closable={false}
        footer={null}
        >
        <Space align="center" direction="vertical">
          <Space direction="vertical" size="small" align="center">
            <ActiveIcon
              style={{
                fontSize: "55px",
                color: token.colorSuccess,
              }}
            />
            <ATitle content="Successfully Done !" level={4} />
            <p
              style={{
                textAlign: "center",
              }}
            >
              Thank you for your submission. We will review it and send you an
              email.
            </p>
          </Space>
          <AButton
            type="primary"
            size="small"
            onClick={() => {
              setUpdateSuccess(false);
              setIsWaitListModelVisible(false);
              if(!!!userInfo){
                navigate("/login");
              }else {
                navigate("/");
              }
            }}
            >
            Ok
          </AButton>
        </Space>
      </AModal>

      {(!userInfo || !parentId) && (
        <AModal
          maskClosable={false}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          title="Enter Verification Code"
          footer={[
            <AButton key="cancel" onClick={() => setIsModalOpen(false)}>
              Cancel
            </AButton>,
            <AButton key="verify" type="primary" onClick={verifyCode}>
              Verify Code
            </AButton>,
          ]}
        >
          <Form.Item
            rules={[
              {
                required: true,
                len: 6,
                message: "Enter Valid Code",
              },
            ]}
          >
            <AInput
              placeholder="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Form.Item>
        </AModal>
      )}
      <AModal
          open={existingUser}
          centered
          bodyStyle={{ padding: '20px', marginTop: "-3rem" }} 
          onCancel={() => setExistingUser(false)}
          title={<div style={{ paddingRight: '40px' }}>
            User with this email already exist. Do you want to reactivate this account or use a different email address?
          </div>}
          footer={[
            <AButton key="cancel" onClick={handleReactivate}>
              Reactivate 
            </AButton>, 
          <AButton key="confirm" type="primary" 
            onClick={() => {
              setExistingUser(false)
            }}
          >
              Use Different Email
            </AButton>
          ]}
          >
            </AModal>

            <AModal
        style={{
          padding: "1rem",
        }}
        open={reactivationModel}
        closable={false}
        footer={null}
        >
        <Space align="center" direction="vertical">
          <Space direction="vertical" size="small" align="center">
            <ActiveIcon
              style={{
                fontSize: "55px",
                color: token.colorSuccess,
              }}
            />
            <ATitle content="Successfully Done !" level={4} />
            <p
              style={{
                textAlign: "center",
              }}
            >
              A mail for further processing has been sent to your provided email!
            </p>
          </Space>
          <AButton
            type="primary"
            size="small"
            onClick={() => {
              setUpdateSuccess(false);
              setIsWaitListModelVisible(false);
              setReactivationModel(false)
              navigate("/")
            }}
            >
            Ok
          </AButton>
        </Space>
      </AModal>

      <AModal
          open={existingUserWithoutSubscription}
          centered
          bodyStyle={{ padding: '20px', marginTop: "-3rem" }} 
          onCancel={() => setExistingUserWithoutSubscription(false)}
          title={<div style={{ paddingRight: '40px' }}>
            User with this email already exist. Looks like your child's enrollment has not been complete. Want to complete it now?
          </div>}
          footer={[
            <AButton key="confirm">
              Continue
            </AButton>, 
          <AButton key="cancel" type="primary" 
            onClick={() => {
              setExistingUserWithoutSubscription(false)
            }}
          >
              Use new data
            </AButton>
          ]}
          >
            </AModal>

      <AModal
        style={{
          padding: "50px",
        }}
        open={reactivationPendingModel}
        closable={false}
        footer={null}
      >
        <Space align="center" direction="vertical">
          <Space direction="vertical" size="small" align="center">
            <ExclamationCircleOutlined
              style={{
                fontSize: "60px",
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_PENDING,
                borderRadius: "50%",
                marginTop: "20px",
              }}
            />
            <ATitle content="Pending for Approval!" level={2} />
            <p
              style={{
                textAlign: "center",
                fontSize: tokens.PARAGRAPH,
              }}
            >
              Your child is currently in waiting list. You will be notify sortly via email when the spot is available. Thank You! 
            </p>
          </Space>
          <AButton
            type="primary"
            size="large"
            onClick={() => {
              setReactivationPendingModel(false);
              navigate("/login")
            }}
          >
            Ok
          </AButton>
        </Space>
      </AModal>
    </>
  );
};

export default FormFields;
