const actions = {
  FETCH_BRANCHES: "FETCH_BRANCHES",
  FETCH_BRANCHES_SUCCESS: "FETCH_BRANCHES_SUCCESS",
  FETCH_BRANCHES_FAILED: "FETCH_BRANCHES_FAILED",
  FETCH_BRANCH: "FETCH_BRANCH",
  FETCH_BRANCH_SUCCESS: "FETCH_BRANCH_SUCCESS",
  FETCH_BRANCH_FAILED: "FETCH_BRANCH_FAILED",
  CREATE_BRANCH: "CREATE_BRANCH",
  CREATE_BRANCH_SUCCESS: "CREATE_BRANCH_SUCCESS",
  CREATE_BRANCH_FAILED: "CREATE_BRANCH_FAILED",
  UPDATE_BRANCH: "UPDATE_BRANCH",
  UPDATE_BRANCH_SUCCESS: "UPDATE_BRANCH_SUCCESS",
  UPDATE_BRANCH_FAILED: "UPDATE_BRANCH_FAILED",
  DELETE_BRANCH: "DELETE_BRANCH",
  DELETE_BRANCH_SUCCESS: "DELETE_BRANCH_SUCCESS",
  DELETE_BRANCH_FAILED: "DELETE_BRANCH_FAILED",

  createBranch: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CREATE_BRANCH,
    data,
    resolve,
    reject,
  }),

  updateForm: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_BRANCH,
    data,
    resolve,
    reject,
  }),

  fetchBranches: (
    page: number,
    pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCHES,
    page,
    pageSize,
    resolve,
    reject,
  }),

  fetchBranch: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_BRANCH,
    resolve,
    reject,
  }),

  deleteBranch: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_BRANCH,
    id,
    resolve,
    reject,
  }),
};

export default actions;
