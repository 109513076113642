import { BranchAssociate } from "../../branchAssociate/interface/BranchAssociate.interface";

const actions = {
  FETCH_BRANCH_SELF_SERVICES: "FETCH_BRANCH_SELF_SERVICES",
  FETCH_BRANCH_SELF_SERVICES_SUCCESS: "FETCH_BRANCH_SELF_SERVICES_SUCCESS",
  FETCH_BRANCH_SELF_SERVICE: "FETCH_BRANCH_SELF_SERVICE",
  SAVE_BRANCH_SELF_SERVICE: "SAVE_BRANCH_SELF_SERVICE",
  SAVE_BRANCH_SELF_SERVICE_SUCCESS: "SAVE_BRANCH_SELF_SERVICE_SUCCESS",
  DELETE_BRANCH_SELF_SERVICE: "DELETE_BRANCH_SELF_SERVICE",
  DELETE_BRANCH_SELF_SERVICE_SUCCESS: "DELETE_BRANCH_SELF_SERVICE_SUCCESS",
  DELETE_BRANCH_SELF_SERVICE_FAILED: "DELETE_BRANCH_SELF_SERVICE_FAILED",

  fetchBranchSelfServices: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCH_SELF_SERVICES,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchBranchSelfService: (
    id: number | "" | undefined,
    resolve: (value: BranchAssociate) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCH_SELF_SERVICE,
    id,
    resolve,
    reject,
  }),

  saveBranchSelfService: (
    data: BranchAssociate,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_BRANCH_SELF_SERVICE,
    data,
    event,
    resolve,
    reject,
  }),

  deleteBranchSelfService: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_BRANCH_SELF_SERVICE,
    id,
    resolve,
    reject,
  }),
};

export default actions;
