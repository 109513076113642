import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface LoginReturnType {
  [x: string]: string;
}

const login = takeLatest(
  actions.LOGIN,
  function* (action: AnyAction): Generator<Effect, void, LoginReturnType> {
    try {
      const response = yield call(
        api.post,
        "api/auth/login",
        {
          email: action.data.email,
          password: action.data.password,
        },
        null,
        true
      );
      localStorage.setItem("token", response.accessToken);
      // yield put({
      // 	type: actions.FETCH_CURRENT_USER,
      // 	resolve: action.resolve,
      // 	reject: action.reject,
      // });
      yield call(action.resolve, response.message);
    } catch (error) {
      yield put({ type: actions.LOGIN_FAILED });
      yield call(action.reject, error);
    }
  }
);

const updateUser = takeLatest(
  actions.UPDATE_USER,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.patch,
        `users/${action.data.id}`,
        action.data
      );
      yield put({ type: actions.STORE_CURRENT_USER, payload: response });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_CURRENT_USER_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchCurrentUser = takeLatest(
  actions.FETCH_CURRENT_USER,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, "users/current", false);
      yield put({ type: actions.STORE_CURRENT_USER, payload: response });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_CURRENT_USER_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([login, fetchCurrentUser, updateUser]);
}
