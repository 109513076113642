import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        surveyQuestions: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type){
        case actions.FETCH_QUESTIONS:
            return state.setIn(["loading"], true);

        case actions.FETCH_QUESTIONS_SUCCESS:
            const questions = action.payload;
            return state.merge({
                loading: false,
                surveyQuestions: questions,
            });
            
        case actions.FETCH_QUESTIONS_FAILED:
            return state.setIn(["loading"], false);
            
        default:
            return state;    
    }
};

export default reducer;