import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface TimeSlotReturnType {
  [x: string]: string;
}

const createTimeSlot = takeLatest(
  actions.CREATE_TIME_SLOT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.post, `api/timeSlot`, action.data, null);
      yield put({
        type: actions.CREATE_TIME_SLOT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.CREATE_TIME_SLOT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const fetchTimeSlots = takeLatest(
  actions.FETCH_TIME_SLOTS,
  function* (action: AnyAction): Generator<Effect, void, TimeSlotReturnType> {
    try {
      const response = yield call(api.get, "api/timeSlot", false);
      yield put({
        type: actions.FETCH_TIME_SLOTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_TIME_SLOTS_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateTimeSlot = takeLatest(
  actions.UPDATE_TIME_SLOT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/timeSlot/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_TIME_SLOT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.UPDATE_TIME_SLOT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const deleteTimeSlot = takeLatest(
  actions.DELETE_TIME_SLOT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/timeSlot/${action.id}`);
      yield put({
        type: actions.DELETE_TIME_SLOT_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.DELETE_TIME_SLOT_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([createTimeSlot, fetchTimeSlots, updateTimeSlot, deleteTimeSlot]);
}
