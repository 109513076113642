const messageactions = {
  FETCH_MESSAGELIST: "FETCH_MESSAGELIST",
  FETCH_MESSAGELIST_SUCCESS: "FETCH_MESSAGELIST_SUCCESS",

  fetchMessageList: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: messageactions.FETCH_MESSAGELIST,
    resolve,
    reject,
  }),
};

export default messageactions;
