const actions = {
  FETCH_TIME_SLOTS: "FETCH_TIME_SLOTS",
  FETCH_TIME_SLOTS_SUCCESS: "FETCH_TIME_SLOTS_SUCCESS",
  FETCH_TIME_SLOTS_FAILED: "FETCH_TIME_SLOTS_FAILED",
  CREATE_TIME_SLOT: "CREATE_TIME_SLOT",
  CREATE_TIME_SLOT_SUCCESS: "CREATE_TIME_SLOT_SUCCESS",
  CREATE_TIME_SLOT_FAILED: "CREATE_TIME_SLOT_FAILED",
  UPDATE_TIME_SLOT: "UPDATE_TIME_SLOT",
  UPDATE_TIME_SLOT_SUCCESS: "UPDATE_TIME_SLOT_SUCCESS",
  UPDATE_TIME_SLOT_FAILED: "UPDATE_TIME_SLOT_FAILED",
  DELETE_TIME_SLOT: "DELETE_TIME_SLOT",
  DELETE_TIME_SLOT_SUCCESS: "DELETE_TIME_SLOT_SUCCESS",
  DELETE_TIME_SLOT_FAILED: "DELETE_TIME_SLOT_FAILED",

  createTimeSlot: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CREATE_TIME_SLOT,
    data,
    resolve,
    reject,
  }),

  updateTimeSlot: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_TIME_SLOT,
    data,
    resolve,
    reject,
  }),

  fetchTimeSlots: (
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_TIME_SLOTS,
    resolve,
    reject,
  }),

  deleteTimeSlot: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_TIME_SLOT,
    id,
    resolve,
    reject,
  }),
};

export default actions;
