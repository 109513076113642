import { Course, LearningMaterialsType } from "../interface/Course.interface";

const actions = {
  FETCH_COURSES: "FETCH_COURSES",
  FETCH_COURSES_SUCCESS: "FETCH_COURSES_SUCCESS",
  FETCH_LEARNING_MATERIALS: "FETCH_LEARNING_MATERIALS",
  FETCH_LEARNING_MATERIALS_SUCCESS: "FETCH_LEARNING_MATERIALS_SUCCESS",
  SAVE_LEARNING_MATERIALS: "SAVE_LEARNING_MATERIALS",
  SAVE_LEARNING_MATERIALS_SUCCESS: "SAVE_LEARNING_MATERIALS_SUCCESS",
  FETCH_COURSE: "FETCH_COURSE",
  SAVE_COURSE: "SAVE_COURSE",
  SAVE_COURSE_SUCCESS: "SAVE_COURSE_SUCCESS",
  DELETE_COURSE: "DELETE_COURSE",
  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",

  fetchCourses: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_COURSES,
    resolve,
    reject,
  }),

  fetchCourse: (
    id: number | "" | undefined,
    resolve: (value: Course) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_COURSE,
    id,
    resolve,
    reject,
  }),

  fetchLearningMaterials: (
    resolve: (value: LearningMaterialsType) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_LEARNING_MATERIALS,
    resolve,
    reject,
  }),

  saveLearningMaterials: (
    data: LearningMaterialsType | FormData,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_LEARNING_MATERIALS,
    data,
    resolve,
    reject,
  }),

  saveCourse: (
    data: Course,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_COURSE,
    data,
    event,
    resolve,
    reject,
  }),

  deleteCourse: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_COURSE,
    id,
    resolve,
    reject,
  }),
};

export default actions;
