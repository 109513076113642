import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchReferrals = takeLatest(
    actions.FETCH_REFERRALS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.get,
          urlGenerator("/api/referral", action)
        );
        yield put({
          type: actions.FETCH_REFERRALS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield call(action.reject, error);
      }
    }
  );

  const updateReferral = takeLatest(
    actions.UPDATE_REFERRAL,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/referral/${action?.data?.id}`,
          action.data
        );
        yield put({
          type: actions.UPDATE_REFERRAL_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error: any) {
        yield put({ type: actions.UPDATE_REFERRAL_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

  export default function* saga() {
    yield all([
        fetchReferrals,
        updateReferral
    ]);
  }