import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchLogs = takeLatest(
  actions.FETCH_LOGS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, urlGenerator("/api/logs", action));
      yield put({
        type: actions.FETCH_LOGS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchLog = takeLatest(
  actions.FETCH_LOG,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `/api/logs/${action.id}`);
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const deleteLog = takeLatest(
  actions.DELETE_LOG,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(api.delete, `/api/logs/${action.id}`);
      yield put({
        type: actions.DELETE_LOG_SUCCESS,
        id: action.id,
      });
      yield call(action.resolve, responseId);
    } catch (error) {
      yield put({ type: actions.DELETE_LOG_FAILED });
      yield call(action.reject, "failed");
    }
  }
);

const deletePermanently = takeLatest(
  actions.DELETE_PERMANENTLY,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(
        api.get,
        `/api/logs/deletePermanently/${action.id}`
      );
      yield put({
        type: actions.DELETE_PERMANENTLY_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield put({ type: actions.DELETE_PERMANENTLY_FAILED });
      yield call(action.reject, "failed");
    }
  }
);

const undoLog = takeLatest(
  actions.UNDO_LOG,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `/api/logs/undo/${action.id}`);
      yield put({
        type: actions.UNDO_LOG_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.UNDO_LOG_FAILED });
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([fetchLogs, fetchLog, undoLog, deleteLog, deletePermanently]);
}
