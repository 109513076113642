import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchLeaveRequests = takeLatest(
  actions.FETCH_LEAVE_REQUESTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const url = action.page
        ? `/api/leave?page=${action.page}&pageSize=${action.pageSize}`
        : `api/leave`;
      const response = yield call(api.get, urlGenerator("/api/leave", action));
      yield put({
        type: actions.FETCH_LEAVE_REQUESTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveLeaveRequest = takeLatest(
  actions.SAVE_LEAVE_REQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.post, `api/leave`, action.data, null);
      yield put({
        type: actions.SAVE_LEAVE_REQUEST_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const updateLeaveRequest = takeLatest(
  actions.UPDATE_LEAVE_REQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/leave/${action.data.id}`,
        action.data
      );

      yield put({
        type: actions.UPDATE_LEAVE_REQUEST_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);
export default function* saga() {
  yield all([fetchLeaveRequests, saveLeaveRequest, updateLeaveRequest]);
}
