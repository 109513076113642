const actions = {
    CREATE_REFUND: "CREATE_REFUND",
    CREATE_REFUND_SUCCESS: "CREATE_REFUND_SUCCESS",
    CREATE_REFUND_FAILED: "CREATE_REFUND_FAILED",
    CREATE_CREDIT_AND_DISCOUNT: "CREATE_CREDIT_AND_DISCOUNT",
    CREATE_CREDIT_AND_DISCOUNT_SUCCESS: "CREATE_CREDIT_AND_DISCOUNT_SUCCESS",
    CREATE_CREDIT_AND_DISCOUNT_FAILED: "CREATE_CREDIT_AND_DISCOUNT_FAILED",
    FETCH_ALL_REFUNDS: "FETCH_ALL_REFUNDS",
    FETCH_ALL_REFUNDS_SUCCESS: "FETCH_ALL_REFUNDS_SUCCESS",
    FETCH_ALL_REFUNDS_FAILED: "FETCH_ALL_REFUNDS_FAILED",

    createRefund: (
        data: any,
        resolve: (token: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.CREATE_REFUND,
        data,
        resolve,
        reject,
    }),

    createDiscountAndCredit: (
        data: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.CREATE_CREDIT_AND_DISCOUNT,
        data,
        resolve,
        reject,
    }),

    fetchAllRefunds: (
        resolve: (data: string) => void,
        reject: (value: string) => void,
       ) => ({
        type: actions.FETCH_ALL_REFUNDS,
        resolve,
        reject,
       }),
}

export default actions;