const actions = {
    FETCH_ALL_CREDIT_AND_DISCOUNTS: "FETCH_ALL_CREDIT_AND_DISCOUNTS",
    FETCH_ALL_CREDIT_AND_DISCOUNTS_SUCCESS: "FETCH_ALL_CREDIT_AND_DISCOUNT_SUCCESS",
    FETCH_ALL_CREDIT_AND_DISCOUNTS_FAILED: "FETCH_ALL_CREDIT_AND_DISCOUNTS_FAILED",
    FETCH_CREDIT_AND_DISCOUNTS: "FETCH_CREDIT_AND_DISCOUNTS",
    FETCH_CREDIT_AND_DISCOUNTS_SUCCESS: "FETCH_CREDIT_AND_DISCOUNTS_SUCCESS",
    FETCH_CREDIT_AND_DISCOUNTS_FAILED: "FETCH_CREDIT_AND_DISCOUNTS_FAILED",

    fetchAllCreditAndDiscounts: (
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_ALL_CREDIT_AND_DISCOUNTS,
        resolve,
        reject,
    }),

    fetchCreditAndDiscounts: (
        id: number | any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_CREDIT_AND_DISCOUNTS,
        id,
        resolve,
        reject,
    })
}

export default actions;