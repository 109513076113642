import NewEventsSaga from "./sagas";
import NewEventsReducer from "./reducers";
import newEventsActions from "./actions";

export const newEventsReducer = {
  newEventsReducer: NewEventsReducer,
};

export const newEventsSaga = [NewEventsSaga()];

export { newEventsActions };
