import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchChildDetails = takeLatest(
    actions.FETCH_CHILD_DETAILS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.get,
          `api/pendingstudents/child-details/${action.id}`, false
        );
        yield put({
          type: actions.FETCH_CHILD_DETAILS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, "success");
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

  const removePendingStudent = takeLatest(
    actions.REMOVE_FROM_WAITING_LIST,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/pendingStudents/removefromwaitinglist/${action.data.id}`, action.data
        );
        yield put({ 
          type: actions.REMOVE_FROM_WAITING_LIST_SUCCESS,
          payload: action.data.id,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.REMOVE_FROM_WAITING_LIST_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

export default function* saga() {
    yield all([fetchChildDetails, removePendingStudent]);
}
