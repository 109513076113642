import { Input, InputProps } from "antd";
import { TextAreaProps } from "antd/lib/input/TextArea";

interface AInputProps extends InputProps {
  type?: "string" | "password" | "textarea";
  customized?: boolean;
  extraStyle?: any;
}

function AInput({
  type,
  placeholder,
  bordered,
  customized,
  extraStyle,
  ...others
}: AInputProps) {
  const commonStyle: React.CSSProperties = {
    borderBottom: "1px solid black",
    borderRadius: 0,
    fontSize: "12px",
  };

  const inputComponent =
    type === "password" ? (
      <Input.Password
        placeholder={placeholder}
        bordered={bordered}
        {...others}
        style={customized ? commonStyle : (extraStyle ? extraStyle : undefined) }
      />
    ) : type === "textarea" ? (
      <Input.TextArea
        placeholder={placeholder}
        bordered={bordered}
        {...others as TextAreaProps}
        style={customized ? { ...commonStyle, fontSize: "14px" } : { fontSize: "14px" }}
      />
    ) : (
      <Input
        placeholder={placeholder}
        bordered={bordered}
        {...others}
        style={customized ? commonStyle : { fontSize: "14px" }}
      />
    );

  return inputComponent;
}

export default AInput;
