import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        employees: [],
        employeeAttendance: [],
        employeeRole: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_EMPLOYEE_DETAILS_SUCCESS:
            return state.setIn(["employees"], action.payload);

        case actions.FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_SUCCESS:
            return state.setIn(["employeeAttendance"], action.payload);
        
        case actions.FETCH_EMPLOYEE_ROLE_SUCCESS:
            return state.setIn(["employeeRole"], action.payload);      

        default:
            return state;    
    }
}

export default reducer;