// Define constants here

import { SelectProps } from "antd";
import { StringObject } from "./interface/Utils.interface";

/* 
Themeing variables
*/
export const tokens: StringObject = {
  HEADING_1: "20",
  HEADING_2: "18",
  HEADING_3: "16",
  LINEHEIGHT_H1: "22.4",
  LINEHEIGHT_H2: "21",
  LINEHEIGHT_H3: "19.6",
  PARAGRAPH: "14",
  FONT_FAMILY: "'Poppins', sans-serif",
  THEME_COLOR_HEADING_DISPLAY: "#5E5873",
  THEME_COLOR: "#7367F0",
  THEME_COLOR_SUCCESS: "#28C76F",
  BORDER: " 1px solid #EDEDED",
  CARD_BOX_SHADOW: "0px 4px 24px 0px #0000000F",
  COLOR_SUCCESS: "#6EB869",
  COLOR_PENDING: "#F4C249",
  BG_LIGHT: "#FFFFFF",
  COLOR_USERNAME: "#6E6B7B",
  COLOR_PRIMARY: "#6C559E",
  COLOR_PRIMARY_P50: "#f0eef5",
  COLOR_PRIMARY_P75: "#c3b9d7",
  COLOR_PRIMARY_P100: "#aa9cc7",
  COLOR_PRIMARY_P200: "#8572ae",
  COLOR_PRIMARY_P300: "#6c559e",
  COLOR_PRIMARY_P400: "#4c3b6f",
  COLOR_PRIMARY_P500: "#423460",
  COLOR_SECONDARY: "#f4c249",
  COLOR_SECONDARY_S50: "#fef9ed",
  COLOR_SECONDARY_S75: "#fae6b4",
  COLOR_SECONDARY_S100: "#f9dc95",
  COLOR_SECONDARY_S200: "#f6cc68",
  COLOR_SECONDARY_S300: "#f4c249",
  COLOR_SECONDARY_S400: "#ab8833",
  COLOR_SECONDARY_S500: "#95762d",
  COLOR_GREEN: "#437040",
  COLOR_GREEN_G50: "#f1f8f0",
  COLOR_GREEN_G75: "#c4e2c2",
  COLOR_GREEN_G100: "#87c483",
  COLOR_GREEN_G200: "#87c483",
  COLOR_GREEN_G300: "#6eb869",
  COLOR_GREEN_G400: "#4d814a",
  COLOR_GREEN_G500: "#437040",
  COLOR_DANGER: "#921000",
  COLOR_DANGER_D50: "#fae8e6",
  COLOR_DANGER_D75: "#eca096",
  COLOR_DANGER_D100: "#e4786b",
  COLOR_DANGER_D200: "#d93e2b",
  COLOR_DANGER_D300: "#d11700",
  COLOR_DANGER_D400: "#921000",
  COLOR_DANGER_D500: "#7f0e00",
  COLOR_LIGHTBLACK: "#2b2b2c",
  COLOR_LIGHTBLACK_L50: "#ececec",
  COLOR_LIGHTBLACK_L75: "#b0b0b0",
  COLOR_LIGHTBLACK_L100: "#8f8f90",
  COLOR_LIGHTBLACK_L200: "#5f5f60",
  COLOR_LIGHTBLACK_L300: "#3e3e3f",
  COLOR_LIGHTBLACK_L400: "#2b2b2c",
  COLOR_LIGHTBLACK_L500: "#262626",
  COLOR_NEUTRAL: "#565656",
  COLOR_NEUTRAL_N0: "#f0eef5",
  COLOR_NEUTRAL_N10: "#fbfbfb",
  COLOR_NEUTRAL_N20: "#f6f6f6",
  COLOR_NEUTRAL_N30: "#ededed",
  COLOR_NEUTRAL_N40: "#e2e2e3",
  COLOR_NEUTRAL_N50: "#c8c8c8",
  COLOR_NEUTRAL_N60: "#bbbbbb",
  COLOR_NEUTRAL_N70: "#b0b0b0",
  COLOR_NEUTRAL_N80: "#a3a3a3",
  COLOR_NEUTRAL_N90: "#959596",
  COLOR_NEUTRAL_N100: "#888889",
  COLOR_NEUTRAL_N200: "#7b7b7c",
  COLOR_NEUTRAL_N300: "#6e6e6e",
  COLOR_NEUTRAL_N400: "#636364",
  COLOR_NEUTRAL_N500: "#565656",
  COLOR_NEUTRAL_N600: "#4b4b4b",
  COLOR_NEUTRAL_N700: "#3b3b3c",
  COLOR_NEUTRAL_N800: "#2e2e2f",
  COLOR_NEUTRAL_N900: "#232324",
  COLOR_LINK : '#7cade3'
};

// Role constants
export const roleNames: StringObject = {
  SUPER_ADMIN: "super admin",
  BRANCH_ADMIN: "branch admin",
  BRACH_ASSOCIATE: "branch associate",
  INSTRUCTOR: "instructor",
  PARENT: "parent",
  CHILD: "child",
  BRANCH_SELF_SERVICE: "branch self-service",
  CUSTOMER_SUPPORT_ADMIN: "customer support admin",
  CUSTOMER_SUPPORT_USER: "customer support user",
};

export const days: SelectProps["options"] = [
  { label: "Sunday", value: "sun" },
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
];

export const campWeek: SelectProps["options"] = [
  { label: "Entire Camp Week", value: "2" },
  { label: "Weekly Camp", value: "1" }
]

export const shortCampWeek: SelectProps["options"] = [
  { label: "1st Week (Dec-23 To Dec-27)", value: "1" },
  { label: "2nd Week (Dec-30 To Jan-06)", value: "2" },
]

export const longCampWeek: SelectProps["options"] = [
  { label: "Dec-23 To Jan-06", value: "3"}
]

export const trialClassDays: SelectProps["options"] = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" },
];

export const appointments: SelectProps["options"] = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  {label: "Sunday", value: "sun"},
];

export const partTimeDays: SelectProps["options"] = [
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
]

export const dayToIndex: { [x: string]: number } = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export const daysLabel: string[] = [
  "",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const monthsLabel: string[] = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const modelAlias: StringObject = {
  Leave: "leaveApproval",
  EnrollmentChangeReq: "enrolledStudents",
  Removal: "removeChildApproval",
  Attendance: "attendance",
};

export const pairDays: { label: string; value: string[] }[] = [
  { label: "Mon-Wed", value: ["mon", "wed"] },
  { label: "Tue-Thu", value: ["tue", "thu"] },
  // { label: "Wed-Fri", value: ["wed", "fri"] },
  { label: "Sat-Sun", value: ["sat", "sun"] },
];

export const newPairDays: { label: string; value: string[] }[] = [
  { label: "Mon-Thu", value: ["mon", "thu"] },
  // { label: "Tue-Thu", value: ["tue", "thu"] },
  { label: "Sat-Sun", value: ["sat", "sun"] },
];

export const roomActivities: StringObject = {
  JOIN_ROOM: "join_room",
  CREATE_NEW_ROOM: "create_new_room",
  READ_MESSAGE: "read_message",
  SEND_MESSAGE: "send_message",
  MESSAGE_TYPING: "message_typing",
  STOP_MESSAGE_TYPING: "stop_message_typing",
  CREATE_NWEROOM: "create_newroom",
  RECEIVE_MESSAGE: "receive_message",
  RECEIVE_MESSAGE_TYPING: "receive_message_typing",
  STOP_RECEIVE_MESSAGE_TYPING: "stop_receive_message_typing",
};

export const roomType: StringObject = {
  TYPE_GROUP: "group",
  TYPE_INDIVIDUAL: "individual",
};

export const milestoneStep: StringObject = {
  // STEP_1: "Beginner",
  // STEP_2: "Intermediate",
  // STEP_3: "Advance",
  // STEP_4: "Congratulation on your Achievement",
  STEP_1: "Level 1",
  STEP_2: "Level 2",
  STEP_3: "Level 3",
  STEP_4: "Level 4",
  STEP_5: "Specialized",
};
export const milestoneLevel: { [x: string]: number } = {
  // Beginner: 0,
  // Intermediate: 33,
  // Advance: 66,
  // "Congratulation on your Achievement": 100,
  "Level 1": 0,
  "Level 2": 25,
  "Level 3": 50,
  "Level 4": 75,
  Specialized: 100,
};

export const states: {label: string; value: string}[] = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

