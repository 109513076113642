import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    students: [],
    activeStudent: {},
    activeProgressReport: {},
    progressReports: [],
    activeCurrentCourse: {},
    currentCourse: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case (actions.FETCH_STUDENT,
    actions.FETCH_PROGRESS_REPORT,
    actions.FETCH_STUDENTS,
    actions.FETCH_PROGRESS_REPORTS,
    actions.ADD_PROGRESS_REPORT,
    actions.FETCH_CURRENT_COURSES,
    actions.FETCH_CURRENT_COURSE,
    actions.ADD_CURRENT_COURSE):
      return state.setIn(["loading"], true);

    case actions.FETCH_STUDENTS_SUCCESS:
      const formData = action.payload;
      return state.merge({
        loading: false,
        students: formData,
      });

    case actions.FETCH_STUDENT_SUCCESS:
      const student = action.payload;
      return state.merge({
        loading: false,
        activeStudent: student,
      });

    case actions.FETCH_PROGRESS_REPORT_SUCCESS:
      const progressReport = action.payload;
      return state.merge({
        loading: false,
        activeProgressReport: progressReport,
      });

    case actions.FETCH_CURRENT_COURSE_SUCCESS:
      const currentCourse = action.payload;
      return state.merge({
        loading: false,
        activeCurrentCourse: currentCourse,
      });  

    case actions.FETCH_PROGRESS_REPORTS_SUCCESS:
      const data = action.payload;
      return state.merge({
        loading: false,
        progressReports: data,
      });

    case actions.FETCH_CURRENT_COURSES_SUCCESS:
      const currentCourseData = action.payload;
      console.log("cas", currentCourseData)
      return state.merge({
        loading: false,
        currentCourse: currentCourseData,
      })  

    case (actions.FETCH_STUDENT_FAILED,
    actions.FETCH_PROGRESS_REPORTS_FAILED,
    actions.FETCH_PROGRESS_REPORT_FAILED,
    actions.FETCH_STUDENT_FAILED,
    actions.ADD_PROGRESS_REPORT_FAILED,
    actions.FETCH_CURRENT_COURSE_FAILED,
    actions.FETCH_CURRENT_COURSES_FAILED,
    actions.ADD_CURRENT_COURSE_FAILED):
      return state.setIn(["loading"], false);

    case actions.ADD_PROGRESS_REPORT_SUCCESS:
      const savePRIndex = state.toJS().progressReports.length;
      return state.setIn(["progressReports", savePRIndex], action.payload);

    case actions.ADD_CURRENT_COURSE_SUCCESS:
      const saveCCIndex = state.toJS().currentCourse.length;
      return state.setIn(["currentCourse", saveCCIndex], action.payload);

    case actions.FETCH_ALL_PROGRESS_REPORTS_SUCCESS:
      if (
        Array.isArray(action.payload) &&
        action.payload[0].hasOwnProperty("batch")
      ) {
        const progressReports = action.payload[0].children[0].progressReports;
        return state.setIn(["progressReports"], progressReports);
      } else {
        return state.setIn(["progressReports"], action.payload);
      }

    case actions.FETCH_ALL_CURRENT_COURSES_SUCCESS:
      if (
        Array.isArray(action.payload) &&
        action.payload[0].hasOwnProperty("batch")
      ) {
        const currentCourse = action.payload[0].children[0].currentCourse;
        return state.setIn(["currentCourse"], currentCourse);
      } else {
        return state.setIn(["currentCourse"], action.payload);
      }
      
    case actions.FETCH_MILESTONE_SUCCESS:
      return state.setIn(["milestone"], action.payload);

    default:
      return state;
  }
};

export default reducer;
