import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchPickupPersons = takeLatest(
  actions.FETCH_PICKUP_PERSONS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/pickupperson/byChild", action)
      );
      yield put({
        type: actions.FETCH_PICKUP_PERSONS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const savePickupPerson = takeLatest(
  actions.SAVE_PICKUP_PERSON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(
          api.post,
          "api/pickupperson",
          action.data,
          null
        );
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_PICKUP_PERSON_SUCCESS,
          payload: response,
        });
      }

      if (action.event === "EDIT") {
        const response = yield call(api.put, `api/pickupperson/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_PICKUP_PERSON_SUCCESS,
          payload: response,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const deletePickupPerson = takeLatest(
  actions.DELETE_PICKUP_PERSON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `/api/pickupperson/${action.id}`);
      yield put({
        type: actions.DELETE_PICKUP_PERSON_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, 'success');
    } catch (error: any) {
      yield put({ type: actions.DELETE_PICKUP_PERSON_FAILED });
      const errorMessage = error.response
        ? error.response.data.errors
        : "failed";
      yield call(action.reject, errorMessage);
    }
  }
);

const verifyPickupPerson = takeLatest(
  actions.VERIFY_PICKUP_PERSON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
        const response = yield call(api.put, `api/pickupperson/verifyPickupPerson/${action.id}`, action.data);
        yield put({
          type: actions.VERIFY_PICKUP_PERSON_SUCCESS,
          payload: response
        });
      
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);


export default function* saga() {
  yield all([
    fetchPickupPersons,
    savePickupPerson,
    deletePickupPerson,
    verifyPickupPerson
  ]);
}
