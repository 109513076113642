import BranchSelfServicesSaga from "./sagas";
import BranchSelfServicesReducer from "./reducers";
import branchSelfServicesActions from "./actions";

export const branchSelfServicesReducer = {
  branchSelfServicesReducer: BranchSelfServicesReducer,
};

export const branchSelfServicesSaga = [BranchSelfServicesSaga()];

export { branchSelfServicesActions };
