import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const MiniUserIcon = (props: Partial<CustomIconComponentProps>) => {
  const homeSvg = () => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9983 8.8002C12.8209 8.8002 14.2983 7.32273 14.2983 5.5002C14.2983 3.67766 12.8209 2.2002 10.9983 2.2002C9.1758 2.2002 7.69834 3.67766 7.69834 5.5002C7.69834 7.32273 9.1758 8.8002 10.9983 8.8002Z"
        fill="currentColor"
      />
      <path
        d="M3.81003 15.9431C3.59566 16.5019 3.78712 17.1283 4.25996 17.4952C6.12155 18.94 8.45956 19.8002 10.9985 19.8002C13.54 19.8002 15.8801 18.9383 17.7427 17.4908C18.2153 17.1235 18.4063 16.497 18.1916 15.9384C17.0814 13.0501 14.281 11.0002 11.0017 11.0002C7.72069 11.0002 4.91896 13.0523 3.81003 15.9431Z"
        fill="currentColor"
      />
    </svg>
  );
  return <Icon component={homeSvg} {...props} />;
};
