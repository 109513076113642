import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    leaves: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_LEAVE_REQUESTS_SUCCESS:
      return state.setIn(["leaves"], action.payload);

    case actions.SAVE_LEAVE_REQUEST_SUCCESS:
      let leavesIndex = 0;
      leavesIndex = state.toJS().leaves.length;
      return state.setIn(["leaves", "data", leavesIndex], action.payload);

    case actions.UPDATE_LEAVE_REQUEST_SUCCESS:
      let leaveIndex = 0;
      leaveIndex = state
        .toJS()
        .leaves?.data?.findIndex(
          (leave: any) => leave.id === action.payload.id
        );
      return state.setIn(["leaves", "data", leaveIndex], action.payload);
    default:
      return state;
  }
};

export default reducer;
