import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchAllEmployee = takeLatest(
  actions.FETCH_EMPLOYEE_DETAILS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("/api/timesheetreport/getAllEmployee", action)
      );
      yield put({
        type: actions.FETCH_EMPLOYEE_DETAILS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response)
    } catch (error) {
      yield call(action.reject, error)
    }
  }
);

const fetchAllAttendanceOfEmloyee = takeLatest(
  actions.FETCH_ALL_ATTENDANCE_OF_EMPLOYEE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const { id, filter } = action;
      const response = yield call(api.get, `/api/timesheetreport/attendance/${id}`, false, filter);
      yield put({
        type: actions.FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response)
    } catch (error) {
      yield call(action.reject, error)
    }
  }
);

const fetchEmployeeRole = takeLatest(
  actions.FETCH_EMPLOYEE_ROLE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `/api/timesheetreport/role`, false);
      yield put({
        type: actions.FETCH_EMPLOYEE_ROLE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response)
    } catch (error) {
      yield call(action.reject, error)
    }
  }
);

const updateCheckoutTime = takeLatest(
  actions.UPDATE_CHECKOUT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try { 
      const response = yield call(api.put, `/api/timesheetreport/checkout/${action.data.id}`, action.data);
      
      yield put({
        type: actions.UPDATE_CHECKOUT_SUCCESS,
        payload: action.data,
      });
      action.resolve(response);
    } catch (error) {
      yield put({
        type: actions.UPDATE_CHECKOUT_FAILED,
        payload: error,
      });
      action.reject(error);
    }
  }
);

const addTime = takeLatest(
  actions.ADD_TIME,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try { 
      const response = yield call(api.post, `/api/timesheetreport/addTime`, action.data, null);
      
      yield put({
        type: actions.ADD_TIME_SUCCESS,
        payload: action.data,
      });
      action.resolve(response);
    } catch (error) {
      yield put({
        type: actions.ADD_TIME_FAILED,
        payload: error,
      });
      action.reject(error);
    }
  }
)

export default function* saga() {
  yield all([
    fetchAllEmployee,
    fetchAllAttendanceOfEmloyee,
    fetchEmployeeRole,
    updateCheckoutTime,
    addTime
  ]);
}