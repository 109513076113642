import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    instructors: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_INSTRUCTORS_SUCCESS:
      return state.setIn(["instructors"], action.payload);

    case actions.SAVE_INSTRUCTOR_SUCCESS:
      let instructorsIndex = 0;

      if (action.event === "ADD") {
        instructorsIndex = state.toJS().courses.length;
      }

      if (action.event === "EDIT") {
        instructorsIndex = state
          .toJS()
          .instructors?.data?.findIndex(
            (instructor: any) => instructor.id === action.payload.id
          );
      }

      return state.setIn(
        ["instructors", "data", instructorsIndex],
        action.payload
      );

    case actions.DELETE_INSTRUCTOR_SUCCESS:
      const deleteIndex = state
        .toJS()
        .instructors?.data?.findIndex(
          (instructor: any) => instructor.id === action.id
        );
      return state.deleteIn(["instructors", "data", deleteIndex]);

    default:
      return state;
  }
};

export default reducer;
