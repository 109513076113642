import RemoveChildSaga from "./sagas";
import RemoveChildReducer from "./reducers";
import RemoveChildActions from "./actions";

export const removeChildReducer = {
  removeChildReducer: RemoveChildReducer,
};

export const removeChildSaga = [RemoveChildSaga()];

export { RemoveChildActions };
