import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface PendingStudentsReturnType {
    [x: string]: string;
}

const fetchPendingStudents = takeLatest(
    actions.FETCH_PENDING_STUDENTS,
    function* (
      action: AnyAction
    ): Generator<Effect, void, PendingStudentsReturnType> {
      try {
        const response = yield call(
          api.get,
          urlGenerator("api/pendingStudents", action),
          false
        );
        yield put({
          type: actions.FETCH_PENDING_STUDENTS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.FETCH_PENDING_STUDENTS_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );  

  const updatePendingStudents = takeLatest(
    actions.UPDATE_PENDING_STUDENTS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/pendingStudents/${action.data.id}`,
          action.data
        );
        yield put({
          type: actions.UPDATE_PENDING_STUDENTS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.UPDATE_PENDING_STUDENTS_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

  const removePendingStudent = takeLatest(
    actions.REMOVE_PENDING_STUDENTS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/pendingStudents/removependingstudents/${action.data.id}`, action.data
        );
        yield put({ 
          type: actions.REMOVE_PENDING_STUDENTS_SUCCESS,
          payload: action.data.id,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.REMOVE_PENDING_STUDENTS_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

  export default function* saga() {
    yield all([fetchPendingStudents, updatePendingStudents, removePendingStudent]);
  }  
