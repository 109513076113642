import TrialClassesSaga from "./sagas";
import TrialClassesReducer from "./reducers";
import trialClassesActions from "./actions";

export const trialClassesReducer = {
    trialClassesReducer: TrialClassesReducer,
};

export const trialClassesSaga = [TrialClassesSaga()];

export { trialClassesActions };