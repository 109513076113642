import TimesheetSaga from "./sagas";
import TimesheetReducer from "./reducers";
import timesheetActions from "./actions";

export const timesheetReducer = {
	timesheetReducer: TimesheetReducer,
};

export const timesheetSaga = [TimesheetSaga()];

export { timesheetActions };
