import AllChildSaga from "./sagas"
import AllChildReducer from "./reducers";
import AllChildActions from "./actions";

export const allChildReducer = {
    allChildReducer: AllChildReducer,
};

export const allChildSaga = [AllChildSaga()];

export { AllChildActions };