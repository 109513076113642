import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS({
  loading: false,
  currentUser: {},
});

const reducer = (state = initialState, action: Record<string, string>) => {
  switch (action.type) {
    case actions.LOGIN:
    case actions.FETCH_CURRENT_USER:
      return state.setIn(["loading"], true);

    case actions.STORE_CURRENT_USER:
      const userData = action.payload;
      return state.merge({
        loading: false,
        currentUser: userData,
      });

    case actions.LOGIN_FAILED:
      return state.setIn(["loading"], false);

    case actions.LOGOUT:
      localStorage.removeItem("token");
      return state.remove();

    default:
      return state;
  }
};

export default reducer;
