import StudentPaymentStatusSaga from "./sagas";
import StudentPaymentStatusReducer from "./reducers";
import studentPaymentStatusActions from "./actions";

export const studentPaymentStatusReducer = {
  studentPaymentStatusReducer: StudentPaymentStatusReducer,
};

export const studentPaymentStatusSaga = [StudentPaymentStatusSaga()];

export { studentPaymentStatusActions };
