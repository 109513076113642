import { BranchAdmin } from "../interface/BranchAdmin.interface";

const actions = {
  FETCH_BRANCH_ADMINS: "FETCH_BRANCH_ADMINS",
  FETCH_BRANCH_ADMINS_SUCCESS: "FETCH_BRANCH_ADMINS_SUCCESS",
  FETCH_BRANCH_ADMIN: "FETCH_BRANCH_ADMIN",
  SAVE_BRANCH_ADMIN: "SAVE_BRANCH_ADMIN",
  SAVE_BRANCH_ADMIN_SUCCESS: "SAVE_BRANCH_ADMIN_SUCCESS",
  DELETE_BRANCH_ADMIN: "DELETE_BRANCH_ADMIN",
  DELETE_BRANCH_ADMIN_SUCCESS: "DELETE_BRANCH_ADMIN_SUCCESS",
  DELETE_BRANCH_ADMIN_FAILED: "DELETE_BRANCH_ADMIN_FAILED",

  fetchBranchAdmins: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCH_ADMINS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchBranchAdmin: (
    id: number | "" | undefined,
    resolve: (value: BranchAdmin) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BRANCH_ADMIN,
    id,
    resolve,
    reject,
  }),

  saveBranchAdmin: (
    data: BranchAdmin,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_BRANCH_ADMIN,
    data,
    event,
    resolve,
    reject,
  }),

  deleteBranchAdmin: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_BRANCH_ADMIN,
    id,
    resolve,
    reject,
  }),
};

export default actions;
