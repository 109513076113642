import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { getUserId } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchAllChild = takeLatest(
  actions.FETCH_ALLCHILD,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const { id } = action;
      const parentId = id ? parseInt(id) : getUserId(); 
      const enroll_response = yield call(
        api.get,
        `api/child?parent_id=${parentId}`
      );
      const others_response = yield call(
        api.get,
        `api/child/comingChild/${parentId}`
      );
      const combinedres = {
        enrolled: enroll_response,
        others: others_response
      }
      yield put({
        type: actions.FETCH_ALLCHILD_SUCCESS,
        payload: combinedres,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchOneChild = takeLatest(
  actions.FETCH_ONECHILD,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `api/child/getOneChild/` + action.payload.id , false
      );
      yield put({
        type: actions.FETCH_ONECHILD_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([fetchAllChild, fetchOneChild]);
}