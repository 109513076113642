import { InputNumber, InputNumberProps } from "antd";
import React from "react";
import { tokens } from "utils/Constants";

interface AInputNumberProps extends InputNumberProps {
  mode?: "clock";
}

function AInputNumber({ mode, ...others }: AInputNumberProps) {
  return (
    <InputNumber
      style={
        mode
          ? {
              borderColor: tokens.COLOR_PRIMARY,
              fontSize: "16px",
            }
          : {
              width: "100%",
              fontSize: "16px",
            }
      }
      {...others}
    />
  );
}

export default AInputNumber;
