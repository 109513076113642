import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const updatePassword = takeLatest(
  actions.UPDATE_PASSWORD,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      yield call(api.put, `/api/settings`, action.data);
      yield put({
        type: actions.UPDATE_PASSWORD_SUCCESS,
        payload: action.data,
        event: action.event,
      });
      yield call(action.resolve, "Success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

export default function* saga() {
  yield all([updatePassword]);
}
