import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const notificationIcon = () =>{
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.95143 2.1504V0.699999C4.95143 0.3136 5.26503 0 5.65143 0C6.03713 0 6.35143 0.3136 6.35143 0.699999V2.1504C7.39163 2.3002 8.36393 2.7832 9.11573 3.535C10.0348 4.45409 10.5514 5.70079 10.5514 6.99999V9.63479L11.1177 10.7674C11.3886 11.3099 11.3599 11.9539 11.0407 12.4698C10.7222 12.9857 10.1587 13.3 9.55253 13.3H6.35143C6.35143 13.6864 6.03713 14 5.65143 14C5.26503 14 4.95143 13.6864 4.95143 13.3H1.75034C1.14344 13.3 0.579951 12.9857 0.261452 12.4698C-0.0577478 11.9539 -0.0864539 11.3099 0.185146 10.7674L0.751438 9.63479V6.99999C0.751438 5.70079 1.26735 4.45409 2.18645 3.535C2.93895 2.7832 3.91053 2.3002 4.95143 2.1504ZM5.65143 3.49999C4.72253 3.49999 3.83285 3.8689 3.17625 4.52549C2.51965 5.18139 2.15144 6.07179 2.15144 6.99999V9.79999C2.15144 9.90849 2.12555 10.0156 2.07725 10.1129C2.07725 10.1129 1.76574 10.7359 1.43674 11.3932C1.38284 11.5017 1.38844 11.6312 1.45214 11.7341C1.51584 11.837 1.62854 11.9 1.75034 11.9H9.55253C9.67362 11.9 9.78634 11.837 9.85004 11.7341C9.91374 11.6312 9.91934 11.5017 9.86544 11.3932C9.53644 10.7359 9.22493 10.1129 9.22493 10.1129C9.17663 10.0156 9.15143 9.90849 9.15143 9.79999V6.99999C9.15143 6.07179 8.78253 5.18139 8.12593 4.52549C7.46933 3.8689 6.57963 3.49999 5.65143 3.49999Z" />
    </svg>
  )
}


export const NotificationIcon = (props: Partial<CustomIconComponentProps>) =>{
  return (
   <Icon component={notificationIcon} {...props}/>
  )
}

