const actions = {
    CREATE_MAILING_ADDRESS: "CREATE_MAILING_ADDRESS",
    CREATE_MAILING_ADDRESS_SUCCESS: "CREATE_MAILING_ADDRESS_SUCCESS",
    CREATE_MAILING_ADDRESS_FAILED: "CREATE_MAILING_ADDRESS_FAILED",
    FETCH_EXISITNG_USER: "FETCH_EXISITNG_USER",
    FETCH_EXISITNG_USER_SUCCESS: "FETCH_EXISITNG_USER_SUCCESS",
    FETCH_EXISITNG_USER_FAILED: "FETCH_EXISITNG_USER_FAILED",
    SEND_MAIL_FOR_FURTHER_PROCESSING: "SEND_MAIL_FOR_FURTHER_PROCESSING",
    SEND_MAIL_FOR_FURTHER_PROCESSING_SUCCESS: "SEND_MAIL_FOR_FURTHER_PROCESSING_SUCCESS",
    SEND_MAIL_FOR_FURTHER_PROCESSING_FAILED: "SEND_MAIL_FOR_FURTHER_PROCESSING_FAILED",
    FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION: "FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION",
    FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION_SUCCESS: "FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION_SUCCESS",
    FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION_FAILED: "FETCH_EXISITNG_USER_FAILED",

    creatingMailingAddress: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.CREATE_MAILING_ADDRESS,
        data,
        resolve,
        reject
    }),

    fetchExisitngUser: (
        email: any,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_EXISITNG_USER,
        email,
        resolve,
        reject
    }),

    sendMailForFurtherProcessing: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.SEND_MAIL_FOR_FURTHER_PROCESSING,
        data,
        resolve,
        reject
    }),

    fetchExisitngUserWithoutSubscription: (
        email: any,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION,
        email,
        resolve,
        reject
    }),
}

export default actions;