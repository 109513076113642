import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import { getUserId } from "utils/Helpers";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchCourseInfo = takeLatest(
  actions.FETCH_COURSEINFO,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `api/course/${action.id ? action.id : getUserId()}`
      );
      yield put({
        type: actions.FETCH_COURSEINFO_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchBranchReportInfo = takeLatest(
  actions.FETCH_BRANCHREPORT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `api/dashboardReport/branchReport/${action.id}/${action.year ? action.year : new Date().getFullYear()}`
      );
      yield put({
        type: actions.FETCH_BRANCHREPORT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchYears = takeLatest(
  actions.FETCH_YEAR,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, "/api/dashboardReport/getYear");
      yield put({
        type: actions.FETCH_YEAR_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);


export default function* saga() {
  yield all([fetchCourseInfo, fetchBranchReportInfo, fetchYears]);
}
