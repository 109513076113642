import { Map } from "immutable";
import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    userDetails: {},
  })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
  }

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_USER_DETAILS_SUCCESS:
      return state.setIn(["userDetails"], action.payload);

    // case actions.UPDATE_ENROLLMENT_SUCCESS:
    //   const updatedEntry = action.payload;
    //   const enrollmentIndex = state
    //     .toJS()
    //     .userDetails?.findIndex(
    //       (userDetail: any) => userDetail.id === action.payload.id
    //     );
    //   return state.setIn(["userDetails", enrollmentIndex], updatedEntry)
      
    default:
      return state;
  }
};

export default reducer;
