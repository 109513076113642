import CreditAndDiscountsSaga from "./sagas"
import CreditAndDiscountsReducer from "./reducers";
import creditAndDiscountsActions from "./actions";

export const creditAndDiscountsReducer = {
    creditAndDiscountsReducer: CreditAndDiscountsReducer,
};

export const creditAndDiscountsSaga = [CreditAndDiscountsSaga()];

export { creditAndDiscountsActions };