import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect, takeEvery } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}
const fetchCoursesByInstructor = takeLatest(
  actions.FETCH_CHILD_BYINSTRUCTOR,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/child/byinstructor", action)
      );
      yield put({
        type: actions.FETCH_CHILD_BYINSTRUCTOR_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchAssignments = takeEvery(
  actions.FETCH_ASSIGNMENTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/assignment", action)
      );
      yield put({
        type: actions.FETCH_ASSIGNMENTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveAssignment = takeLatest(
  actions.SAVE_ASSIGNMENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/assignment/add`,
        action.data,
        null
      );

      yield put({
        type: actions.SAVE_ASSIGNMENT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const submitAssignment = takeLatest(
  actions.SUBMIT_ASSIGNMENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/assignment/submit/${action.data.id}`,
        action.data
      );
      yield put({ type: actions.SUBMIT_ASSIGNMENT_SUCCESS, payload: response });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);
export default function* saga() {
  yield all([
    fetchCoursesByInstructor,
    saveAssignment,
    fetchAssignments,
    submitAssignment,
  ]);
}
