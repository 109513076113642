import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        surveyAnswers: [],
        parentDetails: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_SURVEYANSWERS:
            return state.setIn(["loading"], true);

        case actions.FETCH_SURVEYANSWERS_SUCCESS:
            const surveyAnswers = action.payload;
            return state.merge({
                loading: false,
                surveyAnswers: surveyAnswers
            });

        case actions.FETCH_SURVEYANSWERS_FAILED:
            return state.setIn(["loading"], false);

        case actions.FETCH_PARENTDETAILS:
            return state.setIn(["loading"], true);
        
        case actions.FETCH_PARENTDETAILS_SUCCESS:
            const parentDetails = action.payload;
            return state.merge({
                loading: false,
                parentDetails: parentDetails
            });
            
        case actions.FETCH_PARENTDETAILS_FAILED:
            return state.setIn(["loading"], false);
            
        default:
            return state;    
    }
};

export default reducer;