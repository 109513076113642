import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const paperAirplaneIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.44865 11.0001L3.94531 2.86426C10.0092 4.62577 15.6409 7.40313 20.6439 10.9999C15.6409 14.5967 10.0092 17.3741 3.9454 19.1357L6.44865 11.0001ZM6.44865 11.0001L13.3239 11.0001"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PaperAirplaneIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={paperAirplaneIcon} {...props} />;
};
