import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api()

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object; 
}

interface MaillingAddressReturnType {
    [x: string]: string;
}

const createMailingAddress = takeLatest(
    actions.CREATE_MAILING_ADDRESS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.post,
                `api/enrollmentWithoutId/mailing-address`,
                action.data,
                null
            );
            yield put({
                type: actions.CREATE_MAILING_ADDRESS_SUCCESS,
                payload: response,
            })
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.CREATE_MAILING_ADDRESS_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

const fetchExisitngUser = takeLatest(
    actions.FETCH_EXISITNG_USER,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.post,
                `api/enrollmentWithoutId/check-exisiting-user`,
                action,
                null
            );
            yield put({
                type: actions.FETCH_EXISITNG_USER_SUCCESS,
                payload: response,
            })
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_EXISITNG_USER_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

const fetchExisitngUserWithoutSubscription = takeLatest(
    actions.FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.post,
                `api/enrollmentWithoutId/check-exisiting-user-without-subscription`,
                action,
                null
            );
            yield put({
                type: actions.FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION_SUCCESS,
                payload: response,
            })
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_EXISITNG_USER_WITHOUT_SUBSCRIPTION_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

const sendMailForFurtherProcessing = takeLatest(
    actions.SEND_MAIL_FOR_FURTHER_PROCESSING,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try{
            const response = yield call(
                api.post,
                `api/enrollmentWithoutId/send-email`,
                action.data,
                null
            );
            yield put({
                type: actions.SEND_MAIL_FOR_FURTHER_PROCESSING_SUCCESS,
                payload: response,
            })
            yield call(action.resolve, response)
        } catch (error) {
            yield put({ type: actions.SEND_MAIL_FOR_FURTHER_PROCESSING_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

export default function* saga() {
    yield all([createMailingAddress, fetchExisitngUser, sendMailForFurtherProcessing, fetchExisitngUserWithoutSubscription]);
}