import React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const settingIcon = () =>{
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.68601 2.9994L10.293 0.392396C10.4805 0.204925 10.7348 0.0996094 11 0.0996094C11.2652 0.0996094 11.5195 0.204925 11.707 0.392396L14.314 2.9994H18C18.2652 2.9994 18.5196 3.10475 18.7071 3.29229C18.8946 3.47983 19 3.73418 19 3.9994V7.6854L21.607 10.2924C21.7945 10.4799 21.8998 10.7342 21.8998 10.9994C21.8998 11.2646 21.7945 11.5189 21.607 11.7064L19 14.3134V17.9994C19 18.2646 18.8946 18.519 18.7071 18.7065C18.5196 18.894 18.2652 18.9994 18 18.9994H14.314L11.707 21.6064C11.5195 21.7939 11.2652 21.8992 11 21.8992C10.7348 21.8992 10.4805 21.7939 10.293 21.6064L7.68601 18.9994H4.00001C3.73479 18.9994 3.48044 18.894 3.2929 18.7065C3.10536 18.519 3.00001 18.2646 3.00001 17.9994V14.3134L0.393006 11.7064C0.205535 11.5189 0.10022 11.2646 0.10022 10.9994C0.10022 10.7342 0.205535 10.4799 0.393006 10.2924L3.00001 7.6854V3.9994C3.00001 3.73418 3.10536 3.47983 3.2929 3.29229C3.48044 3.10475 3.73479 2.9994 4.00001 2.9994H7.68601ZM5.00001 4.9994V8.5144L2.51501 10.9994L5.00001 13.4844V16.9994H8.51501L11 19.4844L13.485 16.9994H17V13.4844L19.485 10.9994L17 8.5144V4.9994H13.485L11 2.5144L8.51501 4.9994H5.00001ZM11 14.9994C9.93914 14.9994 8.92173 14.578 8.17158 13.8278C7.42143 13.0777 7.00001 12.0603 7.00001 10.9994C7.00001 9.93853 7.42143 8.92111 8.17158 8.17097C8.92173 7.42082 9.93914 6.9994 11 6.9994C12.0609 6.9994 13.0783 7.42082 13.8284 8.17097C14.5786 8.92111 15 9.93853 15 10.9994C15 12.0603 14.5786 13.0777 13.8284 13.8278C13.0783 14.578 12.0609 14.9994 11 14.9994ZM11 12.9994C11.5304 12.9994 12.0391 12.7887 12.4142 12.4136C12.7893 12.0385 13 11.5298 13 10.9994C13 10.469 12.7893 9.96025 12.4142 9.58518C12.0391 9.21011 11.5304 8.9994 11 8.9994C10.4696 8.9994 9.96087 9.21011 9.58579 9.58518C9.21072 9.96025 9.00001 10.469 9.00001 10.9994C9.00001 11.5298 9.21072 12.0385 9.58579 12.4136C9.96087 12.7887 10.4696 12.9994 11 12.9994Z" />
    </svg>
  )
}

export const SettingIcon = (props: Partial<CustomIconComponentProps>) =>{
  return (
   <Icon component={settingIcon} {...props}/>
  )
}