import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS, Map } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    branches: [],
    activeBranch: {},
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.CREATE_BRANCH_SUCCESS:
      const saveBranchIndex = state.toJS().branches.length;
      return state.setIn(["branches", saveBranchIndex], action.payload);

    case (actions.FETCH_BRANCHES, actions.FETCH_BRANCH):
      return state.setIn(["loading"], true);

    case actions.FETCH_BRANCH_SUCCESS:
      return state.merge({
        loading: false,
        activeBranch: action.payload,
      });

    case actions.FETCH_BRANCHES_SUCCESS:
      const branches = action.payload;
      return state.merge({
        loading: false,
        branches: branches,
      });

    case (actions.FETCH_BRANCHES_FAILED, actions.CREATE_BRANCH_FAILED):
      return state.setIn(["loading"], false);

    case actions.UPDATE_BRANCH_SUCCESS:
      const updatedEntry = action.payload;
      const branchIndex = state
        .toJS()
        .branches?.data.findIndex(
          (branch: any) => branch.id === action.payload.id
        );
      return state.setIn(["branches", "data", branchIndex], updatedEntry);

    case actions.DELETE_BRANCH_SUCCESS:
      const delBranchIndex = state
        .toJS()
        .branches?.data.findIndex(
          (branch: any) => branch.id === action.payload
        );

      return state.deleteIn(["branches", "data", delBranchIndex]);

    default:
      return state;
  }
};

export default reducer;
