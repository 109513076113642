import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    branchAssociates: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_BRANCH_ASSOCIATES_SUCCESS:
      return state.setIn(["branchAssociates"], action.payload);

    case actions.SAVE_BRANCH_ASSOCIATE_SUCCESS:
      let branchAssociatesIndex = 0;

      if (action.event === "ADD") {
        branchAssociatesIndex = state.toJS().courses.length;
      }

      if (action.event === "EDIT") {
        branchAssociatesIndex = state
          .toJS()
          .branchAssociates.data?.findIndex(
            (branchAssociate: any) => branchAssociate.id === action.payload.id
          );
      }

      return state.setIn(
        ["branchAssociates", "data", branchAssociatesIndex],
        action.payload
      );

    case actions.DELETE_BRANCH_ASSOCIATE_SUCCESS:
      const deleteIndex = state
        .toJS()
        .branchAssociates.data?.findIndex(
          (branchAssociate: any) => branchAssociate.id === action.id
        );
      return state.deleteIn(["branchAssociates", "data", deleteIndex]);

    default:
      return state;
  }
};

export default reducer;
