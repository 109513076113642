import AppointmentSaga from "./sagas";
import AppointmentReducer from "./reducers";
import appointmentActions from "./actions";

export const appointmentReducer = {
    appointmentReducer: AppointmentReducer,
};

export const appointmentSaga = [AppointmentSaga()];

export { appointmentActions };