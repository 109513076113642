import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchSuperAdmins = takeLatest(
  actions.FETCH_SUPERADMINS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/superadmin", action)
      );
      yield put({
        type: actions.FETCH_SUPERADMINS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchSuperAdmin = takeLatest(
  actions.FETCH_SUPERADMIN,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `/api/superadmin/${action.id}`);
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveSuperAdmin = takeLatest(
  actions.SAVE_SUPERADMIN,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(
          api.post,
          "/api/superadmin",
          action.data,
          null
        );
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_SUPERADMIN_SUCCESS,
          payload: response.data,
          event: response.event,
        });
      }

      if (action.event === "EDIT") {
        yield call(api.put, `/api/superadmin/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_SUPERADMIN_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const deleteSuperAdmin = takeLatest(
  actions.DELETE_SUPERADMIN,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(api.delete, `/api/superadmin/${action.id}`);
      yield put({
        type: actions.DELETE_SUPERADMIN_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error: any) {
      yield put({ type: actions.DELETE_SUPERADMIN_FAILED });
      const errorMessage = error.response
        ? error.response.data.errors
        : "failed";
      yield call(action.reject, errorMessage);
    }
  }
);

export default function* saga() {
  yield all([
    fetchSuperAdmins,
    fetchSuperAdmin,
    saveSuperAdmin,
    deleteSuperAdmin,
  ]);
}
