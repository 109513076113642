import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface BranchReturnType {
  [x: string]: string;
}

const createBranch = takeLatest(
  actions.CREATE_BRANCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.post, `api/branch`, action.data, null);
      yield put({
        type: actions.CREATE_BRANCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      console.log(error);
      yield put({ type: actions.CREATE_BRANCH_FAILED });
      yield call(action.reject, error);
    }
  }
);

const fetchBranches = takeLatest(
  actions.FETCH_BRANCHES,
  function* (action: AnyAction): Generator<Effect, void, BranchReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/branch", action),
        false
      );
      yield put({
        type: actions.FETCH_BRANCHES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_BRANCHES_FAILED });
      yield call(action.reject);
    }
  }
);

const fetchBranch = takeLatest(
  actions.FETCH_BRANCH,
  function* (action: AnyAction): Generator<Effect, void, BranchReturnType> {
    try {
      const response = yield call(api.get, `api/branch/${action.id}`, false);
      yield put({
        type: actions.FETCH_BRANCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_BRANCH_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateBranch = takeLatest(
  actions.UPDATE_BRANCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/branch/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_BRANCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.UPDATE_BRANCH_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const deleteBranch = takeLatest(
  actions.DELETE_BRANCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/branch/${action.id}`);
      yield put({
        type: actions.DELETE_BRANCH_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.DELETE_BRANCH_FAILED });
      yield call(action.reject, error.response.data.errors);
    }
  }
);

export default function* saga() {
  yield all([
    createBranch,
    fetchBranches,
    updateBranch,
    deleteBranch,
    fetchBranch,
  ]);
}
