import React from "react";
import { PublicRoutes } from "./interface/PublicRoutes.interface";

const publicRoutes: Array<PublicRoutes> = [
  {
    path: "/resetPassword/:token",
    Component: React.lazy(() => import("pages/resetPassword")),
  },
  {
    path: "/setPassword/:token",
    Component: React.lazy(() => import("pages/oneTimeLogin")),
  },
  {
    path: "/login",
    Component: React.lazy(() => import("pages/login")),
  },
  {
    path: "/forgotpassword",
    Component: React.lazy(() => import("pages/forgotPassword")),
  },
  {
    path: "/privacy-policy",
    Component: React.lazy(() => import("pages/privacyPolicy")),
  },
  {
    path: "/book-a-trial",
    Component: React.lazy(() => import("pages/bookATrialClass")),
  },
  {
    path: "/book-a-trial/:buttonId/:trialClassId",
    Component: React.lazy(() => import("pages/bookATrialClass")),
  },
  {
    path: "/book-an-appointment",
    Component: React.lazy(() => import("pages/bookAnAppointment")),
  },
  {
    path: "/book-an-appointment/:buttonId/:appointmentId",
    Component: React.lazy(() => import("pages/bookAnAppointment")),
  },
  {
    path: "/removefromwaitinglist/:id",
    Component: React.lazy(() => import("pages/waitingList")),
  },
  {
    path: "/",
    Component: React.lazy(() => import("pages/website")),
  },
  {
    path: "*",
    Component: React.lazy(() => import("pages/notFound")),
  },
];

export default publicRoutes;
