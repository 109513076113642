import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        parentAndChildAssociation: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_PARENT_DETAILS:
            return state.setIn(["loading"], true);

        case actions.FETCH_PARENT_DETAILS_SUCCESS:
            const parentDetails = action.payload;
            return state.merge({
                loading: false,
                parentDetails: parentDetails,
            })

        case actions.FETCH_PARENT_DETAILS_FAILED:
            return state.setIn(["loading"], false)

        case actions.FETCH_SINGLE_PARENT_DETAIL:
            return state.setIn(["loading"], true);

        case actions.FETCH_SINGLE_PARENT_DETAIL_SUCCESS:
            const singleParentDetails = action.payload;
            return state.merge({
                loading: false,
                singleParentDetails: singleParentDetails,
            })

        case actions.FETCH_SINGLE_PARENT_DETAIL_FAILED:
            return state.setIn(["loading"], false)
         
        case actions.REMOVE_CHILD_SUCCESS:
            let updateRemoveChildIndex = 0;
            updateRemoveChildIndex = state.toJS()
            .parentDetails?.data.findIndex(
                (parentDetail: any) => 
                    parentDetail.child_id === action.payload.id
                )

            return state.deleteIn(
                ["parentDetails", "data", updateRemoveChildIndex],
                action.payload
            )    

        default:
            return state;
    }
}

export default reducer;