import ChildDetailsSaga from "./sagas";
import ChildDetailsReducer from "./reducers";
import childDetailsActions from "./actions";

export const childDetailsReducer = {
    childDetailsReducer: ChildDetailsReducer,
};

export const childDetailsSaga = [ChildDetailsSaga()];

export { childDetailsActions };