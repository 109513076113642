import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface EvaluationPlacementFormsReturnType {
  [x: string]: string;
}

const fetchStudentPayments = takeLatest(
  actions.FETCH_STUDENT_PAYMENTS_STATUS,
  function* (
    action: AnyAction
  ): Generator<Effect, void, EvaluationPlacementFormsReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("/api/payment/studentPaymentStatus", action),
        false
      );
      yield put({
        type: actions.FETCH_STUDENT_PAYMENTS_STATUS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([fetchStudentPayments]);
}
