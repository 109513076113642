import BranchAssociatesSaga from "./sagas";
import BranchAssociatesReducer from "./reducers";
import branchAssociatesActions from "./actions";

export const branchAssociatesReducer = {
  branchAssociatesReducer: BranchAssociatesReducer,
};

export const branchAssociatesSaga = [BranchAssociatesSaga()];

export { branchAssociatesActions };
