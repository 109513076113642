import { Avatar, Card, Drawer, Menu, Space } from "antd";
import { useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { MenuItems, SidebarProps } from "../interface/MainLayout.interface";
import { UserDeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { CircleIcon, HomeIcon, UserIcon, CuriculumIcon } from "assets";
import logo from "assets/images/Logo.png";
import sloganlogo from "assets/images/sloganlogo.png";
import { tokens } from "utils/Constants";
import { BankNotesIcon } from "assets/icons/BankNotesIcon";
import { CalenderIcon } from "assets/icons/calenderIcon";
import {
  generateLetterImageURL,
  getInfo,
  getUserDeviceResolution,
  getUserInfo,
} from "utils/Helpers";
import { AText, ATitle } from "components";
import { useState, useEffect } from "react";
import Meta from "antd/es/card/Meta";
import ProfileCard from "components/ProfileCard";
import { JWTToken } from "utils/interface/Utils.interface";

interface AMenuItem extends MenuItems {
  type?: "group";
}

type KeyLabelObject = {
  [key: string]: React.ReactNode;
};
var keyLabelObject: KeyLabelObject = {};
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  href?: String,
  children?: AMenuItem[],
  type?: "group"
): AMenuItem {
  keyLabelObject[key] = label;
  return {
    key,
    icon,
    href,
    children,
    label,
    type,
  } as AMenuItem;
}

const items: AMenuItem[] = [
  getItem("Dashboards", "1", <HomeIcon style={{ width: "12pt" }} />, "/"),
  getItem("Child", "7", <UserIcon style={{ width: "12pt" }} />, "/child"),
  getItem(
    "Leave Request",
    "12",
    <CalenderIcon style={{ width: "12pt" }} />,
    "/leaveRequest"
  ),
  getItem(
    "CourseInfo",
    "19",
    <CuriculumIcon style={{ width: "12pt" }} />,
    "/course"
  ),
  getItem(
    "Billing & Payment",
    "18",
    <BankNotesIcon style={{ width: "12pt" }} />,
    "/bilingandpayments"
  ),
  getItem(
    "Communication",
    "10",
    <CuriculumIcon style={{ width: "12pt" }} />,
    "/chat",
    [
      getItem("Chat", "20", <CircleIcon style={{ width: "12pt" }} />, "/chat"),
      getItem(
        "News & Events",
        "25",
        <CircleIcon style={{ width: "12pt" }} />,
        "/newsAndEvents"
      ),
      getItem(
        "Book An Appointment",
        "27",
        <CircleIcon style={{ width: "12pt" }} />,
        "/book-an-appointment"
      ),
    ]
  ),
  getItem(
    "Pick-Up Person",
    "28",
    <UserAddOutlined style={{ width: "12pt" }} />,
    "/pickupPerson"
  ),
  getItem(
    "Remove Child",
    "26",
    <UserDeleteOutlined style={{ width: "12pt" }} />,
    "/removeChild"
  ),
];

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { open, onClose, onMobile } = props;
  const navigate = useNavigate();
  const [user, setUser] = useState<JWTToken>();

  useEffect(() => {
    const userInfo = getInfo();
    setUser(userInfo);
  }, []);

  const onClick = ({ key, item }: any) => {
    const label = keyLabelObject[key];
    navigate(item.props.href, { state: { label: label, user: user } });
    onMobile && onClose(false);
  };

  // const token: string | null = localStorage.getItem("token");
  // const decodedToken: JWTToken = jwt_decode(token || "");

  // const isAuthorized = (permission: string): boolean => {
  //   return true;
  //   // return permissionData(decodedToken).some(element => {
  //   // 	return permission === element;
  //   // });
  // };

  const content = onMobile ? (
    <Drawer
      placement="left"
      open={open}
      closable={true}
      width={getUserDeviceResolution().width - 80}
      onClose={() => onClose(false)}
      style={{
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"} style={{
          marginTop: "-30px",
          marginBottom: "-1rem",
          width: "18rem"
          }} />
      </div>
      <ProfileCard user={user} />
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        style={{
          overflowX: "hidden",
          width: "max-content !important",
          border: "none",
        }}
      />
    </Drawer>
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={open}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        zIndex: "5",
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: tokens.BG_LIGHT,
        // backgroundColor: "#f5f5f5f5 !important",
        // borderRight: tokens.COLOR_LIGHTBLACK_L50 + " 1px solid",
        width: "max-content",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0.5rem",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"} 
          style={{
            marginBottom: "-1rem",
            width: "14rem"
          }}
        />
      </div>
      <ProfileCard user={user} />
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        style={{
          width: "max-content !important",
          border: "none",
        }}
      />
    </Sider>
  );

  return <>{content}</>;
};

export default Sidebar;
