import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchEvents = takeLatest(
  actions.FETCH_EVENTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, "api/event");
      yield put({
        type: actions.FETCH_EVENTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchEvent = takeLatest(
  actions.FETCH_EVENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `api/event/${action.id}`);
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveEvent = takeLatest(
  actions.SAVE_EVENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(api.post, "api/event", action.data, null);
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_EVENT_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }

      if (action.event === "EDIT") {
        yield call(api.put, `api/event/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_EVENT_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const deleteEvent = takeLatest(
  actions.DELETE_EVENT,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(api.delete, `api/event/${action.id}`);
      yield put({
        type: actions.DELETE_EVENT_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchAllNews = takeLatest(
  actions.FETCH_ALL_NEWS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, "api/news");
      yield put({
        type: actions.FETCH_ALL_NEWS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchNews = takeLatest(
  actions.FETCH_NEWS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `api/news/${action.id}`);
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveNews = takeLatest(
  actions.SAVE_NEWS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(api.post, "api/news", action.data, null);
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_NEWS_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }

      if (action.event === "EDIT") {
        yield call(api.put, `api/news/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_NEWS_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const deleteNews = takeLatest(
  actions.DELETE_NEWS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(api.delete, `api/news/${action.id}`);
      yield put({
        type: actions.DELETE_NEWS_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchEvents,
    fetchEvent,
    saveEvent,
    deleteEvent,
    fetchAllNews,
    fetchNews,
    saveNews,
    deleteNews,
  ]);
}
