const actions = {
    FETCH_PENDING_STUDENTS: "FETCH_PENDING_STUDENTS",
    FETCH_PENDING_STUDENTS_SUCCESS: "FETCH_PENDING_STUDENTS_SUCCESS",
    FETCH_PENDING_STUDENTS_FAILED: "FETCH_PENDING_STUDENTS_FAILED",
    UPDATE_PENDING_STUDENTS: "UPDATE_PENDING_STUDENTS",
    UPDATE_PENDING_STUDENTS_SUCCESS: "UPDATE_PENDING_STUDENTS_SUCCESS",
    UPDATE_PENDING_STUDENTS_FAILED: "UPDATE_PENDING_STUDENTS_FAILED",
    REMOVE_PENDING_STUDENTS: "REMOVE_PENDING_STUDENTS",
    REMOVE_PENDING_STUDENTS_SUCCESS: "REMOVE_PENDING_STUDENTS_SUCCESS",
    REMOVE_PENDING_STUDENTS_FAILED: "REMOVE_PENDING_STUDENTS_FAILED",

    fetchPendingStudents: (
        // page: number,
        // pageSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_PENDING_STUDENTS,
        // page,
        // pageSize,
        resolve,
        reject
    }),

    updatePendingStudents: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.UPDATE_PENDING_STUDENTS,
        data,
        resolve,
        reject,
    }),

    removePendingStudent: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.REMOVE_PENDING_STUDENTS,
        data,
        resolve,
        reject
    })
}

export default actions;