import EvaluationPlacementsSaga from "./sagas";
import EvaluationPlacementsReducer from "./reducers";
import evaluationPlacementsActions from "./actions";

export const evaluationPlacementsReducer = {
	evaluationPlacementsReducer: EvaluationPlacementsReducer,
};

export const evaluationPlacementsSaga = [EvaluationPlacementsSaga()];

export { evaluationPlacementsActions };
