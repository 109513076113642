import { CustomerSupportUser } from "../interface/CustomerSupportUser.interface"

const actions = {
    FETCH_CUSTOMER_SUPPORT_USERS: "FETCH_CUSTOMER_SUPPORT_USERS",
    FETCH_CUSTOMER_SUPPORT_USERS_SUCCESS: "FETCH_CUSTOMER_SUPPORT_USERS_SUCCESS",
    FETCH_CUSTOMER_SUPPORT_USERS_FAILED: "FETCH_CUSTOMER_SUPPORT_USERS_FAILED",
    FETCH_CUSTOMER_SUPPORT_USER: "FETCH_CUSTOMER_SUPPORT_USER",
    FETCH_CUSTOMER_SUPPORT_USER_SUCCESS: "FETCH_CUSTOMER_SUPPORT_USER_SUCCESS",
    FETCH_CUSTOMER_SUPPORT_USER_FAILED: "FETCH_CUSTOMER_SUPPORT_USER_FAILED",
    SAVE_CUSTOMER_SUPPORT_USER: "SAVE_CUSTOMER_SUPPORT_USER",
    SAVE_CUSTOMER_SUPPORT_USER_SUCCESS: "SAVE_CUSTOMER_SUPPORT_USER_SUCCESS",
    SAVE_CUSTOMER_SUPPORT_USER_FAILED: "SAVE_CUSTOMER_SUPPORT_USER_FAILED",
    DELETE_CUSTOMER_SUPPORT_USER: "DELETE_CUSTOMER_SUPPORT_USER",
    DELETE_CUSTOMER_SUPPORT_USER_SUCCESS: "DELETE_CUSTOMER_SUPPORT_USER_SUCCESS",
    DELETE_CUSTOMER_SUPPORT_USER_FAILED: "DELETE_CUSTOMER_SUPPORT_USER_FAILED",

    fetchCustomerSupportUsers: (
        // page: number,
        // pageSize: number,
        resolve: (token: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_CUSTOMER_SUPPORT_USERS,
        // page,
        // pageSize,
        resolve,
        reject,
    }),

    fetchCustomerSupportUser : (
        id: number | " " | undefined,
        resolve: (value: CustomerSupportUser) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_CUSTOMER_SUPPORT_USER,
        id,
        resolve,
        reject,
    }),

    saveCustomerSupportUser: (
        data: CustomerSupportUser,
        event: string,
        resolve: (value: string) => void,
        reject: (value: string) => void, 
    ) => ({
        type: actions.SAVE_CUSTOMER_SUPPORT_USER,
        data,
        event,
        resolve,
        reject,
    }),

    deleteCustomerSupportUser: (
        id: number | " " | undefined,
        resolve: (value: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.DELETE_CUSTOMER_SUPPORT_USER,
        id,
        resolve,
        reject,
    }),
}

export default actions;