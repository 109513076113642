import { Table, TableProps } from "antd";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tokens } from "utils/Constants";
import ATable from ".";

interface ATableProps extends TableProps<any> {
  onRowClick?: (key: any) => void;
  data: any;
  fetchDataAction?: string;
  searchValue?: any;
  filters?: string[] | null;
}

function PaginationTable({
  onRowClick,
  fetchDataAction,
  data,
  searchValue,
  columns,
  filters,
  ...others
}: ATableProps) {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [offset, setOffset] = useState(10);
  const searchKey =
    columns &&
    columns
      .filter((column) => column.key) // Filter out the objects without a "key" property
      .map((column) => column.key);

  const dispatch = useDispatch();
  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  const handleChange: TableProps<any>["onChange"] = (
    pagination,
    // filters,
    sorter
  ) => {
    if (pagination) {
      setOffset(pagination.pageSize || 10);
      setPage(pagination.current || 1);
      new Promise((resolve, reject) => {
        setIsLoading(true);
        fetchDataAction &&
          dispatch({
            type: fetchDataAction,
            page: pagination.current || 1,
            pageSize: pagination.pageSize || 10,
            search: encodeURIComponent(searchValue),
            searchKey,
            filters,
            resolve,
            reject,
          });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const handleSearch = () => {
    setSearching(true);
    if (searching) {
      new Promise((resolve, reject) => {
        setIsLoading(true);
        fetchDataAction &&
          dispatch({
            type: fetchDataAction,
            page: 1,
            pageSize: 10,
            search: encodeURIComponent(searchValue),
            searchKey,
            resolve,
            reject,
          });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const updatedColumns = columns?.map((column, index) => {
    if (column.title === "S.N.") {
      return {
        ...column,
        render: (text: any, record: any, dataIndex: number) => {
          return (page - 1) * offset + dataIndex + 1;
        },
      };
    }
    return column;
  });

  return (
    <div
      style={{
        backgroundColor: tokens.BG_LIGHT,
        borderRadius: "6px",
        margin: "10px 0",
        padding: "16px",
      }}
    >
      <ATable
        columns={updatedColumns}
        loading={isLoading}
        style={{ cursor: "pointer" }}
        onChange={handleChange}
        dataSource={data?.data}
        pagination={{
          current: page,
          total: data?.totalCount,
        }}
        scroll={{ x: "max-content" }}
        onRow={(record) => {
          if (onRowClick) {
            return {
              onClick: () => onRowClick(record),
            };
          }
          return {};
        }}
        {...others}
      />
    </div>
  );
}

export default PaginationTable;
