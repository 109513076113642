import { Instructor } from "../interface/Instructor.interface";

const actions = {
  FETCH_INSTRUCTORS: "FETCH_INSTRUCTORS",
  FETCH_INSTRUCTORS_SUCCESS: "FETCH_INSTRUCTORS_SUCCESS",
  FETCH_INSTRUCTOR: "FETCH_INSTRUCTOR",
  SAVE_INSTRUCTOR: "SAVE_INSTRUCTOR",
  SAVE_INSTRUCTOR_SUCCESS: "SAVE_INSTRUCTOR_SUCCESS",
  DELETE_INSTRUCTOR: "DELETE_INSTRUCTOR",
  DELETE_INSTRUCTOR_SUCCESS: "DELETE_INSTRUCTOR_SUCCESS",
  DELETE_INSTRUCTOR_FAILED: "DELETE_INSTRUCTOR_FAILED",

  fetchInstructors: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_INSTRUCTORS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchInstructor: (
    id: number | "" | undefined,
    resolve: (value: Instructor) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_INSTRUCTOR,
    id,
    resolve,
    reject,
  }),

  saveInstructor: (
    data: Instructor,
    event: string,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_INSTRUCTOR,
    data,
    event,
    resolve,
    reject,
  }),

  deleteInstructor: (
    id: number | "" | undefined,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_INSTRUCTOR,
    id,
    resolve,
    reject,
  }),
};

export default actions;
