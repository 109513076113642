import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object; 
}

interface SurveyQuestionsReturnType {
    [x: string]: string;
}

const fetchQuestions = takeLatest(
    actions.FETCH_QUESTIONS,
    function* (
        action: AnyAction
    ): Generator<Effect, void, SurveyQuestionsReturnType>{
        try{
            const response = yield call(
                api.get,
                urlGenerator("api/surveyQuestion", action),
                false
            );
            yield put ({
                type: actions.FETCH_QUESTIONS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any){
            yield put({ type: actions.FETCH_QUESTIONS_FAILED
             });
             yield call(action.reject, "reject");
        }
    }
);

export default function* saga() {
    yield all([fetchQuestions]);
}