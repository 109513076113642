import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        appointments: [],
        activeAppointment: {},
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.CREATE_APPOINTMENT_SUCCESS:
            const saveAppointmentIndex = state.toJS().appointments.length;
            return state.setIn(["appointments", saveAppointmentIndex], action.payload);

        case actions.FETCH_APPOINTMENTS:
            return state.setIn(["loading"], true);

        case actions.FETCH_APPOINTMENTS_SUCCESS:
            const appointments = action.payload;
            return state.merge({
                loading: false,
                appointments: appointments,
            });

        case actions.FETCH_APPOINTMENTS_FAILED:
            return state.setIn(["loading"], false);

        case actions.UPDATE_APPOINTMENT_SUCCESS:
            const updateEntry = action.payload;
            let evalIndex = state.toJS().appointments?.data.findIndex(
                (appointments: any) =>
                    appointments.id === action.payload.id
            );
            return state.setIn(
                ["appointments", "data", evalIndex],
                action.payload
            );

        case actions.FETCH_APPOINTMENT:
            return state.setIn(["loading"], true);

        case actions.FETCH_APPOINTMENT_SUCCESS:
            const activeAppointment = action.payload;
            return state.merge({
                loading: false,
                activeAppointment: activeAppointment,
            });

        case actions.FETCH_APPOINTMENT_FAILED:
            return state.setIn(["loading"], false);

        case actions.FETCH_PARENT_APPOINTMENT:
            return state.setIn(["loading"], true);

        case actions.FETCH_PARENT_APPOINTMENT_SUCCESS:
            const parentAppointment = action.payload;
            return state.merge({
                loading: false,
                activeAppointment: parentAppointment,
            });

        case actions.FETCH_PARENT_APPOINTMENT_FAILED:
            return state.setIn(["loading"], false);

        default:
            return state;
    }
}

export default reducer;