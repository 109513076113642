import { Col, Row } from 'antd';
import AText from 'components/Text';
import ATitle from 'components/Title';
import React from 'react';

interface Props {
}

const TermsAndConditions: React.FC<Props> = () => {
  return (
    <>
      <Row>
        <ATitle content='Terms and Conditions' />
      </Row>
      <Row style={{textAlign: 'justify'}}>
        <Col>
        <AText>These terms and conditions outline the rules and regulations for the use of KidNCode's Website, located at <a href="https://kidncode.com">https://kidncode.com</a>.</AText>
        <AText>By accessing this website we assume you accept these terms and conditions. Do not continue to use kidncode.com if you do not agree to take all of the terms and conditions stated on this page.</AText>
        <AText>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of us. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</AText>
        
        <ATitle content='Cookies' />
        <AText>We employ the use of cookies. By accessing kidncode.com, you agreed to use cookies in agreement with the KidNCode's Privacy Policy.</AText>
        <AText>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</AText>

        <ATitle content='License' />
        <AText>Unless otherwise stated, KidNCode and/or its licensors own the intellectual property rights for all material on kidncode.com. All intellectual property rights are reserved. You may access this from kidncode.com for your own personal use subjected to restrictions set in these terms and conditions.</AText>
        <AText>You must not:</AText>
        <ul>
          <li>Republish material from kidncode.com</li>
          <li>Sell, rent or sub-license material from kidncode.com</li>
          <li>Reproduce, duplicate or copy material from kidncode.com</li>
          <li>Redistribute content from kidncode.com</li>
        </ul>
        <AText>This Agreement shall begin on the date hereof.</AText>
        </Col>
      </Row>
    </>
  );
};

export default TermsAndConditions;
