import CampsSaga from "./sagas";
import CampsReducer from "./reducers";
import campsActions from "./actions";

export const campsReducer = {
  campsReducer: CampsReducer,
};

export const campsSaga = [CampsSaga()];

export { campsActions };
