import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    superadmins: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_SUPERADMINS_SUCCESS:
      return state.setIn(["superadmins"], action.payload);

    case actions.SAVE_SUPERADMIN_SUCCESS:
      let superAdminIndex = 0;

      if (action.event === "ADD") {
        superAdminIndex = state.toJS().courses.length;
      }

      if (action.event === "EDIT") {
        superAdminIndex = state
          .toJS()
          .superadmins?.data?.findIndex(
            (superadmin: any) => superadmin.id === action.payload.id
          );
      }

      return state.setIn(
        ["superadmins", "data", superAdminIndex],
        action.payload
      );

    case actions.DELETE_SUPERADMIN_SUCCESS:
      const deleteIndex = state
        .toJS()
        .superadmins?.data?.findIndex(
          (superadmin: any) => superadmin.id === action.id
        );

      return state.deleteIn(["superadmins", "data", deleteIndex]);

    default:
      return state;
  }
};

export default reducer;
