const actions = {
  FETCH_ATTENDANCEES: "FETCH_ATTENDANCEES",
  FETCH_ATTENDANCEES_SUCCESS: "FETCH_ATTENDANCEES_SUCCESS",
  FETCH_ATTENDANCEES_FAILED: "FETCH_ATTENDANCEES_FAILED",
  FETCH_ATTENDANCE: "FETCH_ATTENDANCE",
  FETCH_ATTENDANCE_SUCCESS: "FETCH_ATTENDANCE_SUCCESS",
  FETCH_ATTENDANCE_FAILED: "FETCH_ATTENDANCE_FAILED",
  FETCH_ATTENDANCE_REPORT: "FETCH_ATTENDANCE_REPORT",
  FETCH_ATTENDANCE_REPORT_SUCCESS: "FETCH_ATTENDANCE_REPORT_SUCCESS",
  FETCH_ATTENDANCE_REPORT_FAILED: "FETCH_ATTENDANCE_REPORT_FAILED",
  CREATE_ATTENDANCE: "CREATE_ATTENDANCE",
  CREATE_ATTENDANCE_SUCCESS: "CREATE_ATTENDANCE_SUCCESS",
  CREATE_ATTENDANCE_FAILED: "CREATE_ATTENDANCE_FAILED",
  UPDATE_ATTENDANCE: "UPDATE_ATTENDANCE",
  UPDATE_ATTENDANCE_SUCCESS: "UPDATE_ATTENDANCE_SUCCESS",
  UPDATE_ATTENDANCE_FAILED: "UPDATE_ATTENDANCE_FAILED",
  DELETE_ATTENDANCE: "DELETE_ATTENDANCE",
  DELETE_ATTENDANCE_SUCCESS: "DELETE_ATTENDANCE_SUCCESS",
  DELETE_ATTENDANCE_FAILED: "DELETE_ATTENDANCE_FAILED",
  CHECK_IN: "CHECK_IN",
  CHECK_IN_SUCCESS: "CHECK_IN_SUCCESS",
  CHECK_IN_FAILED: "CHECK_IN_FAILED",
  CHECK_OUT: "CHECK_OUT",
  CHECK_OUT_SUCCESS: "CHECK_OUT_SUCCESS",
  CHECK_OUT_FAILED: "CHECK_OUT_FAILED",
  CHECK_EMPLOYEE_ACCESS: "CHECK_EMPLOYEE_ACCESS",
  CHECK_EMPLOYEE_ACCESS_SUCCESS: "CHECK_EMPLOYEE_ACCESS_SUCCESS",
  CHECK_EMPLOYEE_ACCESS_FAILED: "CHECK_EMPLOYEE_ACCESS_FAILED",

  createAttendance: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CREATE_ATTENDANCE,
    data,
    resolve,
    reject,
  }),

  updateAttendance: (
    id: number,
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_ATTENDANCE,
    id,
    data,
    resolve,
    reject,
  }),

  fetchAttendancees: (
    resolve: (data: string) => void,
    reject: (value: string) => void,
    branchId?: any,
  ) => ({
    type: actions.FETCH_ATTENDANCEES,
    resolve,
    reject,
    branchId,
  }),

  fetchAttendance: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_ATTENDANCE,
    resolve,
    reject,
  }),

  fetchAttendanceReport: (
    id: number,
    resolve: (data: any) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_ATTENDANCE_REPORT,
    resolve,
    reject,
  }),

  deleteAttendance: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_ATTENDANCE,
    id,
    resolve,
    reject,
  }),

  checkIn: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CHECK_IN,
    data,
    resolve,
    reject,
  }),

  checkOut: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CHECK_OUT,
    data,
    resolve,
    reject,
  }),

  checkEmployeeAccess: (
    data: Object, 
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CHECK_EMPLOYEE_ACCESS,
    data,
    resolve,
    reject,
  }),
};

export default actions;
