import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        employeeAttendances: [],
        activeEmployeeAttendance: {},
        employeeAttendancneReport: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case (actions.CREATE_EMPLOYEE_ATTENDANCE_SUCCESS, actions.EMPLOYEE_CHECK_IN_SUCCESS):
            const saveEmployeeAttendacneIndex = state.toJS().employeeAttendances.length;
                return state.setIn(["employeeAttendances", saveEmployeeAttendacneIndex], action.payload);
        
        case (actions.FETCH_EMPLOYEE_ATTENDANCES,
                actions.FETCH_EMPLOYEE_ATTENDANCE):
                return state.setIn(["loading"], true); 
                
        case actions.FETCH_EMPLOYEE_ATTENDANCE_SUCCESS:
                return state.merge({
                loading: false,
                activeEmployeeAttendance: action.payload,
                });
                
        case actions.FETCH_EMPLOYEE_ATTENDANCES_SUCCESS:
            const employeeAttendances = action.payload;
            return state.merge({
                ...state,
                loading: false,
                employeeAttendances: employeeAttendances,
            })        

        case (actions.EMPLOYEE_CHECK_IN_FAILED,
            actions.CREATE_EMPLOYEE_ATTENDANCE_FAILED):
                return state.setIn(["loading"], false);   
        
        case (actions.UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS, actions.EMPLOYEE_CHECK_OUT_SUCCESS):
            const updatedEntry = action.payload;
            const employeeAttendanceIndex = state.toJS().employeeAttendances.findIndex(
                (employeeAttendance: any) => employeeAttendance.id === action.payload.id
            );
            return state.setIn(["employeeAttendances", employeeAttendanceIndex], updatedEntry)          

        default:
            return state;
    }
}

export default reducer;