import SuperAdminsSaga from "./sagas";
import SuperAdminReducer from "./reducers";
import superAdminActions from "./actions";

export const superAdminsReducer = {
  superAdminsReducer: SuperAdminReducer,
};

export const superAdminsSaga = [SuperAdminsSaga()];

export { superAdminActions };
