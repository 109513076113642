import { Typography } from "antd";

interface ATitleProps {
  content: string;
  [x: string]: any;
}

function ATitle({ content, ...others }: ATitleProps) {
  const { Title } = Typography;
  return <Title {...others}>{content}</Title>;
}

export default ATitle;
