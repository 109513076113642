import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    attendances: [],
    activeAttendance: {},
    attendanceReport: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case (actions.CREATE_ATTENDANCE_SUCCESS, actions.CHECK_IN_SUCCESS):
      const saveAttendanceIndex = state.toJS().attendances.length;
      return state.setIn(["attendances", saveAttendanceIndex], action.payload);

    case (actions.FETCH_ATTENDANCEES,
    actions.FETCH_ATTENDANCE,
    actions.FETCH_ATTENDANCE_REPORT):
      return state.setIn(["loading"], true);

    case actions.FETCH_ATTENDANCE_SUCCESS:
      return state.merge({
        loading: false,
        activeAttendance: action.payload,
      });

    case actions.FETCH_ATTENDANCE_REPORT_SUCCESS:
      return state.merge({
        ...state,
        loading: false,
        attendanceReport: action.payload,
      });

    case actions.FETCH_ATTENDANCEES_SUCCESS:
      const attendances = action.payload;
      return state.merge({
        ...state,
        loading: false,
        attendances: attendances,
      });

    case (actions.FETCH_ATTENDANCEES_FAILED,
    actions.FETCH_ATTENDANCE_REPORT_FAILED,
    actions.FETCH_ATTENDANCE_FAILED,
    actions.CHECK_IN_FAILED,
    actions.CHECK_OUT_FAILED,
    actions.CREATE_ATTENDANCE_FAILED):
      return state.setIn(["loading"], false);

    case (actions.UPDATE_ATTENDANCE_SUCCESS, actions.CHECK_OUT_SUCCESS):
      const updatedEntry = action.payload;
      const attendanceIndex = state
        .toJS()
        .attendances.findIndex(
          (attendance: any) => attendance.id === action.payload.id
        );
      return state.setIn(["attendances", attendanceIndex], updatedEntry);

    case actions.DELETE_ATTENDANCE_SUCCESS:
      const delAttendanceIndex = state
        .toJS()
        .attendances.findIndex(
          (attendance: any) => attendance.id === action.payload
        );

      return state.deleteIn(["attendances", delAttendanceIndex]);

    default:
      return state;
  }
};

export default reducer;
