const actions = {
    SAVE_CAMP: "SAVE_CAMP",
    SAVE_CAMP_SUCCESS: "SAVE_CAMP_SUCCESS",
    SAVE_CAMP_FAILED: "SAVE_CAMP_FAILED",
    FETCH_CAMPS: "FETCH_CAMPS",
    FETCH_CAMPS_SUCCESS: "FETCH_CAMPS_SUCCESS",
    FETCH_CAMPS_FAILED: "FETCH_CAMPS_FAILED",
    FETCH_CAMP: "FETCH_CAMP",
    FETCH_CAMP_SUCCESS: "FETCH_CAMP_SUCCESS",
    FETCH_CAMP_FAILED: "FETCH_CAMP_FAILED",

    saveCamp: (
        data: any,
        event: string,
        resolve: (value: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.SAVE_CAMP,
        data,
        event,
        resolve,
        reject
    }),

    fetchCamps: (
        resolve: (token: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.FETCH_CAMPS,
        resolve,
        reject,
      }),
}

export default actions;
