const actions = {
    FETCH_EMPLOYEE_ATTENDANCES: "FETCH_EMPLOYEE_ATTENDANCES",
    FETCH_EMPLOYEE_ATTENDANCES_SUCCESS: "FETCH_EMPLOYEE_ATTENDANCES_SUCCESS",
    FETCH_EMPLOYEE_ATTENDANCES_FAILED: "FETCH_EMPLOYEE_ATTENDANCES_FAILED",
    FETCH_EMPLOYEE_ATTENDANCE: "FETCH_EMPLOYEE_ATTENDANCE",
    FETCH_EMPLOYEE_ATTENDANCE_SUCCESS: "FETCH_EMPLOYEE_ATTENDANCE_SUCCESS",
    FETCH_EMPLOYEE_ATTENDANCE_FAILED: "FETCH_EMPLOYEE_ATTENDANCE_FAILED",
    CREATE_EMPLOYEE_ATTENDANCE: "CREATE_EMPLOYEE_ATTENDANCE",
    CREATE_EMPLOYEE_ATTENDANCE_SUCCESS: "CREATE_EMPLOYEE_ATTENDANCE_SUCCESS",
    CREATE_EMPLOYEE_ATTENDANCE_FAILED: "CREATE_EMPLOYEE_ATTENDANCE_FAILED",
    EMPLOYEE_CHECK_IN: "EMPLOYEE_CHECK_IN",
    EMPLOYEE_CHECK_IN_SUCCESS: "EMPLOYEE_CHECK_IN_SUCCESS",
    EMPLOYEE_CHECK_IN_FAILED: "EMPLOYEE_CHECK_IN_FAILED",
    EMPLOYEE_CHECK_OUT: "EMPLOYEE_CHECK_OUT",
    EMPLOYEE_CHECK_OUT_SUCCESS: "EMPLOYEE_CHECK_OUT_SUCCESS",
    EMPLOYEE_CHECK_OUT_FAILED: "EMPLOYEE_CHECK_OUT_FAILED",
    UPDATE_EMPLOYEE_ATTENDANCE: "UPDATE_EMPLOYEE_ATTENDANCE",
    UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS: "UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS",
    UPDATE_EMPLOYEE_ATTENDANCE_FAILED: "UPDATE_EMPLOYEE_ATTENDANCE_FAILED",

    createEmployeeAttendance: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.CREATE_EMPLOYEE_ATTENDANCE,
        data,
        resolve,
        reject,
      }),

      fetchAttendances: (
        // page: number,
        // pageSize: number,
        branch_id: number | undefined,
        resolve: (data: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.FETCH_EMPLOYEE_ATTENDANCES,
        branch_id,
        // page,
        // pageSize,
        resolve,
        reject,
      }),

      fetchAttendance: (
        id: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
      ) => ({
        id,
        type: actions.FETCH_EMPLOYEE_ATTENDANCE,
        resolve,
        reject,
      }),

      checkIn: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.EMPLOYEE_CHECK_IN,
        data,
        resolve,
        reject,
      }),

      updateEmployeeAttendance: (
        id: number,
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.UPDATE_EMPLOYEE_ATTENDANCE,
        id,
        data,
        resolve,
        reject,
      }),
    
      checkOut: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.EMPLOYEE_CHECK_OUT,
        data,
        resolve,
        reject,
      }),
}

export default actions;