import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        camps: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_CAMPS_SUCCESS:
            return state.setIn(["camps"], action.payload)

        case actions.SAVE_CAMP_SUCCESS:
            let campsIndex = 0;

            if (action.event === "ADD") {
                campsIndex = state.toJS().camps.length;
            }

            if (action.event === "EDIT") {
                campsIndex = state
                    .toJS()
                    .camps.findIndex((camp: any) => camp.id === action.payload.id);
            }

            return state.setIn(["camps", campsIndex], action.payload);

        default:
            return state;
    }
};

export default reducer;