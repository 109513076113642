import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    batches: [],
    totalStudentOfBatch: [],
    activeBatch: {},
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.CREATE_BATCH_SUCCESS:
      const saveBatchIndex = state.toJS().batches.length;
      return state.setIn(["batches", saveBatchIndex], action.payload);

    case actions.FETCH_BATCH:
      return state.setIn(["loading"], true);

    case actions.FETCH_BATCH_SUCCESS:
      const activeBatch = action.payload;
      return state.merge({
        loading: false,
        activeBatch: activeBatch,
      });

    case actions.FETCH_BATCH_FAILED:
      return state.setIn(["loading"], false);

    case actions.FETCH_BATCHES:
      return state.setIn(["loading"], true);

    case actions.FETCH_BATCHES_SUCCESS:
      const batches = action.payload;
      return state.merge({
        loading: false,
        batches: batches,
      });

    case actions.FETCH_BATCHES_FAILED:
      return state.setIn(["loading"], false);

    case actions.FETCH_BATCHES_BY_INSTRUCTOR:
      return state.setIn(["loading"], true);

    case actions.FETCH_BATCHES_BY_INSTRUCTOR_SUCCESS:
      return state.setIn(["batches"], action.payload);

    case actions.FETCH_BATCHES_BY_INSTRUCTOR_FAILED:
      return state.setIn(["loading"], false);

    case actions.UPDATE_BATCH_SUCCESS:
      const updatedEntry = action.payload;
      const batchIndex = state
        .toJS()
        .batches?.data.findIndex(
          (batch: any) => batch.id === action.payload.id
        );
      return state.setIn(["batches", , "data", batchIndex], updatedEntry);

    case actions.DELETE_BATCH_SUCCESS:
      const delBatchIndex = state
        .toJS()
        .batches?.data.findIndex((batch: any) => batch.id === action.payload);

      return state.deleteIn(["batches", "data", delBatchIndex]);

    case actions.FETCH_TOTAL_STUDENTS_OF_BATCH:
      return state.setIn(["loading"], true);

    case actions.FETCH_TOTAL_STUDENTS_OF_BATCH_SUCCESS:
      const totalStudent = action.payload;
      return state.merge({
        loading: false,
        totalStudentOfBatch: totalStudent,
      })  
    
    case actions.FETCH_TOTAL_STUDENTS_OF_BATCH_FAILED:
      return state.setIn(["loading"], false);

    case actions.FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP:
      return state.setIn(["loading"], true);

    case actions.FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP_SUCCESS:
      const totalStudentOfBatch = action.payload;
      return state.merge({
        loading: false,
        totalStudentOfBatch: totalStudentOfBatch,
      })  
    
    case actions.FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP_FAILED:
      return state.setIn(["loading"], false);

    default:
      return state;
  }
};

export default reducer;
