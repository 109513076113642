const actions = {
    FETCH_SURVEYANSWERS: "FETFETCH_SURVEYANSWERS",
    FETCH_SURVEYANSWERS_SUCCESS: "FETCH_SURVEYANSWERS_SUCCESS",
    FETCH_SURVEYANSWERS_FAILED: "FETCH_SURVEYANSWERS_FAILED",
    FETCH_PARENTDETAILS: "FETCH_PARENTDETAILS",
    FETCH_PARENTDETAILS_SUCCESS: "FETCH_PARENTDETAILS_SUCCESS",
    FETCH_PARENTDETAILS_FAILED: "FETCH_PARENTDETAILS_FAILED", 

    fetchSurveyAnswers: (
        params: {parentId: any; childId: any},
        resolve: (value: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_SURVEYANSWERS,
        params,
        resolve,
        reject,
    }),
    fetchParentDetails: (
        resolve: (value: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_PARENTDETAILS,
        resolve,
        reject,
    }),
}

export default actions;