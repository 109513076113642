import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchUserDetails = takeLatest(
    actions.FETCH_USER_DETAILS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.get,
          `api/userdetails/${action.id }/${action.child_id}`, false
        );
        yield put({
          type: actions.FETCH_USER_DETAILS_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, "success");
      } catch (error) {
        yield call(action.reject, "failed");
      }
    }
  );

  const updateEnrollment = takeLatest(
    actions.UPDATE_ENROLLMENT,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/enrollmentWithoutId/update-enrollment/${action.data.parent_id}`,
          action.data
        );
        yield put({
          type: actions.UPDATE_ENROLLMENT_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error: any) {
        yield put({ type: actions.UPDATE_ENROLLMENT_FAILED });
        yield call(action.reject, error.response);
      }
    }
  );

export default function* saga() {
    yield all([fetchUserDetails, updateEnrollment]);
}
