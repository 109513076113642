const actions = {
    CREATE_APPOINTMENT: "CREATE_APPOINTMENT",
    CREATE_APPOINTMENT_SUCCESS: "CREATE_APPOINTMENT_SUCCESS",
    CREATE_APPOINTMENT_FAILED: "CREATE_APPOINTMENT_FAILED",
    FETCH_APPOINTMENTS: "FETCH_APPOINTMENTS",
    FETCH_APPOINTMENTS_SUCCESS: "FETCH_APPOINTMENTS_SUCCESS",
    FETCH_APPOINTMENTS_FAILED: "FETCH_APPOINTMENTS_FAILED",
    UPDATE_APPOINTMENT: "UPDATE_APPOINTMENT",
    UPDATE_APPOINTMENT_SUCCESS: "UPDATE_APPOINTMENT_SUCCESS",
    UPDATE_APPOINTMENT_FAILED: "UPDATE_APPOINTMENT_FAILED",
    FETCH_APPOINTMENT: "FETCH_APPOINTMENT",
    FETCH_APPOINTMENT_SUCCESS: "FETCH_APPOINTMENT_SUCCESS",
    FETCH_APPOINTMENT_FAILED: "FETCH_APPOINTMENT_FAILED",
    RESCHEDULE_CANCEL_APPOINTMENT: "RESCHEDULE_CANCEL_APPOINTMENT",
    RESCHEDULE_CANCEL_APPOINTMENT_SUCCESS: "RESCHEDULE_CANCEL_APPOINTMENT_SUCCESS",
    RESCHEDULE_CANCEL_APPOINTMENT_FAILED: "RESCHEDULE_CANCEL_APPOINTMENT_FAILED",
    FETCH_PARENT_APPOINTMENT: "FETCH_PARENT_APPOINTMENT",
    FETCH_PARENT_APPOINTMENT_SUCCESS: "FETCH_PARENT_APPOINTMENT_SUCCESS",
    FETCH_PARENT_APPOINTMENT_FAILED: "FETCH_PARENT_APPOINTMENT_FAILED",

    createAppointment: (
        data: Object,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.CREATE_APPOINTMENT,
        data,
        resolve,
        reject,
    }),

    fetchAppointments: (
        // page: number,
        // pageSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_APPOINTMENTS,
        // page,
        // pageSize,
        resolve,
        reject,
    }),

    updateAppointment: (
        data: Object,
        resolve: (date: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.UPDATE_APPOINTMENT,
        data,
        resolve,
        reject,
    }),

    rescheduleOrCancelAppointment: (
        data: Object,
        buttonId: any,
        appointmentId: any,
        resolve: (date: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.RESCHEDULE_CANCEL_APPOINTMENT,
        payload: { data, buttonId, appointmentId},
        resolve,
        reject
    }),

    fetchAppointment: (
        id: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        id,
        type: actions.FETCH_APPOINTMENT,
        resolve,
        reject
    }),

    fetchAppointmentOfParent: (
        id: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        id,
        type: actions.FETCH_PARENT_APPOINTMENT,
        resolve,
        reject
    }),
}

export default actions;