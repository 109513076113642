import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    evaluationPlacementForms: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_EVALUATION_FORM:
      return state.setIn(["loading"], true);

    case actions.FETCH_EVALUATION_FORM_SUCCESS:
      const formData = action.payload;
      return state.merge({
        loading: false,
        evaluationPlacementForms: formData,
      });

    case actions.FETCH_EVALUATION_FORM_FAILED:
      return state.setIn(["loading"], false);

    case actions.UPDATE_EVALUATION_FORM_SUCCESS:
      const updatedEntry = action.payload;
      let evalIndex = state
        .toJS()
        .evaluationPlacementForms?.data.findIndex(
          (evaluationPlacementForm: any) =>
            evaluationPlacementForm.id === action.payload.id
        );
      return state.setIn(
        ["evaluationPlacementForms", "data", evalIndex],
        action.payload
      );
    // return state;

    default:
      return state;
  }
};

export default reducer;
