import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    loading: false,
    timeSlots: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.CREATE_TIME_SLOT_SUCCESS:
      const saveTimeSlotIndex = state.toJS().timeSlots.length;
      return state.setIn(["timeSlots", saveTimeSlotIndex], action.payload);

    case actions.CREATE_TIME_SLOT_FAILED:
      return state.merge({
        loading: false,
        timeSlots: state.toJS().timeSlots,
      });

    case actions.FETCH_TIME_SLOTS:
      return state.setIn(["loading"], true);

    case actions.FETCH_TIME_SLOTS_SUCCESS:
      const timeSlots = action.payload;
      return state.merge({
        loading: false,
        timeSlots: timeSlots,
      });

    case actions.FETCH_TIME_SLOTS_FAILED:
      return state.setIn(["loading"], false);

    case actions.UPDATE_TIME_SLOT_SUCCESS:
      const updatedEntry = action.payload;
      const timeSlotIndex = state
        .toJS()
        .timeSlots.findIndex(
          (timeSlot: any) => timeSlot.id === action.payload.id
        );
      return state.setIn(["timeSlots", timeSlotIndex], updatedEntry);

    case actions.DELETE_TIME_SLOT_SUCCESS:
      const delTimeSlotIndex = state
        .toJS()
        .timeSlots.findIndex((timeSlot: any) => timeSlot.id === action.payload);

      return state.deleteIn(["timeSlots", delTimeSlotIndex]);

    default:
      return state;
  }
};

export default reducer;
