import ReferralsSaga from "./sagas";
import ReferralsReducer from "./reducers";
import referralsActions from "./actions";

export const referralsReducer = {
	referralsReducer: ReferralsReducer,
};

export const referralsSaga = [ReferralsSaga()];

export { referralsActions };