import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        creditAndDiscounts: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_ALL_CREDIT_AND_DISCOUNTS_SUCCESS:
            return state.setIn(["creditAndDiscounts"], action.payload);

        case actions.FETCH_CREDIT_AND_DISCOUNTS_SUCCESS:
            return state.setIn(["creditAndDiscounts"], action.payload);

        default:
            return state;
    }
}

export default reducer;