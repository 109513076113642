import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { tokens } from "utils/Constants";

const bankNotesIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M1.875 15.625C6.431 15.625 10.8447 16.2344 15.039 17.376C15.6448 17.5409 16.25 17.0905 16.25 16.4626V15.625M3.125 3.75V4.375C3.125 4.72018 2.84518 5 2.5 5H1.875M1.875 5V4.6875C1.875 4.16973 2.29473 3.75 2.8125 3.75H16.875M1.875 5V12.5M16.875 3.75V4.375C16.875 4.72018 17.1548 5 17.5 5H18.125M16.875 3.75H17.1875C17.7053 3.75 18.125 4.16973 18.125 4.6875V12.8125C18.125 13.3303 17.7053 13.75 17.1875 13.75H16.875M18.125 12.5H17.5C17.1548 12.5 16.875 12.7798 16.875 13.125V13.75M16.875 13.75H3.125M3.125 13.75H2.8125C2.29473 13.75 1.875 13.3303 1.875 12.8125V12.5M3.125 13.75V13.125C3.125 12.7798 2.84518 12.5 2.5 12.5H1.875M12.5 8.75C12.5 10.1307 11.3807 11.25 10 11.25C8.61929 11.25 7.5 10.1307 7.5 8.75C7.5 7.36929 8.61929 6.25 10 6.25C11.3807 6.25 12.5 7.36929 12.5 8.75ZM15 8.75H15.0062V8.75625H15V8.75ZM5 8.75H5.00625V8.75625H5V8.75Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const BankNotesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={bankNotesIcon} {...props} />
);
