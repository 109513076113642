import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchCourses = takeLatest(
  actions.FETCH_COURSES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, urlGenerator("api/course", action));
      yield put({
        type: actions.FETCH_COURSES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchLearningMaterials = takeLatest(
  actions.FETCH_LEARNING_MATERIALS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, "api/media");
      yield put({
        type: actions.FETCH_LEARNING_MATERIALS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchCourse = takeLatest(
  actions.FETCH_COURSE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, `api/course/${action.id}`);
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveCourse = takeLatest(
  actions.SAVE_COURSE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(api.post, "api/course", action.data, null);
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_COURSE_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }

      if (action.event === "EDIT") {
        yield call(api.put, `api/course/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_COURSE_SUCCESS,
          payload: action.data,
          event: action.event,
        });
      }
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveLearningMaterials = takeLatest(
  actions.SAVE_LEARNING_MATERIALS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        "api/media/learning-materials",
        action.data,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      yield put({
        type: actions.SAVE_LEARNING_MATERIALS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const deleteCourse = takeLatest(
  actions.DELETE_COURSE,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const responseId = yield call(api.delete, `api/course/${action.id}`);
      yield put({
        type: actions.DELETE_COURSE_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchCourses,
    fetchCourse,
    saveCourse,
    deleteCourse,
    fetchLearningMaterials,
    saveLearningMaterials,
  ]);
}
