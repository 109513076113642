const actions = {
    FETCH_PARENT_DETAILS: "FETCH_PARENT_DETAILS",
    FETCH_PARENT_DETAILS_SUCCESS: "FETCH_PARENT_DETAILS_SUCCESS",
    FETCH_PARENT_DETAILS_FAILED: "FETCH_PARENT_DETAILS_FAILED",
    FETCH_SINGLE_PARENT_DETAIL: "FETCH_SINGLE_PARENT_DETAIL",
    FETCH_SINGLE_PARENT_DETAIL_SUCCESS: "FETCH_SINGLE_PARENT_DETAIL_SUCCESS",
    FETCH_SINGLE_PARENT_DETAIL_FAILED: "FETCH_SINGLE_PARENT_DETAIL_FAILED",
    REMOVE_CHILD: "REMOVE_CHILD",
    REMOVE_CHILD_SUCCESS: "REMOVE_CHILD_SUCCESS",
    REMOVE_CHILD_FAILED: "REMOVE_CHILD_FAILED",
    SEND_SECURITY_CODE: "SEND_SECURITY_CODE",
    SEND_SECURITY_CODE_SUCCESS: "SEND_SECURITY_CODE_SUCCESS",
    SEND_SECURITY_CODE_FAILED: "SEND_SECURITY_CODE_FAILED",
    UPDATE_PARENT_DETAILS: "UPDATE_PARENT_DETAILS",
    UPDATE_PARENT_DETAILS_SUCCESS: "UPDATE_PARENT_DETAILS_SUCCESS",
    UPDATE_PARENT_DETAILS_FAILED: "UPDATE_PARENT_DETAILS_FAILED", 

    fetchParentDetails: (
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_PARENT_DETAILS,
        resolve,
        reject
    }),
    fetchSingleParentDetail: (
        id: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_SINGLE_PARENT_DETAIL,
        id,
        resolve,
        reject
    }),
    removeChild: (
        childId: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.REMOVE_CHILD,
        childId,
        resolve,
        reject
    }),
    sendSecurityCode: (
        childId: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.SEND_SECURITY_CODE,
        childId,
        resolve,
        reject
    }),
    updateParentDetails: (
        data: any,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.UPDATE_PARENT_DETAILS,
        data,
        resolve,
        reject,
    })

}

export default actions;