import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
import { Map } from "immutable";
const { fromJS } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        pendingStudents: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_PENDING_STUDENTS:
            return state.setIn(["loading"], true);
         
        case actions.FETCH_PENDING_STUDENTS_SUCCESS:
            const pendingStudents = action.payload;
            return state.merge({
                loading: false,
                pendingStudents: pendingStudents,
            })    

        case actions.FETCH_PENDING_STUDENTS_FAILED:
            return state.setIn(["loading"], false)    

        case actions.UPDATE_PENDING_STUDENTS_SUCCESS:
            const updatedEntry = action.payload;
            let evalIndex = state
                .toJS()
                .pendingStudents?.data.findIndex(
                    (pendingStudent: any) => 
                        pendingStudent.id === action.payload.id
                    );
            return state.setIn(
                ["pendingStudents", "data", evalIndex], action.payload
            )        

        case actions.REMOVE_PENDING_STUDENTS_SUCCESS:
            const removePendingStudent = state.toJS();
            let evalInd = state
                .toJS()
                .pendingStudents?.data.findIndex(
                    (pendingStudent: any) =>   
                        pendingStudent.id === action.payload.id
                );
            return state.setIn(
                ["pendingStudents", "data", evalInd], action.payload
            )        

        default:
            return state;    
    }
}

export default reducer;